<template>
  <a-drawer
    :title="inNew"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form
      :model="formDataAccount"
      layout="vertical"
      ref="formRef"
      autocomplete="off"
    >
      <a-row :gutter="10">
        <a-col span="12">
          <a-form-item label="关联类别:" name="relationType"
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-select :options="guanlianleibie" placeholder="请选择关联类别"
                      v-model:value="formDataAccount.relationType"
                      :disabled="isDisable" @change="gunlianleibie">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="险种/产品名称:" name="riskId" :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-select show-search :options="listAccount.xianzhongmingcheng" placeholder="请选择"
                      v-model:value="formDataAccount.riskId"
                      :filter-option="filterOption" :disabled="isDisable" @change="xianzhongmingcheng1">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="结算类型:" name="settlementTypeCode"
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-select :options="jiesuanleixing" @change="changeAll"
                      v-model:value="formDataAccount.settlementTypeCode" :disabled="isDisable">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12" v-if="formDataAccount.settlementTypeCode == 2">
          <a-form-item label="交费方式:" name="payType" :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-select :options="jiaofeifangshi"
                      v-model:value="formDataAccount.payType"
                      :disabled="isDisable"
            >
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12" v-if="formDataAccount.settlementTypeCode == 1">
          <a-form-item label="手续费(元):" name="proceduresMoney">
            <a-input v-model:value="formDataAccount.proceduresMoney" :disabled="isDisable"></a-input>
          </a-form-item>
        </a-col>
        <a-col span="12" v-if="formDataAccount.settlementTypeCode == 2">
          <a-form-item label="保险期间:" name="insurancePeriod"
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-select :options="baoxianqijian" v-model:value="formDataAccount.insurancePeriod"
                      :disabled="isDisable">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12" v-if="formDataAccount.settlementTypeCode == 2">
          <a-form-item label="缴费期间:" name="renewPeriod"
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-select :options="jiaofeiqijian" v-model:value="formDataAccount.renewPeriod"
                      :disabled="isDisable">
            </a-select>
          </a-form-item>
        </a-col>
        <!--        <a-col span="12" v-if="formDataAccount.settlementTypeCode == 2">-->
        <!--          <a-form-item label="保单年度:" name="policyYear">-->
        <!--            <a-input v-model:value="formDataAccount.policyYear" :disabled="isDisable"></a-input>-->
        <!--          </a-form-item>-->
        <!--        </a-col>-->
        <!--        <a-col span="12" v-if="formDataAccount.settlementTypeCode == 2">-->
        <!--          <a-form-item label="手续费比例:" name="renewRatio">-->
        <!--            <a-input v-model:value="formDataAccount.renewRatio" :disabled="isDisable"></a-input>-->
        <!--          </a-form-item>-->
        <!--        </a-col>-->
        <a-col span="12">
          <a-form-item label="生效日期:" name="effectiveDate"
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-date-picker
              v-model:value="formDataAccount.effectiveDate"
              :disabled="isDisable"
              mode="date"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              clearable
            ></a-date-picker>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="失效日期:" name="invalidDate"
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-date-picker
              v-model:value="formDataAccount.invalidDate"
              :disabled="isDisable"
              mode="date"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              clearable
            ></a-date-picker>
          </a-form-item>
        </a-col>
        <a-table
          :columns="columns"
          :data-source="formDataAccount.chargePolicyYearFormList"
          style="width: 450px;"
          :pagination="false"
          :scroll="{ x: 300 }"
          :row-selection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }"
          :row-key="(record) => record.id"
        >
          <!-- :loading="loading" -->
          <template #bodyCell="{ column, index, record }">
            <template v-if="column.key === 'index'">
              {{ index + 1 }}
            </template>
            <template v-if="column.key === 'policyYear'">
              <div>
                <a-input-number :min="1" @blur="profitRateBlur" v-model:value="record.policyYear"
                                :disabled="isDisable"/>
              </div>
            </template>
            <template v-if="column.key === 'renewRatio'">
              <div>
                <a-input oninput="value=value.match(/\d+\.?\d{0,2}/,'')" v-model:value="record.renewRatio"
                         @change="handelRenewRatio" :disabled="isDisable"/>
              </div>
            </template>
          </template>
        </a-table>
      </a-row>
      <div style="margin-top: 3px">
        <a-button @click="addOne" v-if="!isDisable">增加一行</a-button>&nbsp;
        <a-button @click="delAll" v-if="!isDisable">删除</a-button>&nbsp;
        <a-button @click="addAll" v-if="!isDisable">快速生成数据</a-button>
      </div>
      <quickEnter ref="quickEnterRef" @tableChange="tableChange"></quickEnter>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button html-type="submit" type="primary" @click="onSave()" v-if="!isDisable">
          <template #icon>
            <FileAddOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script>
import quickEnter from '../../../components/extended/quickEnter'
import {computed, defineComponent, onBeforeMount, reactive, ref, toRefs} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import {getDictionaryData, getExtendUri} from '../../../utils/request_extend'
import {message} from 'ant-design-vue'

export default defineComponent({
  components: {
    quickEnter
  },
  props: {
    status: {
      type: String,
      required: true,
      default() {
        return '123'
      }
    },
    statuss: {
      type: String,
      required: true,
      default() {
        return '1234'
      }
    },
    code: {
      type: String,
      required: true,
      default() {
        return '1'
      }
    }
  },
  setup(props, {emit, expose}) {
    const quickEnterRef = ref()
    const formRef = ref()
    const state = reactive({
      visible: false,

      result: [
        {}
      ],
      selectedRowKeys: [],
      columns: [
        {
          title: '保单年度',
          width: 130,
          key: 'policyYear',
          dataIndex: 'policyYear'
        },
        {
          title: '手续费比例(%)',
          width: 130,
          key: 'renewRatio',
          dataIndex: 'renewRatio'
        }
      ],
      temp_products_list: [],
      temp_risk_list: []
    })


    // 保单年度限制
    const profitRateBlur = (e) => {
      console.debug(e)
    }

    // 协议关联险种
    const formDataAccount = reactive({
      id: undefined,
      agreementId: undefined,
      payType: '',
      relationType: undefined,
      riskId: '',
      riskCode: '',
      riskName: '',
      chargePolicyYearFormList: [],
      settlementTypeCode: '2',
      proceduresMoney: 0,
      effectiveDate: '',
      invalidDate: '',
      insurancePeriod: '',
      renewPeriod: '',
      policyYear: 0,
      renewRatio: ''
    })
    const guanlianleibie = ref([])
    const jiesuanleixing = ref([])
    const jiaofeifangshi = ref([])
    const jiaofeiqijian = ref([])
    const baoxianqijian = ref([])
    onBeforeMount(() => {
      getDictionaryData(guanlianleibie, 'agreementContentType')
      getDictionaryData(jiesuanleixing, 'settlementType')
      getDictionaryData(jiaofeifangshi, 'apptype')
      getDictionaryData(jiaofeiqijian, 'renew_period')
      getDictionaryData(baoxianqijian, 'insurance_period')

    })
    // 判断手续费比例的格式
    const handelRenewRatio = (e) => {
      console.debug(e)
      if (Number(e.target.value) > 100 || Number(e.target.value) < 1) {
        formDataAccount.renewRatio = ''
        return message.warning('请输入1-100之间的数字')
      }
    }

    const filterOption = (input, option) => {
      return option.label.indexOf(input) > -1
    }
    const listAccount = reactive({
      // jiaofeifangshi: [],
      xianzhongmingcheng: [],
      // baoxianqijian: [],
      // jiaofeiqijian: []
    })
    const onSelectChange = (selectedRowKeys) => {
      state.selectedRowKeys = selectedRowKeys
      console.debug(state.selectedRowKeys)
    }
    // const chanpinmingcheng = function(value, label) {
    //   console.debug(label)
    //   formDataAccount.riskName = label.label
    // }
    const tableChange = function (list, status) {
      if (status === true) {
        list.forEach(item => {
          item.renewRatio = item.calculatedValue
          item.policyYear = item.calculated
          delete item.calculatedValue
          delete item.calculated
          delete item.premium
        })
        console.debug(list)
        formDataAccount.chargePolicyYearFormList.push(...list)
      } else {
        formDataAccount.chargePolicyYearFormList = []
        list.forEach(item => {
          item.renewRatio = item.calculatedValue
          item.policyYear = item.calculated
          delete item.calculatedValue
          delete item.calculated
          delete item.premium
        })
        console.debug(list)
        formDataAccount.chargePolicyYearFormList.push(...list)
      }
    }
    const addOne = function () {
      let data = new Date().getTime()
      formDataAccount.chargePolicyYearFormList.push(
        {
          id: data,
          policyYear: '',
          renewRatio: ''
        }
      )
    }
    const addAll = function () {
      quickEnterRef.value.show()
    }
    const xianzhongmingcheng1 = function (value, label) {
      formDataAccount.riskName = label.label
    }
    const delAll = function () {
      if (state.selectedRowKeys.length !== 0) {
        state.selectedRowKeys.forEach((item) => {
          formDataAccount.chargePolicyYearFormList.forEach((item1, index) => {
            if (item1.id == item) {
              formDataAccount.chargePolicyYearFormList.splice(index, 1)
            }
          })
        })
      } else {
        message.warning('请选择要删除的数据')
      }
    }
    const isDisable = computed(() => {
      if (props.status == 'select') {
        return true
      } else {
        return false
      }
    })
    const showDrawer = (oldData = {}) => {
      console.debug(oldData)
      state.isNew = !(typeof oldData.id === 'string' && oldData.id.length > 0)
      console.debug('editNewUser', state.isNew, oldData)
      Object.keys(formDataAccount).forEach((key) => {
        formDataAccount[key] = oldData[key] || formDataAccount[key]
      })
      console.debug(oldData)
      formDataAccount.chargePolicyYearFormList = oldData.chargePolicyYearList ? oldData.chargePolicyYearList : []
      // formDataAccount.riskCode = oldData.riskCode
      // formDataAccount.riskName = oldData.riskName
      // formDataAccount.settlementTypeCode = oldData.settlementTypeCode
      // formDataAccount.insurancePeriod = oldData.insurancePeriod
      // formDataAccount.renewPeriod = oldData.renewPeriod
      // formDataAccount.policyYear = oldData.policyYear
      // formDataAccount.renewRatio = oldData.renewRatio
      // formDataAccount.effectiveDate = oldData.effectiveDate
      // formDataAccount.invalidDate = oldData.invalidDate
      // formDataAccount.proceduresMoney = oldData.proceduresMoney
      state.visible = true
    }
    const inNew = computed(() => {
      if (props.statuss === 'bianji') {
        return '协议关联险种/产品修改'
      } else {
        return '协议关联险种/产品增加'
      }
    })
    const onClose = function () {
      state.visible = false
      formDataAccount.riskName = ''
      formDataAccount.insurancePeriod = ''
      formDataAccount.renewPeriod = ''
      formDataAccount.policyYear = ''
      formDataAccount.renewRatio = ''
      formDataAccount.effectiveDate = ''
      formDataAccount.invalidDate = ''
      formDataAccount.proceduresMoney = ''
      formDataAccount.riskId = ''
      formDataAccount.settlementTypeCode = '2'
      formDataAccount.relationType = undefined
      formDataAccount.payType = undefined
    }
    const changeAll = function () {
      // formDataAccount.riskId = ''
      // formDataAccount.riskName = ''
      formDataAccount.insurancePeriod = ''
      formDataAccount.renewPeriod = ''
      formDataAccount.policyYear = ''
      formDataAccount.renewRatio = ''
      formDataAccount.effectiveDate = ''
      formDataAccount.invalidDate = ''
      formDataAccount.proceduresMoney = ''
    }
    const gunlianleibie = function (value) {
      listAccount.xianzhongmingcheng = []
      formDataAccount.riskId = ''
      if (value === '1') {
        if (state.temp_risk_list && state.temp_risk_list.length > 0) {
          listAccount.xianzhongmingcheng = state.temp_risk_list
        } else {
          request(getRequestURI('selectRisk'), {
            data: {
              riskName: ''
            }
          }, true, 'post').then(
            (result) => {
              const arr = []
              const add = []
              const ass = []
              const res = result.result
              console.debug(res)
              for (let i = 0; i < res.length; i++) {
                if (ass.includes(res[i].riskName) == false) {
                  add.push({value: res[i].riskId, label: res[i].riskName})
                  ass.push(res[i].riskName)
                }
                arr.push({label: res[i].riskName, value: res[i].riskId})
              }
              console.debug(add)
              // state.condition.riskName.data = arr
              state.temp_risk_list = add
              listAccount.xianzhongmingcheng = add
            }
          )
        }

      } else {
        if (state.temp_products_list && state.temp_products_list.length > 0) {
          listAccount.xianzhongmingcheng = state.temp_products_list
        } else {
          request(getRequestURI('select'), {data: {}}).then(res => {
            console.debug(res.result)
            let add = []
            res.result.forEach(item => {
              let obj = {}
              obj.label = item.productName
              obj.value = item.id
              add.push(obj)
            })
            state.temp_products_list = add
            listAccount.xianzhongmingcheng = add
            console.debug(listAccount.xianzhongmingcheng)
          })
        }
      }
    }
    const onSave = async () => {
      await formRef.value.validate()
      if (formDataAccount.relationType === '' || formDataAccount.relationType === undefined) {
        message.warning('关联类别为必填信息')
        return
      }
      if (formDataAccount.riskId === '' || formDataAccount.riskId === undefined) {
        message.warning('险种/产品名称为必填信息')
        return
      }
      formDataAccount.agreementId = props.code
      // formDataAccount.agreementCode = props.idd
      let obj = {
        data: formDataAccount
      }
      if (props.statuss === 'bianji') {
        // formDataAccount.agreementId = props.code - 0
        // formDataAccount.id = props.code - 0
        const uri = getRequestURI('productRelation_update')
        request(uri, obj).then(res => {
          if (res.code == 1) {
            emit('select')
            onClose()
            message['success'](res.message)
          } else {
            message['success'](res.message)
          }
          console.debug(res)
        })
      } else {
        const uri = getRequestURI('productRelation_insert')
        request(uri, obj).then(res => {
          if (res.code == 1) {
            message['success'](res.message)
            emit('select')
            onClose()
          } else {
            message['success'](res.message)
          }
          console.debug(res)
        })
      }
    }
    const init = function () {
      request(getExtendUri('dictionary_query_byType'), {data: {types: ['apptype']}}).then(res => {
        console.debug(res)
        res.result.apptype.forEach(item => {
          let obj = {}
          obj.label = item.label
          obj.value = item.value
          // listAccount.jiaofeifangshi.push(obj)
        })
      })

      request(getExtendUri('dictionary_query_byType'), {data: {types: ['insurance_period']}}).then(res => {
        console.debug(res)
        res.result.insurance_period.forEach(item => {
          let obj = {}
          obj.label = item.label
          obj.value = item.value
          // listAccount.baoxianqijian.push(obj)
        })
      })
      request(getExtendUri('dictionary_query_byType'), {data: {types: ['renew_period']}}).then(res => {
        res.result.renew_period.forEach(item => {
          let obj = {}
          obj.label = item.label
          obj.value = item.value
          // listAccount.jiaofeiqijian.push(obj)
        })
        console.debug(res)
      })

    }
    init()
    expose({
      showDrawer
    })
    return {
      ...toRefs(state),
      profitRateBlur,
      handelRenewRatio,
      inNew,
      onClose,
      formDataAccount,
      listAccount,
      gunlianleibie,
      changeAll,
      isDisable,
      onSave,
      showDrawer,
      onSelectChange,
      addOne,
      delAll,
      addAll,
      quickEnterRef,
      tableChange,
      filterOption,
      formRef,
      xianzhongmingcheng1,
      guanlianleibie,
      jiesuanleixing,
      jiaofeifangshi,
      jiaofeiqijian,
      baoxianqijian,
    }
  }

})
</script>

<style scoped>

</style>
