<style scoped>
.page_top {
  display: flex;
  width: 100%;
  height: calc(70% - 10px);
  text-align: left;
}

.page_split {
  width: 100%;
  height: 10px;
  cursor: ns-resize;
  background-color: #fafafa;
}

.page_bottom {
  width: 100%;
  height: 30%;
  text-align: left;
}

.page_split2 {
  width: 10px;
  height: 100%;
  cursor: ew-resize;
  background-color: #fafafa;
}

.page_editor {
  width: calc(60% - 10px);
  height: 100%;
  border: 1px solid #3883fa;
}

.page_data {
  width: 40%;
  height: 100%;
}
.script_editor {
  height: calc(100% - 35px);
}

.page_data .json_editor, .page_data {
  height: 100%;
}

:deep(.jsoneditor-poweredBy), :deep(.jsoneditor-modes), :deep(.full-screen) {
  display: none;
}

.btn_bar {
  width: 100%;
  height: 35px;
  padding: 0 2px;
  line-height: 35px;
  letter-spacing: 16px;
  background-color: #3883fa;
  color: #ffffff;
}
.tool_btn {
  opacity: .8;
}
.log {
  width: 100%;
  height: calc(100% - 48px);
  line-height: 28px;
}

.logText {
  width: 100%;
  height: 100%;
  border: 1px solid #3883fa;
}


.log[contenteditable]:focus {
  outline: none;
}
</style>

<template>
  <div style="width: 100%; height: 100%; padding: 10px;">
    <div class="page_top">
      <div class="page_editor">
        <div class="btn_bar">
          <a-tooltip placement="bottomLeft" title="校验数据">
            <a-button type="text" class="tool_btn" :loading="loading" @click="onCheckData">
              <template #icon>
                <setting-filled style="color: #FFFFFF;"/>
              </template>
            </a-button>
          </a-tooltip>
        </div>
        <MonacoEditor ref="mEditor" class="script_editor" :inputContent="scriptText"
                      @scriptChange="scriptChange" @clearRunStr="clearRunStr"></MonacoEditor>
      </div>
      <div class="page_split2"></div>
      <div class="page_data">
        <json-editor-vue class="json_editor" v-model="jsonData" :options="jsonOptions" language="zh-CN"/>
      </div>
    </div>
    <div class="page_split"></div>
    <div class="page_bottom">
      <div class="btn_bar">
        <a-tooltip placement="bottomLeft" title="清空日志">
          <a-button type="text" class="tool_btn"  @click="onClick">
            <template #icon>
              <delete-filled style="color: #FFFFFF;"/>
            </template>
          </a-button>
        </a-tooltip>
      </div>
      <div class="log">
        <!-- 确定时需要这个数据 -->
        <textarea readonly="readonly" class="logText" v-model="log" style="resize: none;"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive, toRefs, ref, watch} from 'vue'
import JsonEditorVue from 'json-editor-vue3'
import MonacoEditor from './view/MonacoEditor'
import {getTextTo} from './view/gzyq'
import {request} from '../../utils/request'
import {message} from 'ant-design-vue'

export default defineComponent({
  components: {
    JsonEditorVue,
    MonacoEditor
  },
  props: {
    ruleText: {
      type: String,
      required: true
    }
  },
  setup(props, {expose}) {

    const mEditor = ref(null)
    const state = reactive({
      loading: false,
      jsonData: {},
      jsonOptions: {},
      log: '',
      scriptText: '',
      runStr: ''
    })
    watch(props, (val) => {
      state.scriptText = val.ruleText
    }, {immediate: true})
    const scriptChange = (param) => {
      console.debug('scriptChange', param)
      let a = param.originalStrArray
      let buildStr = ''
      for (let i = 0; i < a.length; i++) {
        let text = a[i]
        buildStr += getTextTo(text) + '\n'
      }
      state.runStr = buildStr
      state.scriptText = param.originalStr
    }
    const onClick = () => {
      state.log = ''
    }

    const clearRunStr = () => {
      state.runStr = ''
    }

    const getRes = (array) => {
      let a = []
      for (let i = 0; i < array.length; i++) {
        let obj = array[i]
        let str
        if (obj.result['noException']) {
          str = `规则表达式 ： \n${obj.expression}\n\n规则校验结果：\n ${JSON.stringify(obj.result.result)}\n`
        } else {
          str = `规则表达式 ： \n${obj.expression}\n\n${JSON.stringify(obj.result.result)}\n`
        }
        a.push(str)
      }
      return a.join('\n-------------------\n')
    }
    const getRunStr = () => {
      mEditor.value.getRunScript()
      return {
        runStr: state.runStr,
        originalStr: state.scriptText
      }
    }
    expose({
      getRunStr
    })
    /**
     * 校验单条规则的网络请求
     */
    const onCheckData = () => {
      mEditor.value.getRunScript()
      if (!state.jsonData || state.jsonData === {}) {
        return message.error('json对象不能为空')
      }
      if (!state.runStr || state.scriptText === '') {
          return message.error('规则代码不能为空')
      }
      let param = {
        ruleScript: state.runStr,
        testJson: state.jsonData
      }
      //  入参 规则字符串和对象json
      let uri = '/bee-rule-service/rule/ruleRun/api/runRuleTest'
      state.loading = true
      request(uri, param).then(res => {
          state.loading = false
          if (res.code == 1) {
            message.success('请求成功'),
              state.log = getRes(res.result)
          } else {
            message.warning(res.message)
          }
        }
      )
    }
    return {
      ...toRefs(state),
      mEditor,
      scriptChange,
      onClick,
      clearRunStr,
      onCheckData
    }
  }
})
</script>
