<style scoped>
.editor_content_tool {
  background: #54585a;
  height: 48px;
  position: fixed;
  z-index: 90000;
  width: 560px;
  color: #FFFFFF;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #FFFFFF;
  box-shadow: 0 0 15px #666666;
}
.dragging {
  cursor: move;
}
:deep(.ant-btn-text) {
  color: #FFFFFF;
}
.zs-color-picker {
  display: block;
}
:deep(.zs-color-picker-btn) {
  border-radius: 50%;
  border: none;
}
:deep(.zs-color-picker-btn-color) {
  border: none !important;
  border-radius: 50%;
}
:deep(.color-view) {
  border: 1px solid #ddd;
}
</style>

<template>
  <div class="editor_content_tool editor_tool" ref="dragLayoutRef">
    <a-space>
      <drag-outlined class="dragging" @mousedown="startDrag"/>
      <a-dropdown size="small" type="text">
        <template #overlay>
          <a-menu theme="dark" @click="handleMenuClick">
            <a-menu-item key="1">
              字体1
            </a-menu-item>
            <a-menu-item key="2">
              字体2
            </a-menu-item>
            <a-menu-item key="3">
              字体4
            </a-menu-item>
          </a-menu>
        </template>
        <a-button size="small" type="text">
          默认字体
          <DownOutlined />
        </a-button>
      </a-dropdown>
      <a-dropdown size="small" type="text">
        <template #overlay>
          <a-menu theme="dark" @click="handleMenuClick">
            <a-menu-item key="fontSize:12px">
              12
            </a-menu-item>
            <a-menu-item key="fontSize:18px">
              18
            </a-menu-item>
          </a-menu>
        </template>
        <a-button size="small" type="text">
          字号: {{contextStyle.fontSize}}
          <DownOutlined />
        </a-button>
      </a-dropdown>
      <color-picker @change="colorChange" defaultColor="#FFFFFF" v-model:hex="contextStyle.color"></color-picker>
      <a-dropdown>
        <template #overlay>
          <a-menu @click="handleMenuClick">
            <a-menu-item key="1">
              <UserOutlined />
              1st menu item
            </a-menu-item>
            <a-menu-item key="2">
              <UserOutlined />
              2nd menu item
            </a-menu-item>
            <a-menu-item key="3">
              <UserOutlined />
              3rd item
            </a-menu-item>
          </a-menu>
        </template>
        <a-button size="small" type="text">
          样式
          <DownOutlined />
        </a-button>
      </a-dropdown>
      <a-dropdown>
        <template #overlay>
          <a-menu @click="handleMenuClick">
            <a-menu-item key="1">
              <UserOutlined />
              1st menu item
            </a-menu-item>
            <a-menu-item key="2">
              <UserOutlined />
              2nd menu item
            </a-menu-item>
            <a-menu-item key="3">
              <UserOutlined />
              3rd item
            </a-menu-item>
          </a-menu>
        </template>
        <a-button size="small" type="text">
          位置
          <DownOutlined />
        </a-button>
      </a-dropdown>
    </a-space>
  </div>
</template>

<script>
import {defineComponent, reactive, ref, toRefs} from 'vue'
import {message} from 'ant-design-vue'

export default defineComponent({
  setup(props, context) {
    const state = reactive({
      editContextId: -1,
      currentTarget: null,
      contextStyle: {
        fontSize: '12',
        color: '#000000'
      }
    })
    const handleMenuClick = (action) => {
      const style = action.key.split(':')
      if (style && style.length === 2) {
        state.contextStyle[style[0]] = style[1]
        if (state.currentTarget) {
          Object.assign(state.currentTarget.style, state.contextStyle)
        }
        console.log(state.contextStyle, state.currentTarget.style)
      } else {
        message['warning']('配置信息不正确')
      }
    }
    const colorChange = (e) => {
      console.log('colorChange', e)
      Object.assign(state.currentTarget.style, state.contextStyle)
    }
    const updateContextId = (id, target, t) => {
      state.editContextId = id
      dragState.isDrag = false
      state.currentTarget = target
      dragLayoutRef.value.style.top = t
    }
    // --- drag ---
    const dragLayoutRef = ref()
    const dragState = reactive({
      isDrag: false,
      mouseX: 0,
      mouseY: 0,
      originalX: 0,
      originalY: 0
    })
    const startDrag = (event) => {
      if (event.button === 0) {
        dragState.mouseX = event.clientX
        dragState.mouseY = event.clientY
        dragState.originalX = dragLayoutRef.value.offsetLeft
        dragState.originalY = dragLayoutRef.value.offsetTop
        dragLayoutRef.value.style.zIndex = 91000
        dragState.isDrag = true
        document.addEventListener('mouseup', stopDrag)
        document.addEventListener('mousemove', drag)
      }
    }

    const drag = (event) => {
      if (event.button === 0 && dragState.isDrag) {
        event.stopPropagation()
        dragLayoutRef.value.style.left = (dragState.originalX - (dragState.mouseX - event.clientX)) + 'px'
        dragLayoutRef.value.style.top = (dragState.originalY - (dragState.mouseY - event.clientY)) + 'px'
      }
    }
    const stopDrag = () => {
      dragState.isDrag = false
      dragLayoutRef.value.style.zIndex = 90000
      document.removeEventListener('mouseup', stopDrag)
      document.removeEventListener('mousemove', drag)
    }
    // --- drag end ---
    context.expose({
      updateContextId
    })
    return {
      handleMenuClick,
      colorChange,
      ...toRefs(dragState),
      ...toRefs(state),
      startDrag,
      drag,
      stopDrag,
      dragLayoutRef
    }
  }
})
</script>
