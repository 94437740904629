<template>
  <div>
    <div id="codeEditBox" class="editorBoxStyle"></div>
    <a-row  type="flex" class="btnLineStyle" >
      <a-col :flex="2" style="text-align: left;margin-left: 50px">
        <span>{{ status_bar_text }}</span>
      </a-col>
    </a-row>
  </div>

</template>

<script>
import * as monaco from 'monaco-editor'
import {ref, reactive, toRefs, toRaw, onMounted, onUnmounted} from 'vue'
import {getAutoCompletion, getRulConfig} from './gzyq'

export default {
  name: 'MonacoEditor',
  emits: ['scriptChange','clearRunStr'],
  props: {
    inputContent: {
      type: String,
      required: true
    }
  },
  setup(props, {emit, expose}) {
    const jsConfig = getRulConfig()
    const editor = ref(null)
    const provider = ref(null)
    const myLang = ref(null)
    const color = ref(null)
    const theme = ref(null)
    const hover = ref(null)
    const state = reactive({
      languageId: 'rulesEngine',
      status_bar_text: 'Ln: 0, Col: 0'
    })


    const cloneDeep = (suggestions) => {
      return JSON.parse(JSON.stringify(suggestions))
    }
    const hoverTips = (arr, word) => {
      let tip = ''
      arr.forEach((item) => {
        if (word == item.text) {
          tip = {
            contents: [{value: item.title || ''}, {value: item.content || ''}],
          }
        }
      })
      return tip
    }
    const initEditor = () => {
      monaco.languages.register({id: state.languageId}) // 注册规则引擎语言
      provider.value = monaco.languages.registerCompletionItemProvider(
        state.languageId,
        {
          provideCompletionItems(model, position) {
            console.debug('provideCompletionItems', model, position)
            return {
              suggestions: cloneDeep(getAutoCompletion()),//自定义代码补全
            }
          },
          triggerCharacters: ['.']
        }
      )
      myLang.value = monaco.languages.setLanguageConfiguration(state.languageId, {
        //自定义括号，冒号等符号的补全规则
        brackets: [
          ['{', '}'],
          ['[', ']'],
          ['(', ')'],
        ],
        autoClosingPairs: [
          {open: '{', close: '}'},
          {open: '[', close: ']'},
          {open: '(', close: ')'},
          {open: '"', close: '', notIn: ['string']},
          {open: '\'', close: '', notIn: ['string', 'comment']},
          {open: '`', close: '`', notIn: ['string', 'comment']},
        ],
        surroundingPairs: [
          {open: '{', close: '}'},
          {open: '[', close: ']'},
          {open: '(', close: ')'},
          {open: '"', close: '"'},
          {open: '\'', close: '\''},
          {open: '<', close: '>'}
        ],
      })

      color.value = monaco.languages.setMonarchTokensProvider(state.languageId, {
        //自定义文本颜色
        ignoreCase: true,
        test: ['abc', '张紫薇'],
        keywords: jsConfig['keywords'],
        keywords_CN: jsConfig['keywords_CN'],
        objects_CN: jsConfig['objects_CN'],
        objects: jsConfig['objects'],
        functions_CN: jsConfig['functions_CN'],
        functions: jsConfig['functions'],
        operators_CN: jsConfig['operators_CN'],
        operators: jsConfig['operators'],
        symbols: /[=><!~?:&|+*/^%]+/,
        digits: /\d+(_+\d+)*/,
        octaldigits: /[0-7]+(_+[0-7]+)*/,
        binarydigits: /[0-1]+(_+[0-1]+)*/,
        hexdigits: /[[0-9a-fA-F]+(_+[0-9a-fA-F]+)*/,
        tokenizer: {
          root: [
            // todo 被正则为难的那些日子``````
            // [/[//]+\s+[todo]+\s+.*?/, 'string.escape'], // todo TODO提示`````不会写
            // whitespace
            {include: '@whitespace'},
            [/null|undefined|NaN/, {token: 'empty.key'}], //粉色
            // numbers - 绿
            [/\d*\.\d+([eE][-+]?\d+)?/, 'number.float'],
            [/0[xX][0-9a-fA-F]+/, 'number.hex'],
            [/\d+/, 'number'],
            [/(?!.*[a-zA-Z])[0-9]/,  'number.hex'],
            // 英文字符类
            [/[a-zA-Z\\.0-9]+/, {
              cases: {
                '@objects': 'custom-obj',
                '@keywords': 'custom-key',
                '@functions': 'custom-fun',
                '@test':'custom-error',
                '@default': 'identifier'
              }
            }],
            // [/[a-z_$][\w$]*/, {
            //   cases: {
            //     '@keywords': 'custom-key',
            //     '@functions': 'custom-fun',
            //     '@default': 'identifier'
            //   }
            // }],
            // 中文字符
            // [/[\u4e00-\u9fa5_$][\w$]*/, {
            [/[\u4e00-\u9fa5\\.0-9a-zA-Z]+/, {
              cases: {
                '@keywords_CN': 'custom-key',
                '@objects_CN': 'custom-obj',
                '@functions_CN': 'custom-fun',
                '@operators_CN': 'custom-regexp',
                '@test':'custom-fun',
                '@default': 'identifier'
              }
            }],
            // [/[\w]/, {
            //   cases: {
            //     '@test': 'custom-fun',
            //     '@default': ''
            //   }
            // }],
            [/[{}()[\]]/, 'custom-regexp'],
            [/[<>](?!@symbols)/, '@brackets'],
            [
              /@symbols/,
              {
                cases: {
                  '@operators': 'custom-regexp',
                  '@default': ''
                }
              }
            ],
            [/'.*?'|".*?"/, 'string.escape'],
            [/【.*?】|「.*?」|（.*?）/, 'custom-error'],
            [/#--.*?--#/, {token: 'comment'}], //绿色
            // delimiter: after number because of .\d floats
            [/[;,.]/, 'delimiter'],
            // strings
            [/"([^"\\]|\\.)*$/, 'string.invalid'],
            // non-teminated string
            [/"/, 'string.quote'],
            // characters
            [/'[^\\']'/, 'string'],
            [/'/, 'string.invalid']],
          comment: [
            [/[^\\/*]+/, 'comment'],
            [/\/\*/, 'comment', '@push'],
            // nested comment
            ['\\*/', 'comment', '@pop'],
            [/[\\/*]/, 'comment']
          ],
          whitespace: [
            [/[ \t\r\n]+/, 'white'],
            [/\/\*/, 'comment', '@comment'],
            [/\/\/.*$/, 'comment'],
          ]
        },
      })
      // 自定义颜色规则
      theme.value = monaco.editor.defineTheme('myCoolTheme', {
        base: 'vs-dark', // 以vscode的dark样式为基础做调整
        inherit: false,
        rules: [
          {token: 'custom-info', foreground: '808080'},
          {token: 'custom-error', foreground: 'ff0000', fontStyle: 'bold'},
          {token: 'custom-notice', foreground: 'ffbd59'},
          {token: 'custom-date', foreground: '008800'},
          {token: 'custom-obj', foreground: '9F73AE', background: 'ffffff'},
          {token: 'custom-fun', foreground: 'f5e805'},
          {token: 'custom-key', foreground: 'fa8603', fontStyle: 'bold'},
          {token: 'custom-regexp', foreground: '0bf19d'},
          {token: 'number.float', foreground: '46e80b'},
          {token: 'number.hex', foreground: '469629'},
          {token: 'number', foreground: 'a6d794'},
          {token: 'comment', foreground: '80817f'},
          {token: 'white', foreground: 'ffffff'},
          {token: 'delimiter', foreground: '80817f'},
          {token: 'string.escape', foreground: 'a3f307'},
          {token: 'string.quote', foreground: 'FFBD59'},
          {token: 'string.invalid', foreground: '93bb1a'},
          {token: 'string.escape.invalid', foreground: '7d8813'},
          {token: 'empty.key', foreground: 'cb15f5'},
        ],
        colors: {
          // 相关颜色属性配置
          'editor.foreground': '#ffffff',// 字体颜色
          // 'editor.background': '#3E4244' // 背景色
          'editorCursor.foreground': '#fff',
          'editor.lineHighlightBackground': '#514F37FF',
          'editorLineNumber.foreground': '#80817f',
          'editor.selectionBackground': '#194184FF',
          'editor.inactiveSelectionBackground': '#F5E805FF'
        }
      })
      hover.value = monaco.languages.registerHoverProvider(state.languageId, {
        //自定义文字悬浮提示
        provideHover: (model, position) => {
          if (model.getWordAtPosition(position) != null) {
            const word = model.getWordAtPosition(position).word
            let arr = [
              {
                text: '规则引擎', //悬浮文字
                title: '规则引擎', //标题
                content: '--对数据进行操作，支持curd等操作--', //提示内容
              },
            ]
            return hoverTips(arr, word)
          }
        },
      })
      let uri = monaco.Uri.parse('file://rule-' + new Date().getMilliseconds() + '.txt')
      let model = monaco.editor.getModel(uri) // 如果该文档已经创建/打开则直接取得已存在的model
      if (!model) {
        // 否则创建新的model
        model = monaco.editor.createModel(props.inputContent, state.languageId, uri) // 如 code="console.log('hello')", language="javascript"
      }

      editor.value = monaco.editor.create(document.getElementById('codeEditBox'), {
        model: model, // 采用model的形式
        //初始化配置
        value: props.inputContent,
        language: state.languageId, // 语言类型
        tabCompletion: 'on',
        cursorSmoothCaretAnimation: true,
        formatOnPaste: true,
        mouseWheelZoom: true,
        folding: true, //代码折叠
        autoClosingBrackets: 'always',
        autoClosingOvertype: 'always',
        autoClosingQuotes: 'always',
        theme: 'myCoolTheme', //官方自带三种主题vs, hc-black, or vs-dark
        selectOnLineNumbers: true,//显示行号
        roundedSelection: false,
        readOnly: false, // 只读
        cursorStyle: 'line', //光标样式
        automaticLayout: true, //自动布局
        glyphMargin: true, //字形边缘
        useTabStops: false,
        fontSize: 14, //字体大小
        autoIndent: true, //自动布局
        quickSuggestionsDelay: 100, //代码提示延时
        minimap: {
          enabled: false // 不要小地图
        },
      })
      // 监听值的变化 - 输入的字符
      editor.value.onDidChangeModelContent(() => {
        // let value = editor.value.getValue()
        // console.log(value)
        emit('clearRunStr')
      })
      // 监控光标位置变化
      editor.value.onDidChangeCursorPosition((val) => {
        state.status_bar_text = `Ln: ${val.position.lineNumber}，Col: ${val.position.column}`
      })
    }
    //获取编辑器中的文本
    // 坑```vue3 获取editor需要用toRaw
    const getRunScript = () => {
      emit('scriptChange', {
        originalStr: toRaw(editor.value).getModel().getValue(),
        originalStrArray: toRaw(editor.value).getModel().getLinesContent()
      })
    }
    expose({
      getRunScript
    })
    onMounted(initEditor)
    onUnmounted(()=> {
      // 销毁
      toRaw(editor.value).dispose()
      toRaw(provider.value).dispose()
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>

<style scoped>
.editorBoxStyle {
  height: 94%;
  width: 100%;
}
.btnLineStyle {
  background-color:#ebebeb;
  height: 6%;
  width: 100%;
  align-items: center;
}
</style>
