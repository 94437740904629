<style scoped>
  :deep(.ant-drawer-body){
    padding-top: 0 !important;
  }
  .link-class{
    margin-top: 10px;
  }
  .copy-class{
    cursor: pointer;
    font-size: 17px;
    color: rgb(31, 161, 243);
    text-align: center;
  }


</style>

<template>
  <a-drawer
    :title="isNew ? '渠道新增' : '渠道修改'"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
  <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="1" :tab="isNew ? '渠道新增' : '渠道修改'">
        <a-form :model="formData" :rules="formRules" layout="vertical" ref="formRef" autocomplete="off">
          <a-form-item name="id" v-if="false">
            <a-input v-model:value="formData.id" />
          </a-form-item>
          <a-row :gutter="10">
            <a-col span="12">
              <a-form-item label="渠道名称: " name="channelName" required>
                <a-input v-model:value="formData.channelName" placeholder="请输入渠道名称"/>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="渠道编码: " name="channelCode">
                <a-input v-model:value="formData.channelCode" placeholder="请输入渠道编码"/>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="渠道类型:" name="channelType" required>
                <a-select v-model:value="formData.channelType" :options="ChannelTypeList" placeholder="请选择渠道类型">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="渠道说明:" name="channelDesc" required>
                <a-input v-model:value="formData.channelDesc" placeholder="请输入渠道说明"/>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="渠道代理人ID:" name="channelUserId"
                :rules="[{max: 400, message: '输入长度不能超过400' ,trigger: 'change'},
                  {pattern:/^[a-zA-Z0-9]*$/, message: '只支持字母、数字格式' ,trigger: 'change'}
                ]"
              >
                <a-input v-model:value="formData.channelUserId" placeholder="请输入渠道说明"/>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="渠道推广费:" name="channelPromotionFee" required
                           :rules="[{required:true,message:'必填信息',trigger:'change'},
              {pattern:/^([0-9]+[\d]*(.[0-9]{1,2})?)$/, message: '只能输入数字和小数' ,trigger: 'change'}
            ]"
              >
                <a-input v-model:value="formData.channelPromotionFee" placeholder="请输入渠道推广费"/>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="是否校验代理人" name="isVerifyAgent">
                <a-select
                  :options="isHotSellingList"
                  v-model:value="formData.isVerifyAgent" placeholder="请选择是否校验代理人">
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-tab-pane>
      <a-tab-pane key="2" tab="渠道链接" v-if="!isNew">
        <div class="btn-bar">
          <a-button type="primary" @click="hnadelChannelink">生成渠道专属链接</a-button>
        </div>
        <div class="link-class">
          <a-row>
            <a-col :span="12">
              <a-input id="inputId" readonly v-model:value="formData.channelLink" placeholder="请点击按钮,自动生成"></a-input>
            </a-col>
            <a-col :span="3">
              <p class="copy-class" @click="handelCopy">复制</p>
            </a-col>
          </a-row>
        </div>
      </a-tab-pane>
  </a-tabs>

    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary" @click="onSave">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script>
import {CloseOutlined, SaveOutlined} from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import {defineComponent, reactive, toRefs, ref, onBeforeMount} from 'vue'
import {getRequestURI, request} from '../../../utils/request.js'
// import {getExtendUri} from '../../../utils/request_extend'
import {getDictionaryData} from '../../../utils/request_extend'
export default defineComponent({
  components: {
    CloseOutlined, SaveOutlined
  },
  props: {
    searchfFilters: { type: Object },
    handleSearch: {type: Function}
  },
  setup(props, {expose}) {
    const formRef = ref()
    const formData = reactive({
      id: undefined,
      // 渠道名称
      channelName: undefined,
      // 渠道编码
      channelCode: undefined,
      // 渠道类型
      channelType: undefined,
      // 渠道说明
      channelDesc: undefined,
      // 渠道推广费
      channelPromotionFee: undefined,
      isVerifyAgent: 'Y',
      channelUserId: undefined,
      channelLink: undefined
    })
    const formRules = {
      channelName: [
        {required: true, message: '请输入渠道名称', trigger: 'change'}
      ],
      channelCode: [
        {required: true, message: '请输入渠道编码', trigger: 'change'},
        {pattern:/^[a-zA-Z0-9]*$/, message: '只支持字母、数字格式' ,trigger: 'change'}
      ],
      channelType: [
        {required: true, message: '请选择渠道类型', trigger: 'change'}
      ],
      channelDesc: [
        {required: true, message: '请输入渠道说明', trigger: 'change'}
      ],
      channelPromotionFee: [
        {required: true, message: '请输入渠道推广费', trigger: 'change'}
      ],
    }
    const state = reactive({
      visible: false,
      isNew: true,
      activeKey: '1',
    })
      const ChannelTypeList = ref([])

    const showDrawer = (oldData, num) => {
      state.editDetailData = oldData
      if(oldData === 1 || num === 1) {
        state.isNew = true
      } else if(num === 2) {
        state.isNew = false
        Object.keys(formData).forEach(key => formData[key] = oldData[key])
      }
      state.visible = true
    }


    const onSave = async () => {
      let data = {}
        await formRef.value.validate()
        let url = getRequestURI('channel_update')
        if (state.isNew) {
          url = getRequestURI('channel_save')
          }
        request(url, {data: formData}).then(result => {
          if(result.code === 1){
            message.success('保存成功')
            state.visible = false
            onClose()
            let params={}
            if(JSON.stringify(data)==='{}'){
              params= {...props.searchfFilters}
            }else{
              params={...data}
            }
            props.handleSearch(params)
          }else{
            message.warning(result.message)
          }
        })
      }
    const onClose = () => {
      formRef.value.clearValidate()
      Object.assign(formData, {
          id: 0,
          //渠道名称
          channelName: undefined,
          //渠道编码
          channelCode: undefined,
          //渠道类型
          channelType: undefined,
          //渠道说明
          channelDesc: undefined,
          channelPromotionFee: undefined,
          isVerifyAgent: 'Y',
          channelUserId: undefined,
          channelLink: undefined
      })
      state.visible = false
    }
    // 复制
    const handelCopy = () => {
      if(!formData.channelLink) return message.warning('请点击生成链接后复制')
      let input = document.getElementById('inputId')
      input.setAttribute('value', input.value)
      input.select()
      document.execCommand('copy', 'false', null)                //复制该文本
      message.success('复制成功')

    }
    // 生成渠道链接
    const hnadelChannelink = () => {
      request(getRequestURI('channel_createChannelPProductLink'), {data: {id: state.editDetailData.id}}).then(
        res =>{
          console.log('得到返回的值', res)
          if(res.code === 1){
            formData.channelLink = res.result
            message.success('生成渠道链接成功')
          }else{
            message.warning(res.message)
          }
      })

    }
    const isHotSellingList = ref()


    onBeforeMount(()=> {
      getDictionaryData(ChannelTypeList, 'channel_type')
      getDictionaryData(isHotSellingList, 'yes_no')

    })
    expose({
      showDrawer
    })
    return {
      isHotSellingList,
      hnadelChannelink,
      handelCopy,
      ChannelTypeList,
      formRef,
      formData,
      formRules,
      ...toRefs(state),
      onClose,
      onSave,
    }
  },

})
</script>
