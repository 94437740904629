<template>
  <a-drawer
    :title="isTitle"
    :width="'70%'"
    :visible="visible"
    @close="onClose"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }">
    <a-form
      :model="formData"
      layout="vertical"
      ref="formRef"
      autocomplete="off"
    >

      <template v-for="(item, index) in fieldList" :key="index">
      <a-row :gutter="10">
        <a-col span="6">
          <a-form-item>
            <a-select v-model:value="item.profileName"  placeholder="请选择名称"
                      show-search
                      :filter-option="filterOption"
                      :options="tempTypeList" @change="changeSelectName"
                      :field-names="{label:'',key:'id',}"
                      :disabled="item.isDisable" style="width: 240px; margin-right: 10px"></a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item>
          <a-input v-model:value="item.value" :disabled="item.isDisable" placeholder="请输入参数配置" />
          </a-form-item>
        </a-col>
        <a-col span="6">
          <a-form-item>
            <a-button type="primary" @click="removeRowConfig(item, index)" :disabled="item.isDisable">删除</a-button>
          </a-form-item>
        </a-col>
      </a-row>
      </template>
      <template v-if="isDetailsItemShow">
        <div class="details_item">请添加参数项!</div>
      </template>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button html-type="submit" type="primary" @click="onSave()" v-if="!isDisable">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
    <a-button type="primary" @click="addRowConfig" v-if="!isDisable">增加一行</a-button>
  </a-drawer>
</template>

<script>
  import {computed, defineComponent, nextTick, onMounted, reactive, ref, toRefs} from 'vue'
   import {getRequestURI, request} from '../../../utils/request'
  import {getDictionaryData} from '../../../utils/request_extend'
  import { message } from 'ant-design-vue'
  export default defineComponent({
    components: {

    },
    props: {
      status: {
        type: String,
        required: true,
        default() {
          return ' '
        }
      },
    },
    emits:['select'],
    setup(props, {expose, emit}) {
      const supplierList = ref()
      const tempTypeList = ref()
      const fieldList = ref([])
      const formRef = ref()
      const state = reactive({
        visible: false,
        isDetailsItemShow: false,
        supId: ''
      })
      const formData = reactive({
        id: '',
        supId: '',
        value: '',
        createTime: null,
        updateTime: null,
        createUser: null,
        updateUser: null,
        isDel: 0,
        profileName: '',
        isDisable: false
      })

      const isDisable = computed(() => {
        if (props.status === 'details') {
          return true
        } else {
          return false
        }
      })
      const isTitle = computed(() => {
        if (props.status === 'details') {
          return '查看'
        } else  {
          return '编辑'
        }
      })
      const filterOption = (input, option) => {
        return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
       const changeSelectName = (event) => {
         const value =   fieldList.value.filter(item=>item.profileName === event)
         if(value.length>1) {
           message.warn('禁止添加重复列表项')
           nextTick(()=> {
           fieldList.value[fieldList.value.length-1] = {
             ...formData,
             supId: state.supId
           }
           })

         }

       }

      const showDrawer = (oldData = {}, params, supId) => {
        state.visible = true
        if(params === 'details') {
          if(oldData.length>0) {
            const dataList = oldData.map((item) => {
              return {
                ...item,
                isDisable: true
              }
            })
            fieldList.value = dataList
            state.isDetailsItemShow = false
          }else {
            state.isDetailsItemShow = true
          }
        }else if(params === 'edit') {

          state.supId = supId
          state.isDetailsItemShow = false
          if( oldData.length>0) {
          fieldList.value = oldData
          }else  {
            fieldList.value.push({
              ...formData,
              supId: supId
            })
          }
        }
      }
      // 添加一行字典表
      const addRowConfig = () => {
        // 取数组最后一项
        let last = fieldList.value[fieldList.value.length - 1]
        const list = fieldList.value.filter(item => item.value.trim() === '' || item.value === undefined ) || []
        if ((last && last.profileName && !list.length) || !last) {
          fieldList.value.push({
            ...formData,
            supId: state.supId,
          })
        } else  {
          message.warn(`${!last || !last.profileName ? '请选择下拉框内容' : '请输入对应的参数配置'}`)
        }
      }
      const removeRowConfig =(field, index) =>  {
        fieldList.value.splice(index, 1)
      }

      const onSave =  () => {
        // 最后一项为空时候拦截
        let last = fieldList.value[fieldList.value.length - 1]
        if (last && (!last.profileName || !last.value) ) {
          message.warn(`${!last.profileName ? '请选择下拉框内容' : '请输入对应的参数配置'}`)
          return
        }
          const uri = getRequestURI('config_insert')
          request(uri, {data: {
              supId: state.supId,
              insuranceProfileEntityList: fieldList.value
            }}).then(res => {
            if (res.code === 1) {
              message['success'](res.message)
              onClose()
              emit('select')
            } else {
              message['error'](res.message)
            }
          })

      }

      const onClose = () => {
        formRef.value.clearValidate()
        fieldList.value = []
        state.visible = false
      }

      onMounted(() => {
        getDictionaryData(tempTypeList, 'insurance-profile')

      })
      expose({
        showDrawer
      })
      return {
        ...toRefs(state),
        formData,
        tempTypeList,
        isDisable,
        onClose,
        onSave,
        supplierList, // 供应商
        fieldList,
        isTitle,
        formRef,
        changeSelectName,
        addRowConfig,
        removeRowConfig,
      filterOption
      }
    }
  })
</script>

<style scoped>
  .details_item {
    font-size: 24px;
    margin: auto;
    text-align: center;
    color: red;
  }
</style>
