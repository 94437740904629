<style scoped>
</style>

<template>
  <a-drawer
    :title="isNew ? '渠道新增' : '渠道修改'"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form :model="formData" :rules="formRules" layout="vertical" ref="formRef" autocomplete="off">
      <a-form-item name="id" v-if="false">
        <a-input v-model:value="formData.id" />
      </a-form-item>
      <a-row :gutter="10">
        <a-col span="12">
          <a-form-item label="渠道名称: " name="channelName" required>
            <a-input v-model:value="formData.channelName" placeholder="请输入渠道名称"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="渠道编码: " name="channelCode"
            :rules="[{required:true,message:'必填信息',trigger:'change'},
              {pattern:/^[a-zA-Z0-9]*$/, message: '只支持字母、数字格式' ,trigger: 'change'}
            ]"
          >
            <a-input v-model:value="formData.channelCode" placeholder="请输入渠道编码"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="渠道类型:" name="channelType" required>
            <a-select v-model:value="formData.channelType" :options="ChannelTypeList" placeholder="请选择渠道类型">
              <!-- <a-select-option :value="type.value" v-for="type in ChannelTypeList" :key="type.value">{{type.label}}</a-select-option> -->
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="渠道说明:" name="channelDesc">
            <a-input v-model:value="formData.channelDesc" placeholder="请输入工号"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="渠道推广费:" name="channelPromotionFee">
            <a-input v-model:value="formData.channelPromotionFee" placeholder="请输入渠道推广费"/>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary" @click="onSave">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script>
import {CloseOutlined, SaveOutlined} from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import {defineComponent, reactive, toRefs, ref, onBeforeMount} from 'vue'
import {getRequestURI, request} from '../../../utils/request.js'
// import {getExtendUri} from '../../../utils/request_extend'
import {getDictionaryData} from '../../../utils/request_extend'
export default defineComponent({
  components: {
    CloseOutlined, SaveOutlined
  },
  props: {
    handleSearch: {type: Function}
  },
  setup(props, {expose}) {
    const formRef = ref()
    const formData = reactive({
      id: undefined,
      // 渠道名称
      channelName: undefined,
      // 渠道编码
      channelCode: undefined,
      // 渠道类型
      channelType: undefined,
      // 渠道说明
      channelDesc: undefined,
      // 渠道推广费
      channelPromotionFee: undefined
    })
    const formRules = {
      channelName: [
        {required: true, message: '请输入渠道名称', trigger: 'change'}
      ],
      channelCode: [
        {required: true, message: '请输入渠道编码', trigger: 'change'}
      ],
      channelType: [
        {required: true, message: '请选择渠道类型', trigger: 'change'}
      ],
      channelDesc: [
        {required: true, message: '请输入渠道说明', trigger: 'change'}
      ],
      channelPromotionFee: [
        {required: true, message: '请输入渠道推广费', trigger: 'change'}
      ],
    }
    const state = reactive({
      visible: false,
      isNew: true,
    })
      const ChannelTypeList = ref([])

    const showDrawer = (oldData, num) => {
      if(oldData === 1 || num === 1) {
        state.isNew = true
      } else if(num === 2) {
        state.isNew = false
        Object.keys(formData).forEach(key => formData[key] = oldData[key])
      }
      state.visible = true
    }


    const onSave = async () => {
      let data = {}
        await formRef.value.validate()
        let url = getRequestURI('channel_update')
        if (state.isNew) {
          url = getRequestURI('channel_save')
          }
        request(url, {data: formData}).then(result => {
          if(result.code === 1){
            message.success('保存成功')
            state.visible = false
            onClose()
            props.handleSearch(data)
          }else{
            message.warning(result.message)
          }
        })
      }
    const onClose = () => {
      formRef.value.clearValidate()
      Object.assign(formData, {
          id: 0,
          //渠道名称
          channelName: '',
          //渠道编码
          channelCode: '',
          //渠道类型
          channelType: '',
          //渠道说明
          channelDesc: '',
          channelPromotionFee: ''
      })
      state.visible = false
    }

    onBeforeMount(()=> {
      getDictionaryData(ChannelTypeList, 'channel_type')
    })
    expose({
      showDrawer
    })
    return {
      ChannelTypeList,
      formRef,
      formData,
      formRules,
      ...toRefs(state),
      onClose,
      onSave,
    }
  },

})
</script>
