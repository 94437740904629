<style scoped>
.djsc {
  width: 128px;
  height: 128px;
}
</style>

<template>
  <a-drawer
    :title="inNew"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="activeKey === '1' ? onClose() : onClose1()"
  >
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="1" tab="协议基础信息">
        <a-form
          :model="formData"
          :rules="formRules"
          layout="vertical"
          ref="formRef"
          autocomplete="off"
        >
          <a-input v-model="formData.id" v-show="false"/>
          <a-row :gutter="10">
            <a-col span="12">
              <a-form-item label="协议编号:" name="agreementCode"
                           :rules="[{ required: true, message: '必填信息' },{ pattern:/^[0-9a-zA-Z]/, message: '录入信息格式不正确，请重新录入' ,trigger: 'change'}, {max: 20, message: '输入长度不能超过20' ,trigger: 'change'}]">
                <a-input v-model:value="formData.agreementCode" :disabled="isDisable" clearable/>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="协议名称:" name="agreementName"
                           :rules="[{ required: true, message: '必填信息' ,trigger: 'change'},{max: 50, message: '输入长度不能超过50' ,trigger: 'change'}]">
                <a-input
                  v-model:value="formData.agreementName"
                  :disabled="isDisable"
                  clearable
                />
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="协议类型:" name="agreementType"
                           :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
                <a-select v-model:value="formData.agreementType" :disabled="isDisable" :options="xieyileixing"
                          clearable>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="供应商名称:" name="supId"
                           :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
                <a-tree-select  :filterTreeNode="filterTreeOption" show-search tree-default-expand-all v-model:value="formData.supId" :disabled="isDisable" :tree-data="listData.gongyingshangmingcheng" :fieldNames="{children:'children', label:'supName', key:'id', value:'id'}">
                </a-tree-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="中介公司:" name="brokerageAgency"
                           :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
                <a-select v-model:value="formData.brokerageAgency" :options="jingjigongsi" :disabled="isDisable" clearable>
                  <!-- <a-select-option v-for="item in listData.jingjigongsi" :key="item.value"></a-select-option> -->
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="协议级别:" name="agreementLevel" :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
                <a-select v-model:value="formData.agreementLevel" :disabled="isDisable" :options="xieyijibie"
                          clearable>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="签署日期:" name="signedDate"
                           :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
                <a-date-picker
                  mode="date"
                  v-model:value="formData.signedDate"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  :disabled="isDisable"
                  clearable
                ></a-date-picker>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="状态:">
                <a-select
                  v-model:value="formData.state"
                  :disabled="isDisable"
                  :options="status1"
                  clearable
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="生效日期:" name="effectiveDate"
                           :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
                <a-date-picker
                  mode="date"
                  :disabled="isDisable"
                  v-model:value="formData.effectiveDate"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  clearable
                ></a-date-picker>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="失效日期:" name="invalidDate"
                           :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
                <a-date-picker
                  mode="date"
                  :disabled="isDisable"
                  v-model:value="formData.invalidDate"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  clearable
                ></a-date-picker>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="中介公司负责人:" name="brokerageAgencyPeople"
                           :rules="[{max: 20, message: '输入长度不能超过20' ,trigger: 'change'}]">
                <a-input v-model:value="formData.brokerageAgencyPeople" :disabled="isDisable"/>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="供应商负责人:" name="supPeople"
                           :rules="[{max: 20, message: '输入长度不能超过20' ,trigger: 'change'}]">
                <a-input v-model:value="formData.supPeople" :disabled="isDisable"/>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="供应商联系电话:" name="supPhone"
                           :rules="[{ pattern:/^(\d{8}|\d{11}|\d{16})$/, message: '只能输入8位电话或11位手机或16位公司号码' ,trigger: 'blur'}]">
                <a-input v-model:value="formData.supPhone" :disabled="isDisable"/>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="供应商联系邮箱:" name="supEmail"
                           :rules="[{max: 64, message: '输入长度不能超过64' ,trigger: 'change'},
                           { pattern:/^[0-9a-zA-Z]+([.-_]*[0-9a-zA-Z]+)*@([0-9a-zA-Z]+[-_]*[0-9a-zA-Z]+.)+[0-9a-zA-Z]{2,6}$/, message: '请输入正确的邮箱' ,trigger: 'blur'}
                           ]">
                <a-input v-model:value="formData.supEmail" :disabled="isDisable"/>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="供应商联系地址:" name="supAddress"
                           :rules="[{max: 50, message: '输入长度不能超过50' ,trigger: 'change'}]">
                <a-input v-model:value="formData.supAddress" :disabled="isDisable"/>
              </a-form-item>
            </a-col>
          </a-row>
          <a-col span="24">
            <a-form-item label="备注:" name="remarks" :rules="[{max: 200, message: '输入长度不能超过200' ,trigger: 'change'}]">
              <a-textarea v-model:value="formData.remarks" :rows="4" :disabled="isDisable"/>
            </a-form-item>
          </a-col>
          <a-col span="8">
            <a-form-item label="上传协议:" name="click">
              <upload-file filetype="pdf" :disabled="isDisable" :key="time" :showImg="showImg" num="1" @fileChange="uploadCosFileChange">1111</upload-file>
            </a-form-item>
          </a-col>
        </a-form>
      </a-tab-pane>
      <a-tab-pane key="2" tab="协议关联险种/产品" v-if="status === 'update'">
        <agreement-insurance ref="closeRef" :status="status" :idd="idd" :code="code"/>
      </a-tab-pane>
    </a-tabs>
    <template #extra>
      <a-space>
        <a-button @click="onClose" v-if="activeKey === '1'">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button html-type="submit" type="primary" @click="onSave()" v-if="!isDisable && activeKey === '1'">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
        <a-button @click="onClose1" v-if="activeKey === '2'">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>
<script>
import {SaveOutlined} from '@ant-design/icons-vue'
import {defineComponent, reactive, toRefs, ref, computed, onBeforeMount} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import {getDictionaryData} from '../../../utils/request_extend'
import agreementInsurance from './agreementInsurance.vue'
import uploadFile  from '../../../components/extended/uploadFileNew'
import {message} from 'ant-design-vue'

export default defineComponent({
  components: {
    SaveOutlined,
    agreementInsurance,
    uploadFile
  },

  props: {
    status: {
      type: String,
      required: true,
      default() {
        return '123'
      }
    },
    idd: {
      type: String,
      required: true,
      default() {
        return '1'
      }
    },
    code: {
      type: String,
      required: true,
      default() {
        return '1'
      }
    }
  },
  emits:['select'],
  setup(props, {expose,emit}) {
    const closeRef = ref()
    const formRef = ref()
    const formData = reactive({
      agreementCode: '',
      agreementLevel: '',
      agreementName: '',
      agreementType: '',
      brokerageAgency: '',
      brokerageAgencyPeople: '',
      createTime: '',
      createUser: '',
      delNumber: '',
      effectiveDate: '',
      id: 0,
      invalidDate: '',
      remarks: '',
      signedDate: '',
      state: '0',
      supAddress: '',
      supEmail: '',
      supId: '',
      supName: '',
      supPeople: '',
      supPhone: '',
      updateTime: '',
      updateUser: '',
      uploadAgreement: ''
    })
    const formRules = {
      realName: [{required: true}],
      phoneNo: [{required: true}],
      email: [{required: true, type: 'email'}],
      // userStatus: [
      //   {
      //     required: true,
      //     type: 'number'
      //   }
      // ],
      startDate: [
        {
          required: true,
          type: 'date'
        }
      ]
    }
    const state = reactive({
      status: props.status,
      visible: false,
      isNew: true,
      time: null,
      activeKey: '1',
      showImg: [],
      queryG: {
        data: {
          cityCode: '',
          comAddress: '',
          comPhoneNumber: '',
          comUrl: '',
          countyCode: '',
          createTime: '',
          createUser: '',
          emailCode: '',
          id: 0,
          logo: '',
          parentId: 0,
          provinceCode: '',
          supCode: '',
          supEngName: '',
          supGrade: 0,
          supInitial: '',
          supIntroduce: '',
          supName: '',
          supShortName: '',
          supStatus: 0,
          supType: 0,
          updateTime: '',
          updateUser: ''
        },
        sorters: [
          {
            field: '',
            order: ''
          }
        ]
      },
      // transferStyle: {width: '50%'},
      // roleTitles: ['未添加授权角色列表', '已添加角色列表'],
      // roleData: []
    })
    const xieyileixing = ref([])
    const xieyijibie = ref([])
    const status1 = ref([])
    const jingjigongsi = ref([])

    onBeforeMount(()=>{
      getDictionaryData(xieyileixing, 'agreementType')
      getDictionaryData(xieyijibie, 'agreementLevel')
      getDictionaryData(status1, 'status')
      getDictionaryData(jingjigongsi, 'companyCode')
    })
    const listData = reactive({
      zhuangtai: [
        {
          label: '有效',
          value: 0
        },
        {
          label: '无效',
          value: 1
        }
      ],
      gongyingshangmingcheng: [

      ],
    })
    const onClose1 = function() {
      console.debug(closeRef.value)
      closeRef.value.onClose()
      state.activeKey = '1'
      state.visible = false
    }
    const onClose = () => {
      if(closeRef.value){
        closeRef.value.onClose()
      }
      formRef.value.clearValidate()
      formData.agreementCode = ''
      formData.agreementLevel = ''
      formData.agreementName = ''
      formData.agreementType = ''
      formData.brokerageAgency = ''
      formData.brokerageAgencyPeople = ''
      formData.createTime = ''
      formData.createUser = ''
      formData.delNumber = ''
      formData.effectiveDate = ''
      formData.id = ''
      formData.invalidDate = ''
      formData.remarks = ''
      formData.signedDate = ''
      formData.state = '0'
      formData.supAddress = ''
      formData.supEmail = ''
      formData.supId = ''
      formData.supName = ''
      formData.supPeople = ''
      formData.supPhone = ''
      formData.updateTime = ''
      formData.updateUser = ''
      formData.uploadAgreement = ''
      state.time = new Date().getTime()
      state.visible = false
      state.activeKey = '1'
      state.status = ''
      state.showImg.splice(0)
    }
    const onSave1 = function() {
      closeRef.value.onSave()
    }
    const filterTreeOption = function(input, option) {
      return option.supName.includes(input)
    }
    const showDrawer = (oldData = {}) => {
      console.debug(oldData)
      state.status = props.status
      if(oldData.uploadAgreement!==''){
        let obj = {}
        obj.url = oldData.uploadAgreement
        state.showImg.push(obj)
        console.debug(state.showImg,'666')
      }
      state.visible = true
      state.isNew = !(typeof oldData.id === 'string' && oldData.id.length > 0)
      console.debug('editNewUser', state.isNew, oldData)
      Object.keys(formData).forEach((key) => {
        formData[key] = oldData[key] || formData[key]
      })
      console.debug(state.showImg)
      if (oldData == 'add') {
        state.showImg.splice(0)
      }
    }
    const uploadCosFileChange=(data)=>{
      formData.uploadAgreement=data.url
      console.debug(formData.uploadAgreement,'6666')
    }
    const isDisable = computed(() => {
      if (props.status == 'select') {
        return true
      } else {
        return false
      }
    })
    const inNew = computed(() => {
      if (props.status === 'select') {
        return '协议查看'
      } else if (props.status === 'add') {
        return '协议增加'
      } else {
        return '协议修改'
      }
    })

    const onFormClean = () => {
      formRef.value.resetFields()
      state.targetKeys = []
      state.visible = false
    }
    const onSelectChange = (selectedRowKeys) => {
      console.debug('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
    }

    const onSave = async () => {
      const values = await formRef.value.validate()
      console.debug(values)
      if (Date.parse(formData.signedDate) > Date.parse(formData.effectiveDate)) {
        message.warning('签署日期不能大于生效日期')
        return
      }
      if (Date.parse(formData.effectiveDate) > Date.parse(formData.invalidDate)) {
        message.warning('生效日期不能大于失效日期')
        return
      }
      // signedDate
      // effectiveDate
      // invalidDate
      console.debug(props.status, '88888')
      let obj = {
        data: formData
      }
      if (props.status === 'update') {
        // if (formData.state !== '0') {
        //   message['warning']('请选择数据')
        // }
        const uri = getRequestURI('productAgreement_update')
        request(uri, obj).then(res => {
          if (res.message == '协议编号重复') {
            message['warning']('协议编号重复，请修改后重新保存')
          }
          if (res.code === 1) {
            message['success'](res.message)
            onClose()
            emit('select')
          } else {
            message['error'](res.message)
          }
          console.debug(res)
        })
      } else {
          const uri = getRequestURI('productAgreement_insert')
          request(uri, obj).then(res => {
            console.debug(res)
            if (res.code === 1) {
              emit('select')
              message['success'](res.message)
              onClose()
            } else {
              message['error'](res.message)
            }
          })
      }
    }
    const handleChange = info => {
      if (info.file.status !== 'uploading') {
        console.debug(info.file, info.fileList)
      }

      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    }
    const init = function() {
      const uri = getRequestURI('supplier_querySupplier')
      request(uri, {data:{}}).then(res => {
        if (res.code == 1) {
            listData.gongyingshangmingcheng = res.result
          }
      })
    }
    init()
    const fileList = ref([])
    expose({
      showDrawer
    })
    return {
      formRef,
      formData,
      formRules,
      listData,
      closeRef,
      init,
      uploadCosFileChange,
      onSelectChange,
      ...toRefs(state),
      onClose,
      isDisable,
      inNew,
      onSave,
      onFormClean,
      fileList,
      onClose1,
      onSave1,
      headers: {
        authorization: 'authorization-text'
      },
      handleChange,
      filterTreeOption,
      xieyileixing,
      xieyijibie,
      status1,
      jingjigongsi
    }
  }
})
</script>
