<style scoped>
</style>

<template>
  <search-form
    :Condition="condition"
    :ColSpan="8"
    @callback="handleSearch"
  ></search-form>
  <div class="btn-bar">
    <a-button type="primary" @click="addTemplatePage" v-if="getRequestURI('t_page_add')">
      <template #icon><FileAddOutlined/></template>新增
    </a-button>
    <a-button danger @click="deleteTemplatePage" v-if="getRequestURI('t_page_delete')">
      <template #icon><DeleteOutlined/></template>删除
    </a-button>
  </div>
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :scroll="{ x: 1500 }"
    :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
    :row-key="(record) => record.id"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
  >
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <a-space>
          <a-button type="primary" @click="modifyTemplatePage(record)" size="small" v-if="getRequestURI('t_page_edit')">
            <template #icon><FormOutlined/></template>修改
          </a-button>
          <a-button type="primary" @click="previewTemplatePage(record)" size="small" v-if="getRequestURI('t_page_preview')">
            <template #icon><EyeOutlined/></template>预览
          </a-button>
          <a-button type="primary" @click="linkProduct(record)" size="small" v-if="getRequestURI('t_page_link')">
            <template #icon><LinkOutlined/></template>关联产品
          </a-button>
        </a-space>
      </template>
    </template>
  </a-table>
  <bee-editor ref="webEditorRef" @callback="reloadList"></bee-editor>
  <a-modal v-model:visible="visible" title="请选择模板类型" @ok="handleOk">
    <label>模板类型：</label>
    <a-select v-model:value="curTemplateType" :options="webTemplateTypeData">
    </a-select>
  </a-modal>
</template>

<script>
import {defineComponent, reactive, computed, ref, toRefs, onBeforeMount} from 'vue'
import SearchForm from '../../components/extended/search_form'
import beeEditor from './editor'
import {requestUsePagination, getRequestURI, request} from '../../utils/request.js'
import {
  FileAddOutlined,
  FormOutlined,
  DeleteOutlined,
  EyeOutlined,
  LinkOutlined
} from '@ant-design/icons-vue'
import {message, Modal} from 'ant-design-vue'
import {getDictionaryData} from '../../utils/request_extend'

export default defineComponent({
  components: {
    FileAddOutlined,
    FormOutlined,
    DeleteOutlined,
    EyeOutlined,
    LinkOutlined,
    SearchForm,
    beeEditor,
  },
  setup() {
    const webEditorRef = ref()
    const webTemplateTypeData = ref([])
    const state = reactive({
      condition: {
        template_type: {
          label: '模板类型',
          type: 'select',
          default: '1',
          urlKey: 'queryDictionaryType',
          queryParam: {type: 'WebTemplateType'}
        },
        status: {
          label: '模板状态',
          type: 'select',
          default: '0',
          urlKey: 'queryDictionaryType',
          queryParam: {type: 'WebTemplateStatus'}
        },
        supplier_id: {
          label: '供应商',
          type: 'tree-select',
          urlKey:'querySupplier',
          value:'id'
        },
        template_name: {
          label: '模板名称',
          placeholder: '请输入模板名称关键字'
        },
        template_group_id: {
          label: '模板分组',
          type: 'select',
          data: []
        },
        product_name: {
          label: '产品名称',
          placeholder: '请输入关联产品关键字'
        }
      },
      lastFilter: {},
      columns: [
        {
          key: 'index',
          title: '序号',
          fixed: 'left',
          width: 60,
          align: 'left',
        },
        {
          title: '模板名称',
          width: 200,
          fixed: 'left',
          key: 'template_name',
          dataIndex: 'template_name',
        },
        {
          title: '模板状态',
          width: 120,
          align: 'center',
          key: 'status',
          dataIndex: 'status',
        },
        {
          title: '供应商名称',
          width: 140,
          align: 'center',
          key: 'supplier_name',
          dataIndex: 'supplier_name',
        },
        {
          title: '模板类型',
          width: 100,
          align: 'center',
          key: 'template_type_name',
          dataIndex: 'template_type_name',
        },
        {
          title: '启用版本',
          width: 120,
          align: 'center',
          key: 'version',
          dataIndex: 'version',
        },
        {
          title: '更新日期',
          width: 120,
          align: 'center',
          key: 'modify_time',
          dataIndex: 'modify_time',
        },
        {
          title: '操作',
          key: 'operation',
          fixed: 'right',
          width: 240,
        },
      ],
      selectedRowKeys: [],
      searchNow:{},
      curTemplateType: '1',
      visible: false
    })
    onBeforeMount(()=> {
      getDictionaryData(webTemplateTypeData, 'WebTemplateType')
    })
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(
      getRequestURI('t_page_list'),
      {
        pagination: {
          current: 1,
          pageSize: 10
        }
      },{
        mock: false,
        pagination: {
          total: 10
        },
        result: [{
          id: 'qqq111',
          template_name: '测试模板111',
          template_type: '1',
          template_type_name: '产品页模板'
        }]
      })
    const onSelectChange = (selectedRowKeys) => {
      console.debug('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
      pageSizeOptions: [10, 20, 50],
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.debug('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.lastFilter,
      })
    }
    const handleSearch = function (filters) {
      state.lastFilter = filters
      run({
        pagination: {
          current: current.value,
          pageSize: pageSize.value
        },
        data: filters,
      })
      console.debug('dataSource', dataSource, total.value, pageSize.value)
    }
    const reloadList = () => {
      run({
        pagination: {
          current: current.value,
          pageSize: pageSize.value
        },
        data: state.lastFilter,
      })
    }
    const addTemplatePage = function () {
      state.visible = true
    }
    const modifyTemplatePage = function (oldData) {
      console.debug('oldData', oldData)
      webEditorRef.value.showDrawer(oldData.template_type, oldData)
    }
    const deleteTemplatePage = function () {
      Modal.confirm({
        title: '删除模板',
        content: '是否删除已选择模板？',
        onOk: () => {
          request(getRequestURI('t_page_delete'), {data:{ids:state.selectedRowKeys}}).then(res => {
            if (res.code === 1) {
              reloadList()
              message['success']('删除成功')
            } else {
              message['error'](res.message)
            }
          })
        },
        onCancel: () => {
          Modal.destroyAll()
        }
      })
    }
    const previewTemplatePage = function () {

    }
    const linkProduct = function () {

    }
    const handleOk =  function () {
      state.visible = false
      webEditorRef.value.showDrawer(state.curTemplateType)
    }
    return {
      ...toRefs(state),
      webTemplateTypeData,
      handleSearch,
      onSelectChange,
      handleTableChange,
      addTemplatePage,
      modifyTemplatePage,
      deleteTemplatePage,
      previewTemplatePage,
      linkProduct,
      webEditorRef,
      pagination,
      loading,
      dataSource,
      getRequestURI,
      reloadList,
      handleOk
    }
  },
})
</script>
