<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
  ></search-form>
  <div class="btn-bar">
    <a-button type="primary" @click="addChannel(1)">
      <template #icon>
        <FileAddOutlined />
      </template>
      新增
    </a-button>
  </div>
  <a-table
    :columns="channelColumns"
    :data-source="dataSource ? dataSource['result'] : []"
    :row-key="(record) => record.id"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
  >
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <div>
          <a style="color: orange" @click="updateChannel(record, 2)">编辑</a
          >&nbsp;&nbsp;
          <a style="color: orange" @click="delChannel(record)">删除</a
          >&nbsp;&nbsp;
        </div>
      </template>
    </template>
  </a-table>
  <increaseChannel
    :handleSearch="handleSearch"
    ref="userDrawerRef"
    :searchfFilters="searchfFilters"
  ></increaseChannel>
</template>

<script>
import increaseChannel from './increaseChannel.vue'
import SearchForm from '../../../components/extended/search_form'
import { computed, defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import { getRequestURI, request, requestUsePagination } from '../../../utils/request'
// import {getExtendUri} from '../../../utils/request_extend'
import { getDictionaryValue } from '../../../utils/request_extend'
import { message, Modal } from 'ant-design-vue'


export default defineComponent({
  components: {
    SearchForm, increaseChannel
  },
  setup () {
    const userDrawerRef = ref()
    const state = reactive({
      newChannel: {},
      condition: {
        channelName: {
          label: '渠道名称',
          // placeholder: '请输入'
        },
        channelCode: {
          label: '渠道编码',
          // placeholder: '请输入'
        },
        channelType: {
          label: '渠道类型',
          type: 'select',
          default: '1',
          placeholder: '请选择',
          urlKey: 'queryDictionaryType',
          queryParam: { type: 'channel_type' }
          // data: []
        }
      },
      channelColumns: [  // 渠道
        {
          title: '序号',
          key: 'index',
          align: 'center',
          width: 60,
        },
        {
          title: '渠道名称',
          width: 100,
          align: 'center',
          key: 'channelName',
          dataIndex: 'channelName',
        },
        {
          title: '渠道编码',
          width: 100,
          align: 'center',
          key: 'channelCode',
          dataIndex: 'channelCode',
        },
        {
          title: '渠道类型',
          width: 100,
          align: 'center',
          key: 'channelType',
          dataIndex: 'channelType',
          customRender: (text) => getDictionaryValue('channel_type', text.value)

        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 80,
        },
      ],
    })
    // 存储搜索组件传过来的数据
    const searchfFilters = ref({})
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('channel_queryListForPage'))
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    const handleSearch = function (filters) {
      if (filters) {
        searchfFilters.value = filters
      }
      console.debug('handleSearch', filters)
      state.newChannel = filters
      run({
        pagination: {
          current: 1,
          pageSize: 10
        },
        data: filters,
      })
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.debug('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.newChannel,
      })
    }
    const addChannel = function (num) {
      userDrawerRef.value.showDrawer(num)
    }
    const updateChannel = function (oldData, num) {
      console.debug(oldData)
      userDrawerRef.value.showDrawer(oldData, num)
    }
    const delChannel = record => {
      let data = {}
      Modal.confirm({
        title: '删除提示',
        content: '确定删除此数据吗?',
        onOk: () => {
          request(getRequestURI('channel_delete'), { data: { ids: [record.id] } }).then(res => {
            if (res.code === 1) {
              message.success('删除成功')
              handleSearch(data)
            } else {
              message.warning(res.message)
            }
          })
        },
        onCancel: () => {
          Modal.destroyAll()   // 清除弹出框
        }
      })
    }
    // 渠道类型下拉选
    // const getChannelType = () =>{
    //   request(getExtendUri('dictionary_query_byType'), {data: {types: ['channel_type']}}).then(res => {

    // state.condition.channelType.data = res.result.channel_type
    //     console.debug(222, res)
    //   })
    // }
    onMounted(() => {
      // getChannelType()
    })
    return {
      ...toRefs(state),
      run,
      loading,
      current,
      pageSize,
      total,
      dataSource,
      handleSearch,
      pagination,
      handleTableChange,
      addChannel,
      updateChannel,
      delChannel,
      userDrawerRef,
      searchfFilters
    }
  }
})
</script>

<style scoped>
</style>
