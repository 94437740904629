<style scoped>
</style>

<template>
  <a-drawer
    title="外部系统供应商同步"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form :model="formData" :rules="formRules" layout="vertical" ref="formRef" autocomplete="off" @finish="onFinish">
      <a-input v-model="formData.id" v-show="false"/>
      <a-row :gutter="10">
        <a-col span="12">
          <a-form-item label="外部系统" name="dataSources" required>
            <a-select v-model:value="formData.dataSources" placeholder="请选择">
              <a-select-option :value="1">启用</a-select-option>
              <a-select-option :value="2">禁用</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="供应商名称" name="supplierName">
            <a-input
              v-model:value="formData.supplierName"
              placeholder="请输入供应商名称"
              :disabled="!isNew"
            />
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="供应商级别" name="supplierLevel">
            <a-select v-model:value="formData.supplierLevel" placeholder="请选择状态">
              <a-select-option :value="1">总公司</a-select-option>
              <a-select-option :value="2">分公司</a-select-option>
              <a-select-option :value="2">中心支公司</a-select-option>
              <a-select-option :value="2">支公司</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="供应商类别" name="SupplierCategory">
            <a-select v-model:value="formData.SupplierCategory" placeholder="请选择状态">
              <a-select-option :value="1">人身险</a-select-option>
              <a-select-option :value="2">财产险</a-select-option>
              <a-select-option :value="3">再保险</a-select-option>
              <a-select-option :value="4">其他</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onFormClean">
          <template #icon>
            <ReloadOutlined/>
          </template>
          重置
        </a-button>
        <a-button type="primary" html-type="submit" @click="handleSearch" style="background: #fe5e3a;border: #fe5e3a">
          <template #icon>
            <SearchOutlined/>
          </template>
          查询
        </a-button>
      </a-space>
    </template>
    <a-table
      :columns="columns"
      :data-source="dataSource ? dataSource['result'] : []"
      :scroll="{ x: 500 }"
      :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
      :row-key="(record) => record.id"
      :pagination="pagination"
      @change="handleTableChange"
      :loading="loading"
    >
      <template #bodyCell="{ column, index }">
        <template v-if="column.key === 'index'">
          {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
        </template>
      </template>
    </a-table>
    <a-form :model="formData" :rules="formRules" layout="vertical" ref="formRef" autocomplete="off">
      <a-col span="12">
        <a-form-item label="数据同步方式" name="synchronizationMode" required>
          <a-select v-model:value="formData.synchronizationMode" placeholder="请选择">
            <a-select-option :value="1">推送</a-select-option>
            <a-select-option :value="2">获取</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
    </a-form>
    <div style="text-align: center">
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary" @click="onSave" style="background: #fe5e3a;border: #fe5e3a">
          同步
        </a-button>
      </a-space>
    </div>
  </a-drawer>
</template>
<script>
import {ReloadOutlined, SearchOutlined} from '@ant-design/icons-vue'
import {defineComponent, reactive, toRefs, ref, computed} from 'vue'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request'
export default defineComponent({
  components: {
    ReloadOutlined, SearchOutlined
  },

  setup(props, {expose}, context) {
    const formRef = ref()
    const formData = reactive({
      id: '',
      dataSources: '',//数据来源
      supplierName: '',//供应商名称
      supplierLevel: '',//供应商级别
      SupplierCategory: '',//供应商类别
    })
    const formRules = {
      dataSources: [
        {required: true, message: '请选择数据来源', trigger: 'change'}
      ],
      synchronizationMode: [
        {required: true, message: '请选择数据同步方式', trigger: 'change'}
      ]
    }
    const state = reactive({
      visible: false,
      isNew: true,
      columns: [
        {
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left',
        },
        {
          title: '供应商名称',
          width: 140,
          fixed: 'left',
          key: 'supplierName',
          dataIndex: 'supplierName',
        },
        {
          title: '供应商编码',
          width: 140,
          fixed: 'left',

        },
        {
          title: '供应商级别',
          width: 140,
          fixed: 'left',
          key: 'supplierLevel',
          dataIndex: 'supplierLevel',
        },
        {
          title: '供应商类别',
          width: 140,
          fixed: 'left',
          key: 'SupplierCategory',
          dataIndex: 'SupplierCategory',
        },
      ],
      selectedRowKeys: [],
    })
    const onSelectChange = (selectedRowKeys) => {
      console.debug('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
    }

    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(
      getRequestURI('user_list'),
      {},
      {
        mock: true,
        code: 1,
        message: 'success',
        pagination: {
          total: 200,
          current: 1,
          pageSize: 5,
        },
        result: [
          {
            id: '1',
            dataSources: '011',//数据来源
            supplierName: '012',//供应商名称
            supplierLevel: '013',//供应商级别
            SupplierCategory: '014',//供应商类别

          },
          {
            id: '2',
            dataSources: '021',//数据来源
            supplierName: '022',//供应商名称
            supplierLevel: '023',//供应商级别
            SupplierCategory: '024',//供应商类别

          }
        ],
      }
    )
    const showDrawerTwo = (oldData = {}) => {
      state.visible = true
      state.isNew = !(typeof oldData.id === 'string' && oldData.id.length > 0)
      console.debug('editNewUser', state.isNew, oldData)
      Object.keys(formData).forEach(key => {
        formData[key] = oldData[key] || formData[key]
      })
    }
    const onFinish = (values) => {
      // console.debug('search-form-state: ', formState)
      context.emit('callback', values)
    }
    const handleSearch = function (filters) {
      console.debug('handleSearch', filters)
      run({
        'pagination.current': 1,
        'pagination.pageSize': pageSize.value,
        ...filters,
      })
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
      pageSizeOptions: [10, 20, 50],
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.debug('handleTableChange', pag, filters, sorter)
      run({
        'pagination.current': pag?.current,
        'pagination.pageSize': pag.pageSize,
        sorter,
        ...filters,
      })
    }

    //关闭
    const onClose = () => {
      state.visible = false
    }
    //重置
    const onFormClean = () => {
      formRef.value.resetFields()
    }
    //提交
    const onSave = async () => {
      console.debug('onSave', state.roleData)
      try {
        const values = await formRef.value.validate()
        if (state.isNew) {
          request(getRequestURI('user_post'), values, true, 'post').then(result => {
            console.debug('post success:', values, result)
          })
        } else {
          request(getRequestURI('user_put'), values, true, 'put').then(result => {
            console.debug('put success:', values, result)
          })
        }
        console.debug('Success:', values)
        state.visible = false
      } catch (errorInfo) {
        console.debug('Failed:', errorInfo)
      }
    }
    expose({
      showDrawerTwo
    })
    return {
      formRef,
      formData,
      formRules,
      ...toRefs(state),
      onClose,
      onSave,
      onFormClean,
      getRequestURI,
      onSelectChange,
      loading,
      dataSource,
      handleSearch,
      handleTableChange,
      pagination,
      onFinish,
      showDrawerTwo

    }
  },

})
</script>
