<style scoped></style>

<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
  ></search-form>
  <div class="btn-bar">
    <a-button type="primary" @click="addUser('add')">
      <template #icon>
        <FileAddOutlined />
      </template>
      新增
    </a-button>
    <a-button danger @click="showModal">
      <template #icon>
        <DeleteOutlined />
      </template>
      删除
    </a-button>
    <a-button danger @click="dowload">
      <template #icon>
        <ToTopOutlined />
      </template>
      导出
    </a-button>
  </div>
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :scroll="{ x: 1000 }"
    :pagination="pagination"
    :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
    :row-key="(record) => record.id"
    @change="handleTableChange"
    :loading="loading"
  >
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <div>
          <a
            style="color: orange; margin-right: 20px"
            @click="checkUser(record, 'select')"
            >查看</a
          >
          <a
            style="color: orange; margin-right: 20px"
            @click="modifyUser(record, 'update')"
            >编辑</a
          >
        </div>
      </template>
    </template>
  </a-table>
  <edit-user
    ref="userDrawerRef"
    @select="handleSearch(searchfFilters)"
    :status="status"
    :idd="idd"
    :code="code"
  ></edit-user>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import SearchForm from '../../../components/extended/search_form.vue'
import editUser from './editAgreement.vue'
import { message, Modal } from 'ant-design-vue'
import { request, getRequestURI, requestUsePagination } from '../../../utils/request.js'
import {
  FileAddOutlined,
  DeleteOutlined,
  FormOutlined,
  ToTopOutlined,
} from '@ant-design/icons-vue'
import { getDictionaryValue } from '../../../utils/request_extend'

export default defineComponent({
  components: {
    SearchForm,
    FileAddOutlined,
    DeleteOutlined,
    // eslint-disable-next-line vue/no-unused-components
    FormOutlined,
    ToTopOutlined,
    editUser,
  },
  setup () {
    const userDrawerRef = ref()
    const state = reactive({
      condition: {
        supId: {
          label: '供应商名称',
          type: 'tree-select',
          urlKey: 'querySupplier',
          // urlKey: 'supplierQuerySupLevelSt',
          value: 'id',
          default: '',
          data: []
        },
        agreementName: '协议名称',
        agreementCode: '协议编号',
        agreementType: {
          label: '协议类型',
          type: 'select',
          urlKey: 'queryDictionaryType',
          queryParam: { type: 'agreementType' }
        },
        effectiveDate: {
          label: '生效日期开始',
          type: 'date-picker',
        },
        effectiveEnd: {
          label: '生效日期结束',
          type: 'date-picker',
        },
        invalidDate: {
          label: '失效日期开始',
          type: 'date-picker',
        },
        failureEnd: {
          label: '失效日期结束',
          type: 'date-picker',
        },
      },
      idd: '',
      id: '',
      code: '',
      status: '',
      query: {
        data: {
          agreementCode: '',
          agreementLevel: 0,
          agreementName: '',
          agreementType: 0,
          brokerageAgency: '',
          brokerageAgencyPeople: '',
          createTime: '',
          createUser: '',
          delNumber: 0,
          effectiveDate: '',
          id: 0,
          invalidDate: '',
          remarks: '',
          signedDate: '',
          state: 0,
          supAddress: '',
          supEmail: '',
          supId: 0,
          supName: '',
          supPeople: '',
          supPhone: '',
          updateTime: '',
          updateUser: '',
          uploadAgreement: ''
        },
        pagination: {
          current: 1,
          pageSize: 10,
          total: 0
        },
        sorters: [
          {
            field: '',
            order: ''
          }
        ]
      },
      loading: false,
      result: [],
      // 删除data
      querydel: [],
      lastFilter: {},
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left',
        },
        {
          title: '协议编号',
          width: 100,
          fixed: 'left',
          align: 'center',
          key: 'agreementCode',
          dataIndex: 'agreementCode',
        },
        {
          title: '协议名称',
          width: 100,
          align: 'center',
          key: 'agreementName',
          dataIndex: 'agreementName',
        },
        {
          title: '供应商名称',
          width: 100,
          align: 'center',
          key: 'supName',
          dataIndex: 'supName',
        },
        {
          title: '中介公司名称',
          width: 100,
          align: 'center',
          key: 'brokerageAgency',
          dataIndex: 'brokerageAgency',
          customRender: (text) => getDictionaryValue('companyCode', text.value)
        },
        {
          title: '协议类型',
          width: 100,
          align: 'center',
          key: 'agreementTypeName',
          dataIndex: 'agreementTypeName',
          // customRender: (text) => getDictionaryValue('agreementType', text.value)
        },
        {
          title: '生效日期',
          width: 100,
          align: 'center',
          key: 'effectiveDate',
          dataIndex: 'effectiveDate',
        },
        {
          title: '失效日期',
          width: 120,
          align: 'center',
          key: 'invalidDate',
          dataIndex: 'invalidDate',
        },
        {
          title: '状态',
          width: 120,
          align: 'center',
          key: 'stateName',
          dataIndex: 'stateName',
        },
        {
          title: '操作',
          key: 'operation',
          fixed: 'right',
          width: 120,
        },
      ],
      delId: '',
      tableList: [],
      selectedRowKeys: [],
    })
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('productAgreement_query'))
    // 存储搜索组件传过来的数据
    const searchfFilters = ref({})
    const onSelectChange = (selectedRowKeys, selectedRow) => {
      state.querydel.splice(0)
      console.debug('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
      state.querydel = selectedRow
      console.debug('selectedRowKeys changed: ', selectedRow)
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
      // pageSizeOptions: [10, 20, 50],
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.debug('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.lastFilter,
      })
    }
    //页面加载调用数据
    onMounted(() => {
      handleSearch({})
    })
    const handleSearch = function (filters) {
      if (filters) {
        searchfFilters.value = filters
      }
      if (filters.effectiveDate && filters.effectiveEnd) {
        if (!(filters.effectiveDate < filters.effectiveEnd)) {
          return message.warning('生效日期结束要大于生效日期开始')
        }
      }
      if (filters.failureEnd && filters.invalidDate) {
        if (!(filters.invalidDate < filters.failureEnd)) {
          return message.warning('失效日期结束要大于失效日期开始')
        }
      }
      state.lastFilter = filters
      run(
        {
          data: filters,
          pagination: {
            current: current.value,
            pageSize: pageSize.value
          },
        }
      )
    }
    const select = function () {
      run(
        {
          data: state.lastFilter,
          pagination: {
            current: current.value,
            pageSize: pageSize.value
          },
        }
      )
    }
    const addUser = function (a) {
      state.status = a
      userDrawerRef.value.showDrawer(state.status)
    }
    const dowload = function () {
      if (!dataSource.value || !dataSource.value['result']) {
        message['warning']('暂无数据')
      } else {
        const uri = getRequestURI('productAgreement_export')
        request(uri, {
          data: {
            id: state.selectedRowKeys,
            productAgreementForm: state.lastFilter
          }
        }).then(res => {
          if (res.code == 1) {
            window.location = res.result
            state.selectedRowKeys = []
          }
        })
      }
    }
    const modifyUser = function (oldData, states) {
      state.status = states
      state.idd = oldData.agreementCode
      state.code = oldData.id + ''
      console.debug(oldData)
      const uri = getRequestURI('productAgreement_selectListForPageOne')
      request(uri, { data: oldData }).then(res => {
        res.result[0].state = res.result[0].state + ''
        res.result[0].agreementType = res.result[0].agreementType + ''
        res.result[0].agreementLevel = res.result[0].agreementLevel + ''
        userDrawerRef.value.showDrawer(res.result[0])
      })
    }
    const checkUser = function (oldData, a) {
      console.debug(oldData)
      oldData.state = oldData.state + ''
      oldData.agreementType = oldData.agreementType + ''
      oldData.agreementLevel = oldData.agreementLevel + ''
      state.status = a
      state.idd = oldData.agreementCode
      state.code = oldData.id + ''
      userDrawerRef.value.showDrawer(oldData)
    }

    const restUserPassword = function () {
    }
    const visible = ref(false)

    const showModal = (record) => {
      // state.delId = record
      console.debug(record)
      if (state.querydel.length == 0) {
        message['warning']('请选择数据')
      } else {
        Modal.confirm({
          title: '确认删除吗?',
          async onOk () {
            // let keys = state.selectedRowKeys
            // if (keys.length === 0) {
            //   message.warning('请选择数据')
            //   return
            // }
            // visible.value = true
            const uri = getRequestURI('productAgreement_delete')
            console.debug(state.querydel)
            request(uri, { data: state.querydel }).then(res => {
              console.debug(res)
              if (res.code == 1) {
                console.debug(res)
                message['success'](res.message)
                select()
              } else {
                message['error'](res.message)
              }
              state.querydel.splice(0)
            })
          },
          onCancel () {
            // state.querydel.splice(0)
          }
        })
      }
      visible.value = true
    }
    return {
      ...toRefs(state),
      handleSearch,
      dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
      pagination,
      select,
      onSelectChange,
      dowload,
      handleTableChange,
      addUser,
      modifyUser,
      restUserPassword,
      userDrawerRef,
      checkUser,
      visible,
      showModal,
      searchfFilters
    }
  },
})
</script>
