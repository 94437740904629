<style scoped>
</style>

<template>
  <a-drawer
    title="环境供应商同步"
    :width="'30%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form :model="formData" layout="vertical" ref="formRef" autocomplete="off">
      <a-input v-model="formData.id" v-show="false"/>
      <a-row :gutter="10">
        <a-col span="12">
          <a-form-item label="系统名称" name="systemCode"  :rules="[{ required: true, message: '请选择系统名称' ,trigger: 'change'}]">
            <a-select v-model:value="formData.systemCode" placeholder="请选择"  @change="changeName" clearable  allow-clear >
              <a-select-option  v-for="item in nameList" :key="item.systemCode" :value="item.systemCode" clearable>{{item.systemName}}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item  label="&nbsp;">
            <a-button @click="onFormClean">
              <template #icon>
                <ReloadOutlined />
              </template>
              重置
            </a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button danger @click="onSave" >
          <template #icon>
            <RetweetOutlined/>
          </template>
          同步
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>
<script>

import {defineComponent, reactive, toRefs, ref} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import {message} from 'ant-design-vue'
export default defineComponent({
  components: {

  },
  setup(props, {expose}, context) {
    const formRef = ref()
    const formData = reactive({
      id: '',
      dataList: {
        systemCode: ''
      },//数据来源
    })


    const state = reactive({
      nameList:[],//系统名称
      dataSourceList:[],
      visible: false,
      isNew: true,
      selectedRowKeys: [],
    })

    //获取系统名称数据
    const systemName =()=>{
      let uri = getRequestURI('env_sync_query')
      request(uri, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }, true, 'get').then(res=>{
        state.nameList=res.result
      })
    }
    const changeName =(val)=>{
      formData.dataList.systemCode=val
    }

    const showDrawerOne = (oldData = {}) => {
      state.visible = true
      state.isNew = !(typeof oldData.id === 'string' && oldData.id.length > 0)
      console.debug('editNewUser', state.isNew, oldData)
      Object.keys(formData).forEach(key => {
        formData[key] = oldData[key] || formData[key]
      })

      systemName()
    }
    const onFinish = (values) => {
      // console.debug('search-form-state: ', formState)
      context.emit('callback', values)
    }

    //关闭
    const onClose = () => {
      state.visible = false
      formData.systemCode=''
    }
    //重置
    const onFormClean = () => {
      formRef.value.resetFields()
      formData.systemCode=''
    }
    //同步
    const onSave = async () => {
      await formRef.value.validateFields()
      let obj = {
        data:formData.dataList
      }
      let uri = getRequestURI('sync_supplierInfo')
      request(uri, obj).then(data => {
        if (data.code == 1) {
          message['success']('同步成功')
          onClose()
        }else{
          message.warning(data.message)
        }
      })
    }
    expose({
      showDrawerOne
    })
    return {
      formRef,
      formData,
      ...toRefs(state),
      onClose,
      onSave,
      onFormClean,
      getRequestURI,
      onFinish,
      showDrawerOne,
      changeName,
      systemName
    }
  },

})
</script>
