<style scoped>
.editor_block_tool {
  background: #54585a;
  height: 48px;
  position: fixed;
  z-index: 90000;
  width: 560px;
  color: #FFFFFF;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #FFFFFF;
  box-shadow: 0 0 15px #666666;
}
.dragging {
  cursor: move;
}
:deep(.ant-btn-text) {
  color: #FFFFFF;
}
</style>

<template>
  <div class="editor_block_tool editor_tool" ref="dragLayoutRef">
    <a-space>
      <drag-outlined class="dragging" @mousedown="startDrag"/>
      <a-dropdown-button size="small" type="text" @click="handleMenuClick">
        <copy-outlined/>
        复制
        <template #overlay>
          <a-menu theme="dark" @click="handleMenuClick">
            <a-menu-item key="1">
              <scissor-outlined/>
              剪切
            </a-menu-item>
            <a-menu-item key="2">
              <snippets-outlined/>
              粘贴
            </a-menu-item>
            <a-menu-item key="3">
              <delete-outlined/>
              删除
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown-button>
      <a-dropdown>
        <template #overlay>
          <a-menu @click="handleMenuClick">
            <a-menu-item key="1">
              <UserOutlined />
              1st menu item
            </a-menu-item>
            <a-menu-item key="2">
              <UserOutlined />
              2nd menu item
            </a-menu-item>
            <a-menu-item key="3">
              <UserOutlined />
              3rd item
            </a-menu-item>
          </a-menu>
        </template>
        <a-button size="small" type="text">
          样式
          <DownOutlined />
        </a-button>
      </a-dropdown>
      <a-dropdown>
        <template #overlay>
          <a-menu @click="handleMenuClick">
            <a-menu-item key="1">
              <UserOutlined />
              1st menu item
            </a-menu-item>
            <a-menu-item key="2">
              <UserOutlined />
              2nd menu item
            </a-menu-item>
            <a-menu-item key="3">
              <UserOutlined />
              3rd item
            </a-menu-item>
          </a-menu>
        </template>
        <a-button size="small" type="text">
          位置
          <DownOutlined />
        </a-button>
      </a-dropdown>
      <a-button size="small" type="text" @click="saveAsBlock">
        <delivered-procedure-outlined />另存为...
      </a-button>
    </a-space>
  </div>
</template>

<script>
import {defineComponent, reactive, ref, toRefs} from 'vue'

export default defineComponent({
  setup(props, context) {
    const state = reactive({
      editContextId: -1,
      currentTarget: null
    })
    const handleMenuClick = (key) => {
      console.log('handleMenuClick:' + key)
    }
    const updateContextId = (id, target, t) => {
      state.editContextId = id
      dragState.isDrag = false
      state.currentTarget = target
      dragLayoutRef.value.style.top = t
    }
    // --- drag ---
    const dragLayoutRef = ref()
    const dragState = reactive({
      isDrag: false,
      mouseX: 0,
      mouseY: 0,
      originalX: 0,
      originalY: 0
    })
    const startDrag = (event) => {
      if (event.button === 0) {
        dragState.mouseX = event.clientX
        dragState.mouseY = event.clientY
        dragState.originalX = dragLayoutRef.value.offsetLeft
        dragState.originalY = dragLayoutRef.value.offsetTop
        dragLayoutRef.value.style.zIndex = 91000
        dragState.isDrag = true
        document.addEventListener('mouseup', stopDrag)
        document.addEventListener('mousemove', drag)
      }
    }

    const drag = (event) => {
      if (event.button === 0 && dragState.isDrag) {
        event.stopPropagation()
        dragLayoutRef.value.style.left = (dragState.originalX - (dragState.mouseX - event.clientX)) + 'px'
        dragLayoutRef.value.style.top = (dragState.originalY - (dragState.mouseY - event.clientY)) + 'px'
      }
    }
    const stopDrag = () => {
      dragState.isDrag = false
      dragLayoutRef.value.style.zIndex = 90000
      document.removeEventListener('mouseup', stopDrag)
      document.removeEventListener('mousemove', drag)
    }
    // --- drag end ---
    const saveAsBlock = () => {
      if (state.editContextId !== -1) {
        context.emit('saveAsBlockHandle', state.editContextId)
      }
    }

    context.expose({
      updateContextId
    })
    return {
      handleMenuClick,
      ...toRefs(dragState),
      ...toRefs(state),
      startDrag,
      drag,
      stopDrag,
      dragLayoutRef,
      saveAsBlock
    }
  }
})
</script>
