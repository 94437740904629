<template>
  <div>
    <search-form
      :Condition="condition"
      :ColSpan="12"
      @callback="handleSearch"
      ref="searchRef"
    ></search-form>
    <div class="btn-bar">
      <a-button danger @click="handelDelete">
        <template #icon>
          <DeleteOutlined />
        </template>
        批量删除
      </a-button>
      <a-button type="primary" @click="handelSave('add')">
        <template #icon>
          <FileAddOutlined />
        </template>
        新增
      </a-button>
    </div>
    <a-table
      :columns="channelColumns"
      :data-source="dataSource ? dataSource['result'] : []"
      :row-key="(record) => record.id"
      :scroll="{ x: 1300 }"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :pagination="pagination"
      @change="handleTableChange"
      :loading="loading"
    >
      <template #bodyCell="{ column, index, record }">
        <template v-if="column.key === 'index'">
          {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
        </template>
        <template v-if="column.key === 'operation'">
          <div>
            <a style="color: orange" @click="handelSave('see', record)">查看</a
            >&nbsp;&nbsp;
            <a style="color: orange" @click="handelSave('update', record)"
              >编辑</a
            >&nbsp;&nbsp;
            <a style="color: orange" @click="handelDelete(record)">删除</a
            >&nbsp;&nbsp;
          </div>
        </template>
      </template>
    </a-table>
    <ProtocolAddAndUpdate
      ref="protocolRef"
      @handleSearch="handleSearch(newChannel)"
      :channelNameList="condition.channelId.data"
    />
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, toRefs } from 'vue'
import SearchForm from '../../../components/extended/search_form'
import { getRequestURI, requestUsePagination, request } from '../../../utils/request'
import { message, Modal } from 'ant-design-vue'
import ProtocolAddAndUpdate from './ProtocolAddAndUpdate'
import { getDictionaryValue } from '../../../utils/request_extend'
export default {
  components: {
    SearchForm,
    ProtocolAddAndUpdate
  },
  setup () {
    const state = reactive({
      selectedRowKeys: [],
      newChannel: {},
      condition: {
        agreementName: {
          label: '协议名称',
          placeholder: '请输入'
        },
        agreementCode: {
          label: '协议编号',
          placeholder: '请输入'
        },
        agreementType: {
          label: '协议类型',
          type: 'select',
          urlKey: 'queryDictionaryType',
          queryParam: { type: 'agreementType' }
        },
        channelId: {
          label: '渠道名称',
          type: 'select',
          placeholder: '请选择',
          data: []
        },
        effectiveDate: {
          label: '生效日期开始',
          type: 'date-picker',
        },
        // invalidDate: {
        //   label: '失效日期开始',
        //   type: 'date-picker',
        // },
        effectiveEnd: {
          label: '生效日期结束',
          type: 'date-picker',
        },
        // invalidEnd: {
        //   label: '失效日期结束',
        //   type: 'date-picker',
        // },
      },
      channelColumns: [  // 渠道
        {
          title: '序号',
          key: 'index',
          align: 'center',
          fixed: 'left',
          width: 60,

        },
        {
          title: '协议名称',
          width: 200,
          fixed: 'left',
          align: 'center',
          key: 'agreementName',
          dataIndex: 'agreementName',
        },
        {
          title: '协议编码',
          width: 100,
          align: 'center',
          key: 'agreementCode',
          dataIndex: 'agreementCode',
        },
        {
          title: '协议类型',
          width: 100,
          align: 'center',
          key: 'agreementType',
          dataIndex: 'agreementType',
          customRender: (text) => getDictionaryValue('agreementType', text.value + '')
        },
        {
          title: '渠道名称',
          width: 100,
          align: 'center',
          key: 'channelName',
          dataIndex: 'channelName',
        },
        {
          title: '生效日期',
          width: 150,
          align: 'center',
          key: 'effectiveDate',
          dataIndex: 'effectiveDate',
        },
        {
          title: '失效日期',
          width: 150,
          align: 'center',
          key: 'invalidDate',
          dataIndex: 'invalidDate',
        },
        {
          title: '状态',
          width: 100,
          align: 'center',
          key: 'state',
          dataIndex: 'state',
          customRender: (text) => getDictionaryValue('status', text.value)
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          fixed: 'right',
          width: 200,
        },
      ],
    })


    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('productChannelAgreement_selectListForPage'))
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    const handleSearch = function (filters) {
      if (Date.parse(filters.effectiveDate) > Date.parse(filters.effectiveEnd)) {
        message.warning('生效开始日期不能大于或等于生效结束日期')
        return
      }
      // if (Date.parse(filters.invalidDate) > Date.parse(filters.invalidEnd)) {
      //   message.warning('失效开始日期不能大于失效结束日期')
      //   return
      // }
      state.newChannel = filters
      run({
        pagination: {
          current: 1,
          pageSize: 10
        },
        data: filters,
      })
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value
    }))
    const handleTableChange = (pag, filters, sorter) => {
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.newChannel,
      })
    }

    const searchRef = ref()
    // 更加渠道类型 返回对应的数据
    const getChannelTypeDetail = (value) => {
      if (!value) return
      for (let i = 0; i < searchRef.value.State.treeData['channelType'].length; i++) {
        if (value == searchRef.value.State.treeData['channelType'][i].value) {
          return searchRef.value.State.treeData['channelType'][i].label
        }
      }
    }
    const protocolRef = ref()
    // 新增
    const handelSave = (status, data) => {
      protocolRef.value.showDrawer(status, data)
    }
    // 批量删除
    const handelDelete = (data) => {
      let arr = []
      if (data.id) {
        arr.push(data.id)
      } else {
        if (!state.selectedRowKeys.length) return message.warning('请先选择数据')
        arr = state.selectedRowKeys
      }
      Modal.confirm({
        title: '删除提示',
        content: '是否删除已选择信息？',
        async onOk () {
          request(getRequestURI('productChannelAgreement_delete'), { data: { ids: arr } }).then(res => {
            if (res.code === 1) {
              handleSearch(state.newChannel)
              message['success']('删除成功')
            } else {
              message['error'](res.message)
            }
          })
        },
        onCancel () {
          Modal.destroyAll()
        }
      })
    }
    // 多选
    const onSelectChange = (selectedRowKeys) => {
      state.selectedRowKeys = selectedRowKeys
    }

    // 获取渠道名称
    const getChannelName = () => {
      let data = {}
      request(getRequestURI('channel_select'), { data }, true, 'get').then(result => {
        let arr = []
        result.result.forEach(item => {
          arr.push({ id: item.id, value: item.id, label: item.channelName })
        })
        state.condition.channelId.data = arr
      })
    }
    onMounted(() => {
      getChannelName()
    })

    return {
      getChannelName,
      ...toRefs(state),
      protocolRef,
      getChannelTypeDetail,
      searchRef,
      pagination,
      dataSource,
      loading,
      onSelectChange,
      handleTableChange,
      handleSearch,
      handelSave,
      handelDelete,
    }
  }
}
</script>

<style scoped>
</style>