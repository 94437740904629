import * as monaco from 'monaco-editor'
import store from '../../../store'

const isNotEmpty = (...args) => {
  for (let i = 0; i < args.length; i++) {
    let argument = args[i]
    if (argument == null || argument === '' || argument === undefined) {
      return false
    }
  }
  return true
}
const getKind = (type) => {
  switch (type) {
    case 'object':
      return monaco.languages.CompletionItemKind.Variable
    case type === 'function':
      return monaco.languages.CompletionItemKind.Function
    case type === 'operator':
      return monaco.languages.CompletionItemKind.Operator
    case type === 'keyword':
      return monaco.languages.CompletionItemKind.Keyword
    default:
      return monaco.languages.CompletionItemKind.Field
  }
}

export const getRulConfig = () => {
  let config = store.getters['rule/getConfigInfo']
  if (isNotEmpty(config) && isNotEmpty(config.keywordList) && config.keywordList.length > 0) {
    return config
  }
  return {}
}

export const getAutoCompletion = () => {
  //不相同的提示写这里
  // {
  //   label: '如果',//提示名
  //   kind: monaco.languages.CompletionItemKind.Function,
  //   // insertText: '如果 ( ) { }\n否则 { }'//补全代码,
  //   insertText: ['如果 (${1:条件}) {', '\t$0', '} 否则 {', '\t', '}'].join('\n'),
  //   insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
  //   detail: '如果，一般与 否则配合使用'
  // },
  // {
  //   label: 'AGE',//提示名
  //   kind: monaco.languages.CompletionItemKind.Function,
  //   insertText: ['AGE (${1:出生日期},${2:最小值},${3:最小值类型},${4:最大值},${5:最大值类型})'].join('\n'),
  //   insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
  //   detail: '年龄比较'
  // },{
  //   label: 'asssert',//提示名
  //   kind: monaco.languages.CompletionItemKind.Keyword,
  //   insertText: ['断言 (${1:predicate}, ${2:[msg]})'].join('\n'),
  //   insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
  //   detail: '断言'
  // },
  return getRulConfig()['keywordList'].map(item => ({
      label: item.keywordName,
      kind: getKind(item.keywordType),
      insertText: item.completionStatement ? item.completionStatement : item.keywordName,
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      detail: item.describes
    })
  )
}

export const getTextTo = (text = '', flag = 'toE') => {
  const array = ['']
  let pos = 0
  const splitChars = [
    ' ', '(',')','[',']','{','}',';',
    '（','）','【','】','「','」','；'
  ]
  for (let i = 0; i < text.length; i++) {
    if (splitChars.includes(text[i]) && i === 0) {
      array[pos] = text[i]
      array.push('')
      pos++
    } else if (splitChars.includes(text[i]) && i !== 0) {
      if (array[pos] !== '') {
        array.push('')
        pos++
      }
      array[pos] = text[i]
      array.push('')
      pos++
    } else {
      array[pos] = array[pos] + text[i]
    }
  }
  return array.map(item => getScriptWordByKeyOrName(item, flag)).join('')
}


const toUnicode = function (str) {
  if (str) {
    let unStr = ''
    for (let i = 0, len = str.length; i < len; i++) {
      let c = str.charCodeAt(i).toString(16)
      if (c.length === 4) {
        unStr = unStr + '\\u' + c
      } else if (c.length === 2) {
        unStr = unStr + '\\x' + c
      }
    }
    return unStr
  } else {
    return ''
  }
}
export const getScriptWordByName = function (str) {
  if (str === '' ) {
    return str
  }
  let r = new RegExp('#[^#]*#\\w#' + toUnicode(str)).exec(getRulConfig().keyWordsStr) || ['##E#' + str]
  let [, key, type, tag] = r[0].split('#')
  return {key: key || '', type: type || '', tag: tag || ''}
}

export const getScriptWordByKey = function (str) {
  let r = new RegExp('#' + toUnicode(str) + '#\\w#.*').exec(getRulConfig().scriptDataStr) || ['###']
  let [, key, type, tag] = r[0].split('#')
  return {key: key || '', type: type || '', tag: tag || ''}
}

export const getScriptWordByKeyOrName = (str, flag) => {
  let regStr
  if (flag === 'toE') {
    regStr = getScriptWordByName(str).key
  } else {
    regStr = getScriptWordByKey(str).tag
  }
  return regStr === '' ? str : regStr
}

