<style>
:root {
  --editor-space-clr: #9bd5fd;
  --editor-edit-clr: #ff9a9a;
  --editor-active-clr: #740000;
  --editor-primary-color: #1890ff;
}
</style>
<style scoped>
.editor_layout {
  display: flex;
  flex-direction: row;
  height: 100%;
  position: relative;
}

.editor_template, .editor_background, .editor_attributes {
  height: 100%;
}

.editor_template {
  width: 30%;
}

:deep(.ant-tabs-content) {
  height: 100%;
}
.ant-radio-button-wrapper {
  margin: 5px 10px 5px 0;
}
.search {
  line-height: 30px;
}
.search.title {
  font-size: 12px;
  font-weight: bolder;
  color: var(--editor-primary-color);
}
.block_list {
  height: calc(100% - 268px);
  overflow-x: hidden;
  overflow-y: scroll;
}
/* 拖拽 */
.editor_drag_preview {
  position: fixed;
  width: 460px;
  max-height: 240px;
  overflow: hidden;
  opacity: 0.5;
  z-index: 99999;
}

/* 编辑 */
.editor_background {
  width: 50%;
  background: #efefef;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

/* 编辑 page */
.editor_page {
  background: #FFFFFF;
  width: 480px;
  min-height: 30px;
  box-shadow: 0 0 15px #666666;
  margin: 50px auto;
  padding: 10px;
}

.page_space {
  width: 100%;
  height: 6px;
  line-height: 6px;
  cursor: pointer;
}

.page_space:hover {
  background: var(--editor-space-clr);
}

.page_block {
  margin: 5px 0;
  height: auto;
  min-height: 30px;
  border: 1px dashed #e0e0e0;
  cursor: pointer;
  user-select: none;
  background: #FFFFFF;
  outline: 0 none transparent !important;
}

.page_block:hover {
  border-color: var(--editor-space-clr);
}

.page_block.drag {
  opacity: 0.5;
  border-color: var(--editor-active-clr);
}

.page_block[contenteditable=true] {
  border-color: var(--editor-edit-clr);
  user-select: auto;
  cursor: text;
}

/* 右侧模板属性 */
.editor_attributes {
  width: 20%;
  padding: 15px;
}
</style>

<template>
  <a-drawer
    :title="title"
    width="100%"
    :visible="visible"
    :body-style="{ padding: '0' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <template #extra>
      <a-space>
        <a-button type="primary" @click="onSaveAs" v-if="getRequestURI('t_page_edit')||getRequestURI('t_page_add')">
          <SaveOutlined/>
          保存
        </a-button>
        <a-button type="primary" @click="onSave" v-if="getRequestURI('t_page_edit')||getRequestURI('t_page_add')">
          <DeliveredProcedureOutlined/>
          另存为
        </a-button>
        <a-button type="primary" @click="onPreview" v-if="getRequestURI('t_page_preview')">
          <EyeOutlined/>
          预览
        </a-button>
        <a-button @click="onClose">
          <CloseOutlined/>
          关闭
        </a-button>
      </a-space>
    </template>
    <div class="editor_layout" @mousedown="resetFocus">
      <div v-show="isDrag" class="editor_drag_preview" ref="dragElRef"></div>
      <div class="editor_template">
        <a-tabs v-model:activeKey="templateActiveKey" tab-position="left"
                :style="{height: '100%'}" size="small">
          <a-tab-pane key="template" style="padding-left: 0;height: 100%;">
            <template #tab>排<br/>版</template>
            <div style="height: 100%; padding: 10px;">
              <div class="search title">素材分类</div>
              <div class="search">
                <a-radio-group v-model:value="blockType" size="small">
                  <a-radio-button value="block_title">标题</a-radio-button>
                  <a-radio-button value="block_card">卡片</a-radio-button>
                  <a-radio-button value="block_layout">布局</a-radio-button>
                  <a-radio-button value="block_image">图片</a-radio-button>
                  <a-radio-button value="block_video">视频</a-radio-button>
                  <a-radio-button value="block_audio">音频</a-radio-button>
                  <a-radio-button value="block_file">文件</a-radio-button>
                  <a-radio-button value="block_product_attribute">产品属性</a-radio-button>
                  <a-radio-button value="block_product_file">产品附件</a-radio-button>
                </a-radio-group>
              </div>
              <div class="search title">素材标签</div>
              <div class="search">
                <a-button type="primary" ghost size="small">选择</a-button>
              </div>
              <div class="search title">搜索</div>
              <div class="search">
                <a-input-search v-model:value="blockSearchValue" placeholder="输入素材关键字" @search="onBlockSearch" />
              </div>
              <a-divider style="border-color: var(--editor-primary-color);" />
              <div class="block_list">
                <section class="page_block" @mousedown="startDrag">
                  <p>测试测试</p>
                </section>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="clipboard" style="padding-left: 0;height: 100%;">
            <template #tab>剪<br/>切<br/>板</template>
            剪切板
          </a-tab-pane>
        </a-tabs>
      </div>
      <div class="editor_background">
        <block-tool v-show="editContextId >= 0" ref="blockToolRef" @saveAsBlockHandle="saveAsBlockHandle" @configBlockHandle="configBlockHandle"/>
        <content-tool v-show="editContextId >= 0" ref="contentToolRef"/>
        <div class="editor_page">
          <template v-for="(pItem, pIndex) in editBlockList" :key="'pm_' + pIndex">
            <div class="page_space" :editBlockId="pIndex" @click="(event) => insertBaseBlock(event, pIndex)"
                 @mouseover="enterBlock(pIndex, 'space')" @mouseout="leaveBlock"></div>
            <section class="page_block editor_page_block"
                     :contenteditable="editContextId === pIndex"
                     :editBlockId="pIndex"
                     @mousedown="startDrag"
                     @mouseover="enterBlock(pIndex, 'block')"
                     @mouseout="leaveBlock"
                     @click="(event) => canEditBlock(event, pIndex)"
                     @blur="(event) => stopEdit(event, pIndex)"
                     v-html="pItem.html"
            >
            </section>
          </template>
          <div class="page_space" :editBlockId="editBlockLength.value"
               @click="(event) => insertBaseBlock(event, editBlockLength.value)"
               @mouseover="enterBlock(editBlockLength.value, 'space')" @mouseout="leaveBlock"></div>
        </div>
      </div>
      <div class="editor_attributes">
        <a-form :model="formData" :rules="formRules" layout="vertical" ref="formRef" autocomplete="off">
          <a-form-item label="模板编码" name="id" v-show="false">
            <a-input v-model:value="formData.id"/>
          </a-form-item>
          <a-form-item label="模板名称" name="template_name" required>
            <a-input v-model:value="formData.template_name" placeholder="请输入模板名称"/>
          </a-form-item>
          <a-form-item label="模板类型" name="template_type" required>
            <a-select v-model:value="formData.template_type" placeholder="请选择模板类型">
              <a-select-option :value="'1'">产品页模板</a-select-option>
              <a-select-option :value="'3'">图文页模板</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="适配类型" name="device_type" required>
            <a-select v-model:value="formData.device_type" placeholder="请选择适配类型">
              <a-select-option :value="'0'">通用</a-select-option>
              <a-select-option :value="'1'">手机</a-select-option>
              <a-select-option :value="'2'">电脑</a-select-option>
              <a-select-option :value="'3'">平板</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="模板分组" name="template_group_id" >
            <a-select v-model:value="formData.template_group_id" placeholder="请选择模板分组">
            </a-select>
          </a-form-item>
          <a-form-item label="供应商" name="supplier_id" >
            <a-select v-model:value="formData.supplier_id" placeholder="请选择供应商">
            </a-select>
          </a-form-item>
          <a-form-item label="版本号" name="version" >
            <a-input v-model:value="formData.version" :bordered="false" disabled/>
          </a-form-item>
          <a-form-item label="状态" name="status" >
            <a-input v-model:value="formData.status" :bordered="false" disabled/>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <template #footer>
      <a-row style="text-align: center; font-size: 12px;line-height: 32px;">
        <a-col span="12">
          <a-button type="link" @click="setTemplateConfig">
            <setting-outlined/>
            全局格式
          </a-button>
          字体：{{ templateConfig.fontFamily }} 字号：{{ templateConfig.fontSize }} 行间距：{{ templateConfig.rowSpace }}
          字间距：{{ templateConfig.letterSpace }} 字体颜色：{{ templateConfig.fontColor }}
          背景颜色：{{ templateConfig.backgroundColor }}
        </a-col>
        <a-col span="12">
          <dashboard-outlined/>
          页面内容： 字数：{{ templatePageData.wordCount }} 浏览时间： {{ templatePageData.previewTimes }}
          图片数：{{ templatePageData.photoCount }} 视频数：{{ templatePageData.vedioCount }}
          链接数：{{ templatePageData.linkCount }} 录入项数：{{ templatePageData.inputCount }}
        </a-col>
      </a-row>

    </template>
  </a-drawer>
</template>

<script>
import {defineComponent, computed, nextTick, reactive, ref, toRefs} from 'vue'
import {getRequestURI} from '../../utils/request.js'
import ContentTool from './content_tool'
import BlockTool from './block_tool'

export default defineComponent({
  components: {ContentTool, BlockTool},
  emits: ['callback'],
  setup(props, context) {
    const state = reactive({
      visible: false,
      isNew: true,
      isEdit: false,
      editContextId: -1,
      enterBlockId: -1,
      enterBlockType: '',
      blockSearchValue: '',
      blockTags: [],
      title: '',
      templateActiveKey: 'template',
      blockType: 'block_title',
      templateData: {},
      template_type_name: {
        '1': '产品页模板',
        '3': '图文页模板'
      },
      pageList: [],
      displayPageList: [],
      editBlockList: [
        {
          type: 'html',
          html: 'dsadsadsa'
        },
        {
          type: 'vue',
          config: {},
          html: 'dsadsadsa'
        }
      ],
      formData: {
        id: '',
        template_name: '',
        template_type: '1',
        device_type: '1',
        template_group_id: null,
        supplier_id: null,
        version: null,
        status: null
      },
      templateConfig: {
        fontSize: '14px',
        fontFamily: 'SimSun SimHei Arial Helvetica sans-serif ',
        fontColor: '#000000',
        rowSpace: '28px',
        letterSpace: '0',
        backgroundColor: '#ffffff'
      },
      templatePageData: {
        wordCount: 0,
        previewTimes: '5s',
        photoCount: 0,
        vedioCount: 0,
        linkCount: 0,
        inputCount: 0
      }
    })
    const setTemplateConfig = () => {

    }
    const formRef = ref()
    const formRules = {}
    const editBlockLength = computed(() => ({
      value: state.editBlockList.length,
    }))
    const blockToolRef = ref()
    const contentToolRef = ref()
    const onClose = () => {
      state.visible = false
    }
    const onSave = () => {
    }
    const onSaveAs = () => {
    }
    const onPreview = () => {
    }
    const showDrawer = (type, data) => {
      if (data) {
        state.isNew = false
        state.title = '修改'
      } else {
        state.isNew = true
        state.title = '新建'
      }
      state.title = state.title + state.template_type_name[type]
      state.visible = true
    }
    context.expose({
      showDrawer
    })
    // --- drag ---
    const dragElRef = ref()
    const dragState = reactive({
      isDrag: false,
      dragTarget: null,
      dragX: 0,
      dragY: 0,
      originalX: 0,
      originalY: 0
    })
    const startDrag = (event) => {
      event.stopPropagation()
      if (event.button === 0) {
        dragState.dragTarget = event.currentTarget
        dragState.dragX = event.clientX
        dragState.dragY = event.clientY
        dragElRef.value.innerHTML = event.currentTarget.outerHTML
        dragElRef.value.style.left = (event.clientX + 12) + 'px'
        dragElRef.value.style.top = (event.clientY + 15) + 'px'
        dragState.dragTarget.classList.add('drag')
        dragElRef.value.style.opacity = 0
        dragState.isDrag = true
        document.addEventListener('mouseup', stopDrag)
        document.addEventListener('mousemove', drag)
      }
    }

    const drag = (event) => {
      if (event.button === 0 && dragState.isDrag && !state.isEdit) {
        event.stopPropagation()
        dragElRef.value.style.opacity = 0.5
        dragElRef.value.style.left = (event.clientX + 12) + 'px'
        dragElRef.value.style.top = (event.clientY + 15) + 'px'
      }
    }
    const stopDrag = () => {
      if (dragState.dragTarget) {
        dragState.dragTarget.classList.remove('drag')
      }
      if (dragState.isDrag && state.enterBlockId >= 0 && dragState.dragTarget.getAttribute('editBlockId') != state.enterBlockId) {
        if (state.enterBlockType === 'block') {
          updateBlock(state.enterBlockId, dragState.dragTarget.innerHTML, 1)
        } else if (state.enterBlockType === 'space') {
          updateBlock(state.enterBlockId, dragState.dragTarget.innerHTML, 0)
        }
        state.enterBlockId = -1
        state.enterBlockType = ''
      }
      dragState.isDrag = false
      document.removeEventListener('mouseup', stopDrag)
      document.removeEventListener('mousemove', drag)
    }
    // --- drag end ---
    const resetFocus = (event) => {
      if (event.path.filter(item => item.classList && item.classList.contains('editor_tool')).length === 0) {
        state.editContextId = -1
        state.isEdit = false
      }
    }
    const canEditBlock = (event, contextId) => {
      event.stopPropagation()
      state.editContextId = contextId
      state.isEdit = true
      const _top = event.clientY >= 150 ? event.clientY - 150 : 0
      const _top2 = _top + 55
      blockToolRef.value.updateContextId(contextId, event.currentTarget, _top + 'px')
      contentToolRef.value.updateContextId(contextId, event.currentTarget, _top2 + 'px')
      nextTick(() => {
        event.currentTarget.focus()
      })
    }
    const stopEdit = (event, pIndex) => {
      state.editBlockList[pIndex].html = event.target.innerHTML
    }
    const insertBaseBlock = (event, pIndex) => {
      state.editBlockList.splice(pIndex, 0, {
        html: '请输入内容' + pIndex
      })
      state.editContextId = pIndex
      state.isEdit = true
      const _top = event.clientY >= 150 ? event.clientY - 150 : 0
      const _top2 = _top + 55
      nextTick(() => {
        const el = document.getElementsByClassName('editor_page_block')[pIndex]
        el.focus()
        blockToolRef.value.updateContextId(pIndex, el, _top + 'px')
        contentToolRef.value.updateContextId(pIndex, el, _top2 + 'px')
      })
    }
    const updateBlock = (pIndex, t_html, delCount) => {
      state.editBlockList.splice(pIndex, delCount, {
        html: t_html
      })
      console.log('updateBlock', state.editBlockList)
    }
    const enterBlock = (pIndex, type) => {
      state.enterBlockId = pIndex
      state.enterBlockType = type
    }
    const leaveBlock = () => {
      state.enterBlockId = -1
      state.enterBlockType = ''
    }
    const saveAsBlockHandle = (blockContextId) => {
      console.log('saveAsBlockHandle', blockContextId)
    }
    const configBlockHandle = () => {

    }
    const onBlockSearch = () => {

    }
    return {
      blockToolRef,
      contentToolRef,
      formRef,
      formRules,
      ...toRefs(state),
      ...toRefs(dragState),
      onBlockSearch,
      onClose,
      onSave,
      onSaveAs,
      onPreview,
      getRequestURI,
      resetFocus,
      startDrag,
      drag,
      stopDrag,
      canEditBlock,
      stopEdit,
      insertBaseBlock,
      enterBlock,
      leaveBlock,
      setTemplateConfig,
      editBlockLength,
      dragElRef,
      saveAsBlockHandle,
      configBlockHandle
    }
  }
})
</script>
