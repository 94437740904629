<template>
  <search-form
    ref="searchRef"
    :Condition="condition"
    :ColSpan="12"
    @resetAll="resetAll"
    @callback="handleSearch"
  ></search-form>
  <div class="btn-bar">
    <a-button type="primary" @click="addModule()">
      <template #icon>
        <FileAddOutlined />
      </template>
      新增
    </a-button>
    <a-button danger @click="delModule('sel')">
      <template #icon>
        <DeleteOutlined />
      </template>
      批量删除
    </a-button>
  </div>
  <a-table
    :columns="columns"
    :data-source="dataSource? dataSource['result']: []"
    :loading="loading"
    :pagination="pagination"
    :row-key="(record) => record.id"
    :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
    @change="handleTableChange"
  >
    <template #bodyCell="{ column, record,  index}">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key=== 'templateFileUrl'">
        <a
          href="javascript:void(0)"
          style="color: orange"
          @click="downModule(record)"
        >下载模板</a>
      </template>
      <template v-if="column.key === 'operation'">
        <div style="display: flex">
          <a
            style="color: orange; flex: 1"
            @click="moduleCheck(record, 'select')"
          >查看</a
          >
          <a
            style="color: orange; flex: 1"
            @click="modifyProduct(record, 'update')"
          >编辑</a
          >
<!--          <a style="color: orange; flex: 1" @click="delModule(record)">删除</a>-->
        </div>
      </template>
    </template>
  </a-table>
<module-drawer ref="moduleDrawerRef" @select="handleSearch()" :status = "status" ></module-drawer>
</template>

<script>
  import {defineComponent, reactive, toRefs, onMounted, computed, ref} from 'vue'
  import  moduleDrawer  from './moduleDrawer'
    import SearchForm from '../../../components/extended/search_form'
  import {getRequestURI, request, requestUsePagination} from '../../../utils/request'
  import {message, Modal} from 'ant-design-vue'
  import {getDictionaryData} from '../../../utils/request_extend'

    export default defineComponent({
      components: {
        SearchForm,
        moduleDrawer
      },
      setup () {
        const moduleDrawerRef = ref()
        const templistValue = ref()
        const tempTypeList = ref()
        const state = reactive({
          isCopy: false,
          selectedRowKeys: [],
          status: '',
          condition: {
            supName: {
              label: '供应商名称',
              type: 'tree-select',
              urlKey: 'querySupplier',
              // urlKey: 'supplierQuerySupLevelSt',
              value: 'supName',
              default: '',
              data: []
            },
            // supName: {
            //   label: '供应商名称',
            //   type: 'tree-select',
            //   urlKey: 'supplierQuerySupLevelSt',
            //   value: 'supName',
            //   default: '',
            //   data: []
            // },
            supCode: '供应商编码',
          },
          current: '',
          pageSize: '',
          searchNow: {},
          columns: [
            {
              title: '序号',
              key: 'index',
              fixed: 'left',
              width: 60,
              align: 'left',
            },
            {
              title: '供应商编码',
              width: 200,
              align: 'center',
              key: 'supCode',
              dataIndex: 'supCode',
            },{
              title: '供应商名称',
              width: 200,
              align: 'center',
              key: 'supName',
              dataIndex: 'supName',
            },
            {
              title: '模板名称',
              width: 100,
              align: 'center',
              key: 'templateName',
              dataIndex: 'templateName',
            },
            {
              title: '模板类型',
              width: 120,
              align: 'center',
              key: 'templateType',
              dataIndex: 'templateType',
              customRender: (text)=> getTempType(text.value),
            },
            {
              title: '适用执业区域编码',
              width: 120,
              align: 'center',
              key: 'areaSignList',
              dataIndex: 'areaSignList',
              customRender: (text)=> getAreaSignType(text.value),
            },
            {
              title: '模板附件',
              width: 100,
              align: 'center',
              key: 'templateFileUrl',
              dataIndex: 'templateFileUrl',
            },
            {
              title: '关键字',
              width: 200,
              align: 'center',
              key: 'templateFileKeyWord',
              dataIndex: 'templateFileKeyWord',
            },
            {
              title: '备注',
              width: 100,
              align: 'center',
              key: 'templateDesc',
              dataIndex: 'templateDesc',
            },
            {
              title: '操作',
              align: 'center',
              width: 200,
              key: 'operation',
              dataIndex: 'operation',
            },
          ],
          })

        const {
          data: dataSource,
          run,
          loading,
          current,
          pageSize,
          total,
        } = requestUsePagination(getRequestURI('moduleConfig_select'))

        const pagination = computed(() => ({
          current: current.value,
          pageSize: pageSize.value,
          total: total.value,
        }))
        const resetAll = () => {
        //  state.condition.productId.data = []

        }
        const getAreaSignType = (params) => {

         const temp =  params.map(item => {return item.name})

          return temp.toString()
        }
        const getTempType = (params)=> {
        const temp =  tempTypeList.value.find(item => item.value === params)
          return temp.label? temp.label: ''
        }
//

        // 下载模板
        const downModule =  (oldData) => {
          const productTemp = oldData
          if(productTemp) {
            fetch(productTemp.templateFileUrl).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
              const a = document.createElement('a')
              a.href = URL.createObjectURL(blob)
              //测试链接console.log(a.href)
              a.download = productTemp.templateName  // 下载文件的名字
              document.body.appendChild(a)
              a.click()
            })
          }
        }
        // 添加
        const addModule = () => {
          state.status = ''
          moduleDrawerRef.value.showDrawer()

        }
        // 选择
        const onSelectChange = (selectedRowKeys) => {
          console.log('selectedRowKeys changed: ', selectedRowKeys)
          state.selectedRowKeys = selectedRowKeys
        }
        // 删除 批量删除
        const delModule = (record) => {
          let ids = []
          if (record !== 'sel') {
            ids.push(record.id)
          } else {
            console.log(ids)
            if (!state.selectedRowKeys.length) return message.warning('请选择数据')
            ids = [...state.selectedRowKeys]
          }
          Modal.confirm({
            title: '删除',
            content: record === 'sel' ? '确认批量删除吗?' : '是否删除已选择的？',
            onOk: () => {
              request(getRequestURI('moduleConfig_del'), { data:{ids} }).then(res => {
                if (res.code === 1) {
                  handleSearch()
                  message.success('删除成功')
                  state.selectedRowKeys = []
                } else {
                  message['error'](res.message)
                }
              })
            },
            onCancel: () => {
              Modal.destroyAll()
            }
          })

        }
        // 编辑
        const modifyProduct = (flag, params) => {
          state.status = params
          moduleDrawerRef.value.showDrawer(flag, params)

        }
        // 查看
        const moduleCheck = (oldData, params) => {
          state.status = params
          moduleDrawerRef.value.showDrawer(oldData, params)

        }
        const handleTableChange = (pag, filters, sorter) => {
          run({
            pagination: {
              current: pag?.current,
              pageSize: pag?.pageSize
            },
            sorter,
            data: state.searchNow,
          })
        }

        const handleSearch = function (filters) {
          state.searchNow = filters
          run({
            pagination: {
              current: 1,
              pageSize: 10
            },
            data: filters
          })
        }
        const getAreaList = () => {
          request(getRequestURI('moduleConfig_areaList'),
            {}).then(res => {
            handleSearch({})
            templistValue.value =  res.result.map(item=> {
              return {
                ...item,
                title: item.name,
                value: item.id
              }
            })
          })
        }
        onMounted(() => {
          getDictionaryData(tempTypeList, 'signTemplateType')
          getAreaList()
        })

        return {
            ...toRefs(state),
            resetAll,
            addModule,
          handleSearch,
          delModule,
          moduleCheck,
          modifyProduct,
          pagination,
          moduleDrawerRef,
          dataSource,
          run,
          loading,
          current,
          pageSize,
          total,
          onSelectChange,
          templistValue,
          downModule,
          tempTypeList,
          handleTableChange
          }
      }

    })
</script>

<style scoped>

</style>
