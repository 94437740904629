<template>
  <a-drawer
    :title=panDuan
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form
      :model="formData"
      layout="vertical"
      ref="formRef"
      autocomplete="off"
    >
<!--      { pattern:/^[0-9a-zA-Z]+$/, message: '支持数字和字母格式' ,trigger: 'change'}-->
      <a-row :gutter="10">
        <a-col span="12">
          <a-form-item name="supCode"
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'},
                       {max: 20, message: '输入长度不能超过20' ,trigger: 'change'}]">
            <template #label>
              <span>供应商编码: </span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                    <p>供应商编码在程序中作为常量使用，一旦定义，不要轻易改动</p>
                  </template>
                  <template #title>
                    <span>注意:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
            </template>
            <a-input v-model:value="formData.supCode" :disabled="zhuangTai1=='details'" placeholder="请输入供应商编码"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="供应商级别:" name="supGrade" v-if="ziDuan" required>
            <a-select v-model:value="formData.supGrade" :disabled="zhuangTai1=='details'||'edit'"
                      :options="supGradeList">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item
            :rules="[{ max: 50, message: '输入长度不能超过50' ,trigger: 'change'},
            { required: true, message: '必填信息' ,trigger: 'change'},
            { pattern:/^[\u4e00-\u9fa5]{0,}$/, message: '只支持中文' ,trigger: 'change'}
            ]"
            label="供应商名称:" name="supName">
            <a-input v-model:value="formData.supName" :disabled="zhuangTai1=='details'" placeholder="请输入供应商名称"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item
            :rules="[{max: 10, message: '输入长度不能超过10' ,trigger: 'change'},
            { pattern:/^[a-zA-Z]/, message: '支持字母格式' ,trigger: 'change'}]"
            label="供应商首字母:" name="supInitial">
            <a-input v-model:value="formData.supInitial" :disabled="zhuangTai1=='details'" placeholder="请输入供应商首字母"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="供应商简称:" name="supShortName"
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]"
          >
            <a-input v-model:value="formData.supShortName" :disabled="zhuangTai1=='details'" placeholder="请输入供应商简称"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="供应商英文名称:" name="supEngName"
                       :rules="[{max: 200, message: '输入长度不能超过200' ,trigger: 'change'},
                        { pattern:/^[a-zA-Z0-9]/, message: '支持数字和字母格式' ,trigger: 'change'}
                       ]"
          >
            <a-input v-model:value="formData.supEngName" :disabled="zhuangTai1=='details'" placeholder="请输入供应商英文名称"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="供应商类别:" name="supType"
                       :rules="[{required: true,  message: '必填信息' ,trigger: 'change'}]"
          >
            <a-select v-model:value="formData.supType"   allow-clear :disabled="zhuangTai1=='details'" :options="supTypeList" placeholder="请选择供应商类别" clearable>
            </a-select>
          </a-form-item>
        </a-col>
<!--        <a-col span="12">-->
<!--          <a-form-item label="供应商是否有效:" name="supStatus"-->
<!--                       :rules="[{required: true,  message: '请选择供应商是否有效' ,trigger: 'change'}]">-->
<!--            <a-select v-model:value="formData.supStatus" :disabled="zhuangTai1=='details'">-->
<!--              <a-select-option value="0">有效</a-select-option>-->
<!--              <a-select-option value="1">无效</a-select-option>-->
<!--            </a-select>-->
<!--          </a-form-item>-->
<!--        </a-col>-->
        <a-col span="12">
          <a-form-item label="公司地址:" name="comAddress"
                       :rules="[{max: 50, message: '输入长度不能超过50' ,trigger: 'change'}]"
          >
            <a-input v-model:value="formData.comAddress" :disabled="zhuangTai1=='details'" placeholder="请输入公司地址"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="邮编:" name="emailCode"
                       :rules="[{pattern:/^[0-9]/, message: '支持数字' ,trigger: 'change'},
                       {max: 6, message: '输入长度不能超过6' ,trigger: 'change'}]"
          >
            <a-input v-model:value="formData.emailCode" :disabled="zhuangTai1=='details'" placeholder="请输入邮编"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="公司电话:" name="comPhoneNumber"
                       :rules="[{pattern:/^[0-9]/, message: '支持数字' ,trigger: 'change'},
                       { pattern:/^(\d{8}|\d{11}|\d{16})$/, message: '只能输入8位电话或11位手机或16位公司号码' ,trigger: 'change'}]"
          >
            <a-input v-model:value="formData.comPhoneNumber" :disabled="zhuangTai1=='details'" placeholder="请输入公司电话"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="公司网址:" name="comUrl"
                       :rules="[{max: 50, message: '输入长度不能超过50' ,trigger: 'change'}]"
          >
            <a-input v-model:value="formData.comUrl" :disabled="zhuangTai1=='details'" placeholder="请输入公司网址"/>
          </a-form-item>
        </a-col>
        <!--        <a-col span="12"></a-col>-->
        <a-col span="24">
          <label>请选择区域:</label>
        </a-col>
        <a-col span="24">
          <div class="itempop">
            <a-select style="width: 100%" placeholder="请选择省" defaultValue="" allow-clear
                      v-model:value="formData.provinceCode"
                      :disabled="zhuangTai1=='details'"
                      @change="changeQueryProvince" :options="shengList" clearable>
              <!--            <a-select-option  v-for="item in shengList" :key="item.id" :value="item.id">-->
              <!--              {{ item.name }}-->
              <!--            </a-select-option>-->
            </a-select>
          </div>
        </a-col>
        <a-col span="24">
          <div class="itempop">
            <a-select style="width: 100%" @change="changeQueryCity" allow-clear v-model:value="formData.cityCode"
                      placeholder="请选择市"
                      :disabled="zhuangTai1=='details'" :options="shiList" clearable>
              <!--            <a-select-option  v-for="item in shiList" :key="item.id" :value="item.id">-->
              <!--              {{ item.name }}-->
              <!--            </a-select-option>-->
            </a-select>
          </div>
        </a-col>
        <a-col span="24">
          <div class="itempop">
            <a-select style="width: 100%" @change="changeQueryCounty" allow-clear v-model:value="formData.countyCode"
                      placeholder="请选择区"
                      :disabled="zhuangTai1=='details'" :options="xianList" clearable>
            </a-select>
          </div>
        </a-col>

        <a-col span="12">
          <a-form-item label="成立日期:" name="createTime">
            <a-date-picker
              mode="date"
              placeholder="请输入成立日期"
              v-model:value="formData.createTime"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD HH:mm:ss"
              :disabled="zhuangTai1=='details'"
              style="width: 100%"
            ></a-date-picker>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item label="供应商介绍:" name="supIntroduce"
                       :rules="[{max: 200, message: '输入长度不能超过200' ,trigger: 'change'}]"
          >
            <a-textarea v-model:value="formData.supIntroduce" :rows="4" :disabled="zhuangTai1=='details'" placeholder="请输入供应商介绍"/>
          </a-form-item>
        </a-col>
        <a-col span="8">
          <a-form-item label="LOGO图片:(只支持上传图片)" name="click" >
            <upload-file-new @handelDel="handelDel"  :disabled="zhuangTai1=='details'" :key="time" filetype="img" :showImg="showImg" @fileChange="uploadCosFileChange" listType="picture-card" num="1"  ></upload-file-new>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button html-type="submit" type="primary" @click="onSave()" v-if="zhuangTai1!='details'"  :disabled="!ziDuan">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>
<script>
import {CloseOutlined} from '@ant-design/icons-vue'
import {computed, defineComponent, onBeforeMount, reactive, ref, toRefs} from 'vue'
import {message} from 'ant-design-vue'
import {getRequestURI, request} from '../../../utils/request'
import {getDictionaryData} from '../../../utils/request_extend'
// import uploadFile  from '../../../components/extended/uploadFile'
import uploadFileNew from '../../../components/extended/uploadFileNew'

export default defineComponent({
  components: {
    CloseOutlined,

    uploadFileNew
  },
  props: {
    status: {
      type: String,
      required: true,
      default() {
        return '123'
      },
    },
    searchfFilters: { type: Object },
    handleSearch: {type: Function},
    current: {type: Number}
  },
  setup(props, {expose}) {
    const formRef = ref()
    const formData = reactive({
      id: '',
      parentId: 0,//上级供应商ID
      supCode: '',//供应商编码
      supName: '',//供应商名称
      supGrade: '1',//供应商级别
      supType: null,//供应商类别
      supInitial: '',//供应商首字母
      supShortName: '',//供应商简称
      supEngName: '',//供应商英文名称
      supStatus: '0',//供应商是否有效
      provinceCode: null,//省
      cityCode: null,//市
      countyCode: null,//区
      comAddress: '',//公司地址
      emailCode: '',//邮编
      comPhoneNumber: '',//公司电话
      comUrl: '',//公司网址
      createTime: '',
      updateTime: '',
      supIntroduce: '',//供应商介绍
      logo: '',
      // supCode	: '',//供应商编码
      createUser: '',
      updateUser: '',
    })
    const state = reactive({
      time:null,
      showImg:[],//图片
      zhuangTai3: null,
      zhuangTai1: null,
      ziDuan: true,
      shengList: [],
      shiList: [],
      xianList: [],
      biaoTou: '',
      visible: false,
      isNew: true,
      transferStyle: {width: '50%'},
      roleTitles: ['未添加授权角色列表', '已添加角色列表'],
      roleData: [],
    })
    const handelDel = () =>{
      state.showImg = []
      formData.logo = ''

    }
    const supGradeList = ref([])
    const supTypeList = ref([])
    //改变省
    const changeQueryProvince = (val) => {
      console.debug(val,'000000')
      formData.provinceCode = val
      state.shiList.splice(0)
      state.xianList = []
      shiTest()
      formData.cityCode = ''
      formData.countyCode = ''
    }
    //改变市
    const changeQueryCity = (val) => {
      console.debug(val)
      state.xianList.splice(0)
      formData.cityCode = val
      xianTest()
      formData.countyCode = ''
    }
    //改变区
    const changeQueryCounty = (val) => {
      formData.countyCode = val
    }
    //获取省下拉数据
    const shengtest = () => {
      let obj = {
        type: 1,
      }
      let uri = getRequestURI('queryProvinceInfo')
      request(uri, obj).then(res => {
        res.result.forEach(item =>{
          let obj = {}
          obj.label = item.name
          obj.value = item.id + ''
          state.shengList.push(obj)
        })
      })
    }
    //获取市下拉数据
    const shiTest = () => {
      console.debug('测试数据')
      let obj = {
        data: {
          type: 1,
          parentId: formData.provinceCode
        }
      }
      let uri = getRequestURI('queryCityAndCountyInfo')
      request(uri, obj).then(res => {
        console.debug(res)
        res.result.forEach(item =>{
          let obd = {}
          obd.label = item.name
          obd.value = item.id + ''
          state.shiList.push(obd)
        })
        console.debug(state.shiList)
      })
    }
    //获取区下拉数据
    const xianTest = () => {
      let obj = {
        data: {
          type: 2,
          parentId: formData.cityCode
        }
      }
      let uri = getRequestURI('queryCityAndCountyInfo')
      request(uri, obj).then(res => {
        console.debug('测试数据',res)
        res.result.forEach(item =>{
          let obd = {}
          obd.label = item.name
          obd.value = item.id + ''
          state.xianList.push(obd)
        })
      })
    }

    //title判断状态
    const panDuan = computed(() => {
      if (props.status == 'newAdd' || props.status == 'addSon') {
        return '供应商新增'
      } else if (props.status == 'details') {
        return '供应商查看'
      } else {
        return '供应商编辑'
      }
    })
    const test = () => {
      shengtest()
    }
    const uploadCosFileChange=(data)=>{
      formData.logo=data.url
    }
    //组件传值
    const showDrawer = (oldData = {}, newData = {}) => {
      console.debug(newData,oldData)
      formData.cityCode = oldData.cityCode
      state.zhuangTai1 = newData
      state.ziDuan = true
      if(oldData.logo!=''){
        let obj = {}
        obj.url = oldData.logo
        state.showImg.push(obj)
      }
      if (newData === 'addSon') {
        formData.parentId = oldData.id//上级供应商ID
        oldData.logo=null
        if (oldData.supGrade == 4) {
          state.ziDuan = false
        }
      }
      test()
      state.visible = true

      state.isNew = !(typeof oldData.id === 'string' && oldData.id.length > 0)

      Object.keys(formData).forEach(key => {
        formData[key] = oldData[key] || formData[key]
      })
      console.debug(formData)
      if (oldData === 'newAdd' || newData === 'addSon') {
        state.showImg.splice(0)
      }
      //判断子公司添加清空
      if (newData === 'addSon') {
          formData.supGrade = (oldData.supGrade + 1) + ''
          formData.id = null
          formData.parentId = oldData.id//上级供应商ID
          formData.supCode = ''//供应商编码
          formData.supName = ''//供应商名称
          formData.supType = null//供应商类别
          formData.supInitial = ''//供应商首字母
          formData.supShortName = ''//供应商简称
          formData.supEngName = ''//供应商英文名称
          formData.supStatus = '0'//供应商是否有效
          formData.provinceCode = null//省
          formData.cityCode = null//市
          formData.countyCode = null//区
          formData.comAddress = ''//公司地址
          formData.emailCode = ''//邮编
          formData.comPhoneNumber = ''//公司电话
          formData.comUrl = ''//公司网址
          formData.createTime = ''
          formData.updateTime = ''
          formData.supIntroduce = ''//供应商介绍
          formData.logo = ''
          formData.createUser = ''
          formData.updateUser = ''

      }
      //新增公司级别默认
      if (oldData == 'newAdd') {
        formData.supGrade = '1'
        formData.parentId = 0

      }
      console.debug(formData.cityCode)
      state.shiList.splice(0)
      shiTest()
      state.xianList.splice(0)
      xianTest()
    }
    //关闭清空
    const onClose = async () => {
      state.visible = false
      formRef.value.clearValidate()
        formData.id = null
        formData.parentId = null//上级供应商ID
        formData.supCode = ''//供应商编码
        formData.supName = ''//供应商名称
        formData.supType = null//供应商类别
        formData.supInitial = ''//供应商首字母
        formData.supShortName = ''//供应商简称
        formData.supEngName = ''//供应商英文名称
        formData.supStatus = '0'//供应商是否有效
        formData.provinceCode = null//省
        // formData.cityCode = null,//市
        formData.countyCode = null//区
        formData.comAddress = ''//公司地址
        formData.emailCode = ''//邮编
        formData.comPhoneNumber = ''//公司电话
        formData.comUrl = ''//公司网址
        formData.createTime = ''
        formData.updateTime = ''
        formData.supIntroduce = ''//供应商介绍
        formData.logo = ''
        formData.createUser = ''
        formData.updateUser = ''
        state.showImg.splice(0)
        state.time = new Date().getTime()
    }

    const onFormClean = () => {
      formRef.value.resetFields()
      state.targetKeys = []
    }
    //提交
    const onSave = async () => {
      await formRef.value.validate()
      // 供应商编码校验
      let reg1 = /^[0-9a-zA-Z]/
      let reg1Array = formData.supCode.split('')
      let vive1 = reg1.test(formData.supCode)
      if (formData.supCode === '') {
        message['warning']('供应商编码不能为空')
        return
      }
      if (!vive1) {
        message['warning']('供应商编码只支持数字和字母格式')
        return
      }
      if (reg1Array.length > 20) {
        message['warning']('供应商编码输入长度不能超过20')
        return
      }

      // 供应商名称校验
      let reg2Array = formData.supName.split('')
      if (formData.supName === '') {
        message['warning']('供应商名称不能为空')
        return
      }
      if (reg2Array.length > 20) {
        message['warning']('供应商名称输入长度不能超过20')
        return
      }
      // 供应商首字母校验
      if (formData.supInitial != '') {
        let reg3 = /^[a-zA-Z]/
        let reg3Array = formData.supInitial.split('')
        let vive3 = reg3.test(formData.supInitial)
        if (!vive3) {
          message['warning']('供应商首字母支持字母')
          return
        }
        if (reg3Array.length > 10) {
          message['warning']('供应商首字母输入长度不能超过10')
          return
        }
      }
      //供应商简称
      if (formData.supShortName === '') {
        message['warning']('供应商简称不能为空')
        return
      }

      // 供应商英文名称校验
      if (formData.supEngName != '') {
        let reg4 = /^[a-zA-Z0-9]/
        // let reg4Array = formData.supEngName.split('')
        let vive4 = reg4.test(formData.supEngName)
        if (!vive4) {
          message['warning']('供应商英文名称支持数字和英文格式')
          return
        }
      }


      //供应商类别校验
      if (formData.supType === '') {
        message['warning']('供应商类别不能为空')
        return
      }

      // 公司地址校验
      if (formData.comAddress != '') {
        let reg5Array = formData.comAddress.split('')
        if (reg5Array.length > 50) {
          message['warning']('公司地址输入长度不能超过50')
          return
        }

      }

      // 邮编校验
      if (formData.emailCode != '') {
        let reg6 = /^[0-9]/
        let reg6Array = formData.emailCode.split('')
        let vive6 = reg6.test(formData.emailCode)
        if (!vive6) {
          message['warning']('邮编支持数字')
          return
        }
        if (reg6Array.length > 10) {
          message['warning']('邮编输入长度不能超过6')
          return
        }
      }


      // 公司电话校验
      if (formData.comPhoneNumber != '') {
        let reg7 = /^[0-9]/
        let reg7Array = formData.comPhoneNumber.split('')
        let vive7 = reg7.test(formData.comPhoneNumber)
        if (!vive7) {
          message['warning']('公司电话支持数字')
          return
        }
        if (reg7Array.length > 15) {
          message['warning']('公司电话输入长度不能超过15')
          return
        }
      }


      // 公司网址校验
      if (formData.comUrl != '') {
        let reg8Array = formData.comUrl.split('')
        if (reg8Array.length > 50) {
          message['warning']('公司网址输入长度不能超过50')
          return
        }
      }

      // 供应商介绍校验
      if (formData.supIntroduce != '') {
        let reg9Array = formData.supIntroduce.split('')
        if (reg9Array.length > 200) {
          message['warning']('供应商介绍输入长度不能超过200')
          return
        }
      }


      let obj = {
        data: formData
      }
      if (formData.supStatus !== '0') {
        message['warning']('供应商无效，无法维护协议信息')
      } else {
        if (props.status == 'edit') {
          if (formData.supGrade == 1) {
            formData.parentId = 0
          }
          let uri = getRequestURI('supplier_update')
          request(uri, obj).then(res => {
            props.handleSearch(props.searchfFilters,props.current)
            if (res.code === 1) {
              message['success']('修改成功')
              onClose()
            } else{
              message['warning'](res.message)
            }
          })
        } else {
          formData.id = ''
          let uri = getRequestURI('supplier_add')
          request(uri, obj).then(res => {
            props.handleSearch({})
            if (res.code == 1) {
              message['success']('新增成功')
              onClose()
            } else {
              message['warning'](res.message)
            }
          })
        }
      }
    }

    onBeforeMount(()=>{
      getDictionaryData(supGradeList,'supplierLevel')
      getDictionaryData(supTypeList,'supplierType')
    })

    expose({
      showDrawer
    })
    return {
      formRef,
      formData,
      supGradeList,
      supTypeList,
      ...toRefs(state),
      onClose,
      onSave,
      onFormClean,
      panDuan,
      changeQueryProvince,
      changeQueryCity,
      changeQueryCounty,
      uploadCosFileChange,
      handelDel
    }
  }
})
</script>
<style scoped>
.itempop {
  width: 50%;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
