<template >
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
  ></search-form>
  <div class="btn-bar">
    <a-button type="primary" @click="addUsers('newAdd')">
      <template #icon>
        <FileAddOutlined />
      </template>
      新增
    </a-button>
    <a-button danger @click="huanJing">
      <template #icon>
        <RetweetOutlined />
      </template>
      环境供应商同步
    </a-button>

    <a-button
      type="primary"
      style="background: #fe5e3a; border: #fe5e3a"
      @click="waiBu"
      v-if="false"
    >
      外部系统供应商同步
    </a-button>
  </div>
  <a-table
    :indentSize="30"
    :columns="columns"
    :data-source="dataSourceList"
    :row-key="(record) => record.id"
    @change="handleTableChange"
    :pagination="pagination"
    :key="time"
  >
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'operation'">
        <div>
          <a-button
            type="text"
            style="margin-right: 10px; color: orange"
            size="small"
            @click="selUser(record, 'details')"
            >查看
          </a-button>
          <a-button
            type="text"
            style="margin-right: 10px; color: orange"
            size="small"
            @click="upUser(record, 'edit')"
          >
            编辑
          </a-button>
          <a-button
            type="text"
            style="margin-right: 10px; color: orange"
            size="small"
            @click="deleteOne(record, index)"
            >删除
          </a-button>
          <a-button
            v-if="record.supGrade != '4'"
            type="text"
            size="small"
            style="color: orange"
            @click="addUser(record, 'addSon')"
          >
            添加子公司</a-button
          >
        </div>
      </template>
    </template>
  </a-table>
  <editAgent
    ref="userDrawerRef"
    :status="status"
    :handleSearch="getEditChange"
    :searchfFilters="searchfFilters"
    :current="pagination.current"
  ></editAgent>
  <envirSyn ref="hjTongBu"></envirSyn>
  <external ref="wbTongBu"></external>
</template>
<script>
import {defineComponent, onMounted, reactive, ref, toRefs} from 'vue'
import editAgent from './editAgent'
import envirSyn from './environmentSynchronization'
import external from './externalSystemSynchronization'
import SearchForm from '../../../components/extended/search_form'
import {getRequestURI, request} from '../../../utils/request'
import {message, Modal} from 'ant-design-vue'

export default defineComponent({
  components: {
    editAgent, SearchForm, envirSyn, external,
  },
  setup() {
    const userDrawerRef = ref()
    //环境供应商同步
    const hjTongBu = ref()
    //外部环境供应商同步
    const wbTongBu = ref()
    const state = reactive({
      pagination:{
        current: 1,
        pageSize: 10
      },
      time: null,
      del: '',
      deleteQuery: {},
      dataSourceList: [],
      condition: {
        id: {
          label: '供应商名称',
          type: 'tree-select',
          urlKey: 'querySupplier',
          // urlKey: 'supplierQuerySupLevelSt',
          value: 'id',
          default: '',
          data: []
        },
        supCode: '供应商编码',
        supGrade: {
          label: '供应商级别',
          type: 'select',
          urlKey: 'queryDictionaryType',
          queryParam: { type: 'supplierLevel' }
        },
        supType: {
          label: '供应商类别',
          type: 'select',
          urlKey: 'queryDictionaryType',
          queryParam: { type: 'supplierType' }
        }
      },
      columns: [
        {
          title: '供应商编码',
          dataIndex: 'supCode',
          key: 'supCode',
          align: 'center',
          fixed: 'left',
        },
        {
          title: '供应商名称',
          align: 'center',
          dataIndex: 'supName',
          key: 'supName'
        },
        {
          title: '级别',
          align: 'center',
          dataIndex: 'supGradeName',
          key: 'supGradeName'
        },
        {
          title: '类型',
          align: 'center',
          dataIndex: 'supTypeName',
          key: 'supTypeName'
        },
        {
          title: '操作',
          key: 'operation',
          ellipsis: true,
          align: 'center',
          width: 280,
          scopedSlots: { customRender: 'operation' }
        }
      ],
      status: '',
      tableList: [],
      selectedRowKeys: [],
      query: {
        data: {
          id: 0,
          parentId: null,//上级供应商ID
          supCode: '',//供应商编码
          supName: '',//供应商名称
          supGrade: 1,//供应商级别
          supType: null,//供应商类别
          supInitial: '',//供应商首字母
          supShortName: '',//供应商简称
          supEngName: '',//供应商英文名称
          supStatus: '0',//供应商是否有效
          provinceCode: '',//省
          cityCode: '',//市
          countyCode: '',//区
          comAddress: '',//公司地址
          emailCode: '',//邮编
          comPhoneNumber: '',//公司电话
          comUrl: '',//公司网址
          createTime: '',
          updateTime: '',
          supIntroduce: '',//供应商介绍
          logo: '',
          createUser: '',
          updateUser: '',
        }
      }
    })
    // 存储搜索组件传过来的数据
    const searchfFilters = ref({})
    //搜索
    const handleSearch = function (filters) {
      if (filters) {
        searchfFilters.value = filters
      }
      state.time = new Date().getTime()
      state.query.data.supCode = filters.supCode
      state.query.data.id = filters.id
      state.query.data.supGrade = filters.supGrade
      state.query.data.supType = filters.supType
      let uri = getRequestURI('supplier_querySupplier')
      request(uri, state.query).then(res => {
        state.dataSourceList = res.result
        expandIcon()
      })
    }
    //页面挂载时调用
    onMounted(() => {
      handleSearch({})
    })
    const handleTableChange = (page) =>{
      state.pagination = page
    }
    const getEditChange = (searchfFilters = {},num = '') =>{
      state.pagination.current = num
      handleSearch(searchfFilters)
    }
    //删除按钮
    const deleteOne = (record, index) => {
      state.del = index
      state.deleteQuery = record
      let data = state.deleteQuery
      // visible.value = true
      Modal.confirm({
        title: '确认删除吗？',
        async onOk () {
          let uri = getRequestURI('supplier_delete')
          request(uri, { data }).then(
            (result) => {
              if (result.code == 1) {
                message['success']('删除成功')
                handleSearch({})
              } else {
                message['warning'](result.message)
              }
            }
          )
        },
        onCancel () {
        }
      })
    }
    const expandIcon = function () {
      state.dataSourceList.forEach((a) => {

        return a
      })

    }
    //新增
    const addUsers = function (status) {
      state.status = status
      userDrawerRef.value.showDrawer(status)
    }
    //添加子公司
    const addUser = function (record, status) {
      console.debug(status)
      state.status = status

      userDrawerRef.value.showDrawer(record, status)
    }
    //编辑
    const upUser = function (record, status) {
      state.status = status
      const uri = getRequestURI('supplier_queryOne')
      request(uri, { data: record }).then(res => {
        res.result.supGrade = res.result.supGrade + ''
        res.result.supType = res.result.supType + ''
        userDrawerRef.value.showDrawer(res.result, status)
      })
    }
    //查看
    const selUser = function (record, status) {
      state.status = status
      const uri = getRequestURI('supplier_queryOne')
      request(uri, { data: record }).then(res => {
        res.result.supGrade = res.result.supGrade + ''
        res.result.supType = res.result.supType + ''
        userDrawerRef.value.showDrawer(res.result, status)
      })
    }
    //环境供应商同步
    const huanJing = function () {
      hjTongBu.value.showDrawerOne()
    }
    //外部环境供应商同步
    const waiBu = function () {
      wbTongBu.value.showDrawerTwo()
    }
    return {
      getEditChange,
      userDrawerRef,
      addUser,
      addUsers,
      upUser,
      selUser,
      ...toRefs(state),
      handleSearch,
      deleteOne,
      hjTongBu,
      huanJing,
      waiBu,
      wbTongBu,
      expandIcon,
      searchfFilters,
      handleTableChange
    }
  },
})
</script>
<style scoped>
</style>
