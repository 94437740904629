<template>
<a-drawer
    :title="tabTitle"
    :width="'65%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
  <a-form
    ref="formRef"
    :model="formData"
    :rules="formRules"
    v-bind="formItemLayout"
    layout="vertical"
    autocomplete="off"
  >
    <a-row :gutter="10">
      <a-col span="12">
        <a-form-item label="协议名称:" name="agreementName">
          <a-input v-model:value="formData.agreementName" :disabled="isDisable" clearable placeholder="请输入协议名称"/>
        </a-form-item>
      </a-col>
      <a-col span="12">
        <a-form-item label="协议编号:" name="agreementCode">
          <a-input v-model:value="formData.agreementCode" :disabled="isDisable" clearable placeholder="请输入协议编号"/>
        </a-form-item>
      </a-col>
      <a-col span="12">
        <a-form-item label="协议类型:" name="agreementType">
          <a-select v-model:value="formData.agreementType" :disabled="isDisable" :options="agreementTypeList"
                    clearable placeholder="请选择协议类型">
          </a-select>
        </a-form-item>
      </a-col>
      <a-col span="12">
        <a-form-item label="渠道名称:" name="channelName">
          <a-select :options="channelNameList" v-model:value="formData.channelName" :disabled="isDisable" clearable placeholder="请输入渠道名称">
          </a-select>
        </a-form-item>
      </a-col>
      <a-col span="12">
        <a-form-item label="签署日期:" name="signedDate">
          <a-date-picker
            mode="date"
            v-model:value="formData.signedDate"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            :disabled="isDisable"
            clearable
          ></a-date-picker>
        </a-form-item>
      </a-col>
      <a-col span="12">
        <a-form-item label="生效日期:" name="effectiveDate">
          <a-date-picker
            mode="date"
            :disabled="isDisable"
            v-model:value="formData.effectiveDate"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            clearable
          ></a-date-picker>
        </a-form-item>
      </a-col>
      <a-col span="12">
        <a-form-item label="状态:" name="state">
          <a-select
            v-model:value="formData.state"
            :disabled="isDisable"
            :options="statusList"
            clearable
            placeholder="请选择状态"
          >
          </a-select>
        </a-form-item>
      </a-col>
      <a-col span="12">
        <a-form-item label="失效日期:" name="invalidDate">
          <a-date-picker
            mode="date"
            :disabled="isDisable"
            v-model:value="formData.invalidDate"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            clearable
          ></a-date-picker>
        </a-form-item>
      </a-col>
      <a-col span="12">
        <a-form-item label="中介公司负责人:" name="brokerageAgencyPeople"
                      :rules="[{max: 20, message: '输入长度不能超过20' ,trigger: 'change'}]">
          <a-input v-model:value="formData.brokerageAgencyPeople" :disabled="isDisable" placeholder="请输入中介公司负责人"/>
        </a-form-item>
      </a-col>
      <a-col span="12">
        <a-form-item label="渠道负责人:" name="channelPeople"
                      :rules="[{max: 20, message: '输入长度不能超过20' ,trigger: 'change'}]">
          <a-input v-model:value="formData.channelPeople" :disabled="isDisable" placeholder="请输入渠道负责人"/>
        </a-form-item>
      </a-col>
      <a-col span="12">
        <a-form-item label="渠道联系电话:" name="channelPhone"
                      :rules="[{ pattern:/^(\d{8}|\d{11}|\d{16})$/, message: '只能输入8位电话或11位手机或16位公司号码' ,trigger: 'blur'}]">
          <a-input v-model:value="formData.channelPhone" :disabled="isDisable" placeholder="请输入渠道联系电话"/>
        </a-form-item>
      </a-col>
      <a-col span="12">
        <a-form-item label="渠道联系邮箱:" name="channelEmail"
                      :rules="[{max: 50, message: '输入长度不能超过50' ,trigger: 'change'},
                      { pattern:/^[0-9a-zA-Z]+([.-_]*[0-9a-zA-Z]+)*@([0-9a-zA-Z]+[-_]*[0-9a-zA-Z]+.)+[0-9a-zA-Z]{2,6}$/, message: '请输入正确的邮箱' ,trigger: 'blur'}
                      ]">
          <a-input v-model:value="formData.channelEmail" :disabled="isDisable" placeholder="请输入渠道联系邮箱"/>
        </a-form-item>
      </a-col>
      <a-col span="12">
        <a-form-item label="渠道联系地址:" name="channelAddress"
                      :rules="[{max: 50, message: '输入长度不能超过50' ,trigger: 'change'}]">
          <a-input v-model:value="formData.channelAddress" :disabled="isDisable" placeholder="请输入渠道联系地址"/>
        </a-form-item>
      </a-col>
      <a-col span="18">
        <a-form-item label="备注:" name="remarks" :rules="[{max: 200, message: '输入长度不能超过200' ,trigger: 'change'}]">
          <a-textarea v-model:value="formData.remarks" :rows="4" :disabled="isDisable" placeholder="请输入渠道联系地址"/>
        </a-form-item>
      </a-col>
      <a-col span="8">
        <a-form-item label="上传协议:" name="uploadAgreement">
          <upload-file filetype="pdf" :disabled="isDisable" :key="time" :showImg="showImg" num="1" @fileChange="uploadCosFileChange"></upload-file>
        </a-form-item>
      </a-col>
    </a-row>

  </a-form>

  <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          取消
        </a-button>
        <a-button html-type="submit" type="primary" @click="handleSave" v-if="tabTitle !== '查看渠道协议'">
          <template #icon>
            <FileAddOutlined/>
          </template>
          保存
        </a-button>
      </a-space>
    </template>
</a-drawer>
</template>

<script>
import { onBeforeMount, reactive, ref, toRefs } from 'vue'
import uploadFile  from '../../../components/extended/uploadFileNew'
import {getDictionaryData} from '../../../utils/request_extend'
import { message } from 'ant-design-vue'
import { getRequestURI, request } from '../../../utils/request'
export default {
  components: {
    uploadFile
  },
  props: {
    channelNameList:{
      type: Array,
      default: () => []
    }
  },
  setup(props, {emit}){
    const state = reactive({
      tabTitle: '新增渠道协议',
      visible: false,
      isDisable: false,
      time: null,
      showImg: [],
      channelNameList: []
    })
    const formData = reactive({
      id: '',
      agreementName: '',
      agreementCode: '',
      agreementType: undefined,
      channelName: undefined,
      signedDate: '',
      state: undefined,
      effectiveDate: '',
      invalidDate: '',
      brokerageAgencyPeople: '',
      channelPeople: '',
      channelPhone: '',
      channelEmail: '',
      channelAddress: '',
      remarks: '',
      uploadAgreement: ''
    })
    const formRef = ref()
    // 校验规则
    const formRules = reactive({
      agreementName: [
        {required: true,message: '请输入协议名称',}
      ],
      agreementCode: [
        {required: true,message: '请输入协议编号'},
        {pattern:/^[a-zA-Z0-9]*$/, message: '只支持字母、数字格式' ,trigger: 'change'}
      ],
      agreementType: [
        {required: true,message: '请选择协议类型'}
      ],
      channelName: [
        {required: true,message: '请选择渠道名称'}
      ],
      signedDate: [
        {required: true,message: '请选择签署日期'}
      ],
      state: [
        {required: true,message: '请选择状态'}
      ],
      effectiveDate: [
        {required: true,message: '请选择生效日期'}
      ],
      invalidDate: [
        {required: true,message: '请选择失效日期'}
      ],
      // uploadAgreement: [
      //   {required: true,message: '请上传PDF格式的文件'}
      // ],
    })
    const formItemLayout = {
      labelCol: {
        span: 10,
      },
      wrapperCol: {
        span: 24,
      },
    }
    const showDrawer = (status = '', oldData = {}) => {
      state.status = status
      switch(status){
        case 'add':
          state.tabTitle = '新增渠道协议'
          state.showImg.splice(0)
        break
        case 'see':
        case 'update':
          status === 'see' ? state.tabTitle = '查看渠道协议' : state.tabTitle = '修改渠道协议'
          // 上传协议
          if(oldData.uploadAgreement!==''){
            let obj = {}
            obj.url = oldData.uploadAgreement
            state.showImg.push(obj)
          }
          Object.keys(formData).forEach(item =>{
            formData[item] = oldData[item] || formData[item]
          })

          formData['agreementType'] +=''
          formData['state'] = oldData['state'].toString()
          formData['channelName'] = oldData['channelId']
        break
      }
      status === 'see' ? state.isDisable = true : state.isDisable = false
      state.visible = true
    }
    const handleSave = async () =>{
      await formRef.value.validate()
      if (Date.parse(formData.effectiveDate) < Date.parse(formData.signedDate)) {
        message.warning('签署日期不能大于生效日期')
        return
      }
      if (Date.parse(formData.effectiveDate) > Date.parse(formData.invalidDate)) {
        message.warning('失效日期不能小于生效日期')
        return
      }
      // 新增
      let url = state.status === 'add' ?  getRequestURI('productChannelAgreement_insert') : getRequestURI('productChannelAgreement_update')
      request(url, {data: {...formData, channelId: formData.channelName}}).then(res =>{
        console.log(res)
        if(res.code === 1){
          message.success(res.message)
          onClose()
          emit('handleSearch')
        }else{
          message.warning(res.message)
        }
      })
    }
    // 上传协议
    const uploadCosFileChange=(data)=>{
      formData.uploadAgreement=data.url
    }


    const onClose = () =>{
      // formRef.value.resetFields()
      formRef.value.clearValidate()
      Object.assign(formData, {
        id: '',
        agreementName: '',
        agreementCode: '',
        agreementType: undefined,
        channelName: undefined,
        signedDate: '',
        state: undefined,
        effectiveDate: '',
        invalidDate: '',
        brokerageAgencyPeople: '',
        channelPeople: '',
        channelPhone: '',
        channelEmail: '',
        channelAddress: '',
        remarks: '',
        uploadAgreement: ''
      })
      state.time = new Date().getTime()
      state.showImg.splice(0)
      state.visible = false
    }
    const agreementTypeList = ref([])
    const statusList = ref([])
    onBeforeMount(()=>{
      getDictionaryData(agreementTypeList, 'agreementType')
      getDictionaryData(statusList, 'status')
    })
    return{
      agreementTypeList,
      statusList,
      formData,
      formRef,
      ...toRefs(state),
      formItemLayout,
      formRules,
      showDrawer,
      onClose,
      handleSave,
      uploadCosFileChange

    }
  }
}
</script>

<style scoped>

</style>
