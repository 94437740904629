<style scoped>
  .djsc {
    width: 128px;
    height: 128px;
  }
</style>

<template>
  <a-drawer
    :title="isTitle"
    :width="'70%'"
    :visible="visible"
    @close="onClose"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }">
    <a-form
      :model="formData"
      layout="vertical"
      ref="formRef"
      autocomplete="off"
    >
      <a-row :gutter="10">
        <a-col span="12">
            <a-form-item
              :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]"
              label="供应商名称:" name="supId">
              <a-tree-select v-model:value="formData.supId"
                             :tree-data="supplierList"
                             :filterTreeNode="filterTreeOption"
                             show-search
                             tree-default-expand-all
                             @change="changeSupplier"
                             placeholder="请选择供应商名称"
                             :disabled="isDisable"
                             :field-names="{children:'children', label:'supName', key:'supCode',value:'id' }"
              >
              </a-tree-select>
            </a-form-item>
        </a-col>
        <a-col span="12">
            <a-form-item
              :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]"
              label="供应商编码:" name="supCode">
<!--              <div>{{formData.supCode}}</div>-->
              <a-input v-model:value="formData.supCode" :disabled="true" placeholder="请输入供应商编码"/>
            </a-form-item>
        </a-col>
        <a-col span="12">
<!--          { pattern:/^[a-zA-Z0-9_\u4e00-\u9fa5]+$/, message: '只能输入汉字、字母、数字、下划线' ,trigger: 'change'}-->
          <a-form-item
            :rules="[{ required: true, message: '必填信息' ,trigger: 'change'},
            { max: 100, message: '只能输入100字符' ,trigger: 'change'},
            ]"
            label="模板名称:" name="templateName">
            <a-input v-model:value="formData.templateName" :disabled="isDisable" placeholder="请输入模板名称"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item
            :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]"
            label="模板类型:" name="templateType">
            <a-select v-model:value="formData.templateType" placeholder="请选择模板类型" :options="tempTypeList"
                      :disabled="isDisable"></a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item
            label="适用执业区域编码:" name="areaSignList">
            <a-tree-select
              multiple
              v-model:value="formData.areaSignList"
              style="width: 100%"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              :tree-data="treeDataList"
              placeholder="请选择适用执业区域编码"
              :disabled="isDisable"
              ></a-tree-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="模板编号:" name="templateCode" :rules="[{required: true, message: '必填信息' ,trigger: 'change'},{ pattern:/^[0-9a-zA-Z_]{1,}$/, message: '模板编码只支持数字、字母和下划线' ,trigger: 'change'}]">
            <a-input v-model:value="formData.templateCode"
                     :disabled="isDisable"></a-input>
          </a-form-item>
        </a-col> <a-col span="12">
          <a-form-item label="附件类型:" name="templateFileType" :rules="[{required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-select v-model:value="formData.templateFileType" :options="attachFileTypeList" show-search allow-clear
                      :filter-option="supplierFilterOption"
                      :disabled="isDisable"
                     ></a-select>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item
            label="模板上传:" name="templateFileUrl"
            :rules="[{required: formData.templateType === '1' ? true : false, message: '请上传模板' ,trigger: 'change'}]">
            <upload-file filetype="pdf"
                         :disabled="isDisable"
                         :key="time"
                         :showImg="showUploadFile"
                         num="1"
                         @fileChange="uploadCosFileChange">
            </upload-file>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item label="关键字:" name="templateFileKeyWord" :rules="[{required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-textarea v-model:value="formData.templateFileKeyWord" :disabled="isDisable" :rows="4"/>
            <p>
              关键字结构: '分类|关键字|坐标x|坐标y'组成<br/>
              多个关键字以\n换行<br/>
              坐标x:其值代表相对关键字最后一个字向右偏多少个字宽<br/>
              坐标y:其值代表相对关键字最后一个字向下偏多少个字宽
            </p>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item label="备注:" name="templateDesc">
            <a-textarea v-model:value="formData.templateDesc" :disabled="isDisable" :rows="4"/>
          </a-form-item>
        </a-col>
      </a-row>

    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button html-type="submit" type="primary" @click="onSave()" v-if="!isDisable">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script>
import {SaveOutlined} from '@ant-design/icons-vue'
import {computed, defineComponent, onMounted, reactive, ref, toRefs} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import {getDictionaryData} from '../../../utils/request_extend'
import uploadFile from '../../../components/extended/uploadFileNew'
import {message} from 'ant-design-vue'

export default defineComponent({
  components: {
    SaveOutlined,
    uploadFile
  },
  props: {
    status: {
      type: String,
      required: true,
      default() {
        return '123'
        }
      },
    },
    emits:['select'],
    setup(props, {expose, emit}) {
      const supplierList = ref()
      const tempTypeList = ref()
      const treeDataList = ref()
      const areaSignArray = ref()
      const attachFileTypeList = ref()
      const fileList = ref() // 文件上传
      const formRef = ref()
      const state = reactive({
        visible: false,
        showUploadFile: [],
        time: null,
      })
      const formData = reactive({
        id: '',
        supId: '', //供应商
        supCode: '', // 供应商编码
        templateName: '', // 模板名称
        templateType: '', // 模板类型
        areaSign: '',
        areaSignList: [], // 适用执业区域编码
        templateCode: '', // 模板编号(待定)
        templateFileType: '', // 附件文件类型
        templateFileUrl: '', // 模板文件地址
        templateFileKeyWord: '', // 关键字
        templateDesc: '', // 备注


      })

      const isDisable = computed(() => {
        if (props.status === 'select') {
          return true
        } else {
          return false
        }
      })
         const isTitle = computed(() => {
        if (props.status === 'select') {
          return '查看'
        } else if (props.status === 'update') {
          return '编辑'
        } else {
          return '添加'
        }
      })
      const supplierFilterOption = (input, option) => {
        return option.label.indexOf(input) > -1
      }
      const changeSupplier =(params) => {
        formData.supCode = params
      }
      const gysList = () => {
        let uri = getRequestURI('supplier_querySupplier')
        request(uri, {}).then(res => {
          if(res.code === 1 && res.result.length>0) {
            supplierList.value = res.result
          }
        })
      }
      const getAreaList = () => {
        request(getRequestURI('moduleConfig_areaList'),
          {}).then(res => {
          if(res.code === 1){
            const msg  = res.result.map(item=> {
              return {
                ...item,
                title: item.name,
                value: item.id + ''
              }
            })
            treeDataList.value   = treeData(msg, 'id', 'parentId', 'children' )
          }
        })
      }

      // 还原树形结构
      const treeData =(data, id, parentId, childName) => {
        let cloneData = JSON.parse(JSON.stringify(data))
        return cloneData.filter((father) => {
          let  newArr =[]
          if(father[parentId]=== 0) {
            newArr = cloneData.filter((child) => {
              return  father[id] === child[parentId]
            })
          }
          father[childName] = newArr
          return  father[parentId]=== 0
        })
      }



      // 上传文件
      const uploadCosFileChange=(data)=>{
        formData.templateFileUrl=data.url
      }
      const showDrawer = (oldData = {}, params) => {
        state.visible = true
        state.showUploadFile.splice(0)
        if(params) {
           if(oldData.templateFileUrl!==''){
            let obj = {}
            obj.url = oldData.templateFileUrl
            state.showUploadFile.push(obj)
          }
         const list =  oldData.areaSignList.map(item => item.code)

          Object.keys(formData).forEach((key) => {
            formData[key] = oldData[key] || formData[key]
          })
          formData.areaSignList = list
        }
      }
      const filterTreeOption = function (input, option) {
        return option.supName.includes(input)
      }
      const onSave = async () => {
       await formRef.value.validate()
        if(formData.areaSignList.length===0) {
          formData.areaSign = (areaSignArray.value.find((item)=> item.value == '0')).value
        }else {
          formData.areaSign = '1'
        }
        let obj = {
          data: formData
        }
        if (props.status === 'update') {
          const uri = getRequestURI('moduleConfig_update')
          request(uri, obj).then(res => {
            if (res.code === 1) {
              message['success'](res.message)
              onClose()
              emit('select')
            } else {
              message['error'](res.message)
            }
          })
        } else {
        const uri = getRequestURI('moduleConfig_insert')
        console.log(uri)
        request(uri, obj).then(res => {
          console.debug(res)
          if (res.code === 1) {
            emit('select')
            message['success'](res.message)
            onClose()
          } else {
            message['error'](res.message)
          }
        })
        }
      }

      const onClose = () => {
        formRef.value.clearValidate()
        state.showUploadFile.splice(0)
        state.time = new Date().getTime()
        Object.assign(formData, {
          id: '',
          supId: '', //供应商
          templateName: '', // 模板名称
          templateType: '', // 模板类型
          areaSign: '',
          areaSignList: [], // 适用执业区域编码
          templateCode: '', // 模板编号(待定)
          templateFileType: '', // 附件文件类型
          templateFileUrl: '', // 模板文件地址
          templateFileKeyWord: '', // 关键字
          templateDesc: '', // 备注
          supCode:''
        })
        state.visible = false
      }
      onMounted(() => {
        gysList()
        getAreaList()
        getDictionaryData(tempTypeList, 'signTemplateType')
        getDictionaryData(attachFileTypeList, 'AttachmentType')
        getDictionaryData(areaSignArray, 'areaSign')
      })
      expose({
        showDrawer
      })
      return {
        ...toRefs(state),
        formData,
        isDisable,
        onClose,
        onSave,
        supplierList, // 供应商
        filterTreeOption,
        supplierFilterOption,
        changeSupplier,
        tempTypeList,
        fileList,
        uploadFile,
        treeDataList,
        isTitle,
        uploadCosFileChange,
        attachFileTypeList,
        formRef
      }
    }
  })
</script>
