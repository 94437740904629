<style scoped>
  .djsc {
    width: 128px;
    height: 128px;
  }
</style>

<template>
  <a-drawer
    :title="isTitle"
    :width="'70%'"
    :visible="visible"
    @close="onClose"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }">
    <a-form
      :model="formData"
      layout="vertical"
      ref="formRef"
      autocomplete="off"
    >
      <a-row :gutter="10">
        <a-col span="12">
          <a-form-item
            :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]"
            label="险种名称:" name="riskId">
            <a-select v-model:value="formData.riskId"  placeholder="请选择险种名称"
                      show-search @change="handleProductRisk"
                      :filter-option="riskFilterOption" :options="riskList"
                      :disabled="isDisable" ></a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item
            label="险种编号:" name="riskCode" :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">

<!--            {{formData.riskCode}}-->
            <a-input
              v-model:value="formData.riskCode"
              placeholder="请输入险种编号"
              :disabled="true"
            />
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item
            label="选择模板:" name="signTemplateId" :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]" >
            <a-select v-model:value="formData.signTemplateId"  placeholder="请选择选择模板"
                      :options="signTempList" :filter-option="supplierFilterOption"
                      :disabled="isDisable" @focus="focusTemp" @change="changeTemplate"></a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="模板编号:" name="templateCode" :rules="[{required: true, message: '必填信息' ,trigger: 'change'}]">
<!--            <div>{{formData.templateCode}}</div>-->
            <a-input
              v-model:value="formData.templateCode"
              placeholder="请输入模板编号"
              :disabled="true"
            />
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item
            label="是否允许重签:" name="signCover">
            <a-select v-model:value="formData.signCover"
                      :disabled="isDisable"
            >
             <a-select-option value="Y">是</a-select-option>
             <a-select-option value="N">否</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item
            label="是否允许合成:" name="signComposeSource">
            <a-select v-model:value="formData.signComposeSource"
                      :disabled="isDisable"
            >
              <a-select-option value="1">易高</a-select-option>
              <a-select-option value="0">系统</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="签名类型:" name="signUserType" :rules="[{required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-checkbox-group v-model:value="formData.signUserType" :disabled="isDisable">
              <a-checkbox :value="item.value" v-for="item in signUserTypeList" :key="item.value"
              >{{item.label}}</a-checkbox>
            </a-checkbox-group>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item label="备注:" name="templateDesc">
            <a-textarea v-model:value="formData.templateDesc" :disabled="isDisable" :rows="4"/>
          </a-form-item>
        </a-col>
      </a-row>

    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button html-type="submit" type="primary" @click="onSave()" v-if="!isDisable">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script>
  import {SaveOutlined} from '@ant-design/icons-vue'
  import {defineComponent, reactive, toRefs, computed, onMounted, ref} from 'vue'
  import {getRequestURI, request} from '../../../utils/request'
  import {getDictionaryData} from '../../../utils/request_extend'
  import {message} from 'ant-design-vue'
 // import {message} from 'ant-design-vue'
  export default defineComponent({
    components: {
      SaveOutlined,
    },
    props: {
      status: {
        type: String,
        required: true,
        default() {
          return '123'
        }
      },
    },
    emits:['select'],
    setup(props, {expose, emit}) {

      const riskList = ref()
      const signTempList = ref()
      const signUserTypeList = ref()
      const formRef = ref()
      const state = reactive({
        visible: false,
      })
      const formData = reactive({
        id: '',
        riskId: '', //险种名称
        riskCode: '', // 险种编号
        signTemplateId: '', // 选择模板
        templateCode: '', // 模板编号
        signUserType: [], // 签名类型
        signCover: '', // 是否允许重签
        signComposeSource: '', // 是否允许合成
        templateDesc: '', // 备注
      })

      const isDisable = computed(() => {
        if (props.status === 'select') {
          return true
        } else {
          return false
        }
      })
      const isTitle = computed(() => {
        if (props.status === 'select') {
          return '查看'
        } else if (props.status === 'update') {
          return '编辑'
        } else {
          return '添加'
        }
      })
      // 险种筛选
      const riskFilterOption = (input, option) => {
        return option.label.indexOf(input) > -1
      }
     // 模板筛选
      const supplierFilterOption = (input, option) => {
        return option.label.indexOf(input) > -1
      }
      // 获得险种信息列表
      const getProductRisk = () => {
        let uri = getRequestURI('sign_riskInfo')
        request(uri, {data:{}}).then(res => {
          if(res.code === 1 && res.result.length>0) {
            riskList.value = res.result.map(item => {
              return {
                ...item,
                label: item.riskName,
                value: item.id
              }
            })
          }
        })
      }

      const handleProductRisk = (value) => {
        if(riskList.value.length>0) {
          signTempList.value = undefined
          formData.riskCode = '' // 险种编号
          formData.signTemplateId='' // 选择模板
          formData.templateCode='' // 模板编号
          const  riskCodeObj =  riskList.value.find(item => {return item.id === value})
          formData.riskCode = riskCodeObj.riskCode
          getTempType(value)
        }
      }

      const getTempType = (params) => {
        console.log('执行方法', )
        let uri = getRequestURI('sign_temp')
        request(uri, {data:{riskId: params}}).then(res => {
          console.log('模板配置列表', res)
          if(res.code === 1 && res.result.length>0) {
            // signTemplateId
            signTempList.value = res.result.map(item => {
              return {
                ...item,
                label: item.templateName,
                value: item.id
              }
            })
          }

        })
      }
      const focusTemp = () => {
        if(!formData.riskId) {
          message['success']('请优先选择险种名称')
        } else if(!signTempList.value) {
          message['success']('请先配置险种对应的模板')
        }
      }
      const changeTemplate = (value) => {
        if(riskList.value.length>0) {
          const  tempCodeObj =  signTempList.value.find(item => {return item.id === value})

          formData.templateCode = tempCodeObj.templateCode
        }
      }

      const showDrawer =async (oldData = {}, params) => {
        state.visible = true
        if(params) {
           await getTempType(oldData.riskId)
          Object.keys(formData).forEach((key) => {
            formData[key] = oldData[key] || formData[key]
          })
        }
      }
      const onSave = async () => {
        await formRef.value.validate()
        let obj = {
          data: formData
        }
        console.log('props',  props.status)
        if (props.status === 'update') {
          const uri = getRequestURI('sign_update')
          request(uri, obj).then(res => {
            if (res.code === 1) {
              message['success'](res.message)
              onClose()
              emit('select')
            } else {
              message['error'](res.message)
            }
          })
        } else {
          const uri = getRequestURI('sign_insert')
          console.log(uri)
          request(uri, obj).then(res => {
            console.debug(res)
            if (res.code === 1) {
              emit('select')
              message['success'](res.message)
              onClose()
            } else {
              message['error'](res.message)
            }
          })
        }
      }

      const onClose = () => {
        formRef.value.clearValidate()
        Object.assign(formData, {
          id: '',
          riskId: '', //险种名称
          riskCode: '', // 险种编号
          signTemplateId: '', // 选择模板
          templateCode: '', // 模板编号
          signUserType: [], // 签名类型
          signCover: '', // 是否允许重签
          signComposeSource: '', // 是否允许合成
          templateDesc: '', // 备注
        })
        state.visible = false
      }

      onMounted(() => {
        getProductRisk()
        getDictionaryData(signUserTypeList, 'signUserType')

      })
      expose({
        showDrawer
      })
      return {
        ...toRefs(state),
        formData,
        isDisable,
        onClose,
        onSave,
        riskList, // 险种列表,
        supplierFilterOption,
        riskFilterOption,
        signUserTypeList,
        isTitle,
        getTempType,
        handleProductRisk,
        signTempList,
        changeTemplate,
        focusTemp,
        formRef
      }
    }
  })
</script>
