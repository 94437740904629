<template>
<div>
  {{ tipMsg }}</div>
</template>

<script>
import {onMounted, reactive, toRefs} from 'vue'
import {useRouter} from 'vue-router'
import {request} from '../../utils/request'
import {getExtendUri} from '../../utils/request_extend'
import {getUrlParams} from '../../utils/utlis'

export default {
  name: 'channelProductDetail',
  setup(){
    const router = useRouter()
    const state = reactive({
      channel: '',
      channelUserId: '',
      productCode: '',
      agentId: '',
      tipMsg: ''
    })
    /**
     * 获取url参数
     */
    const getUrlate = () => {
      state.channel = router.currentRoute.value.query['channel'] || undefined
      state.channelUserId = router.currentRoute.value.query['channelUserId'] || undefined
      state.productCode = router.currentRoute.value.query['productCode'] || undefined
      state.agentId = router.currentRoute.value.query['agentId'] || undefined
      createProductLink()
    }
    /**
     * 创建链接
     */
    const createProductLink = () => {
      // 文浩让先把代理人，渠道id这两个值写死
      let param = {
        agentId: state.agentId,
        channel: state.channel,
        channelUserId: state.channelUserId,
        productCode: state.productCode,
        agentCode: '1234567890123'
      }
      if(router.currentRoute.value.query.typeCode || router.currentRoute.value.query.typeCode === '0'){
        param.typeCode = router.currentRoute.value.query.typeCode
      }
        request(getExtendUri('createProductLink'), {
          data: param
        }).then(res=>{
          if (res.code === 1 && res.result) {
            goToProductDetail(res.result)
          } else {
            state.tipMsg = res.message
            // console.warn(res.message)
          }
        }).catch(err => {
          console.error(err)
        })
    }
    const goToProductDetail = (res) => {
      const params = getUrlParams(res)
      router.push({
        path: '/app/detail',
        query: params
      })
    }
    onMounted(() => {
      getUrlate()
    })
    return {
      ...toRefs(state),
      goToProductDetail
    }
  }
}
</script>

<style scoped>

</style>
