<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
  ></search-form>
  <a-table
    :indentSize="30"
    :columns="columns"
    :data-source="dataSourceList"
    :row-key="(record) => record.id"
    :key="time"
  >
    <template #bodyCell="{ column, record}">
      <template v-if="column.key === 'operation'">
        <div>
          <a-button
            type="text"
            style="margin-right: 10px; color: orange"
            size="small"
            @click="selUser(record, 'details')"
          >查看
          </a-button>
          <a-button
            type="text"
            style="margin-right: 10px; color: orange"
            size="small"
            @click="upUser(record, 'edit')"
          >
            编辑
          </a-button>
        </div>
      </template>
    </template>
  </a-table>
  <edit-config ref="editDrawerRef" @select="handleSearch()" :status =status ></edit-config>

</template>

<script>
  import { defineComponent, onMounted, reactive, toRefs , ref} from 'vue'
  import editConfig from './editConfig'
  import SearchForm from '../../../components/extended/search_form'
  import {getRequestURI, request} from '../../../utils/request'

  export default defineComponent({
    components: {
      SearchForm,
      editConfig
    },
    setup () {
      const editDrawerRef = ref()
      const state = reactive(
        {
          time: null,
          del: '',
          status: '',
          deleteQuery: {},
          dataSourceList: [],
          condition: {
            id: {
              label: '供应商名称',
              type: 'tree-select',
              urlKey: 'querySupplier',
              // urlKey: 'supplierQuerySupLevelSt',
              value: 'id',
              default: '',
              data: []
            },
            supCode: '供应商编码',
            supGrade: {
              label: '供应商级别',
              type: 'select',
              urlKey: 'queryDictionaryType',
              queryParam: {type: 'supplierLevel'}
            },
            supType: {
              label: '供应商类别',
              type: 'select',
              urlKey: 'queryDictionaryType',
              queryParam: {type: 'supplierType'}
            }
          },
          columns: [
            {
              title: '供应商编码',
              dataIndex: 'supCode',
              key: 'supCode',
              align: 'left',
              fixed: 'left',
            },
            {
              title: '供应商名称',
              align: 'center',
              dataIndex: 'supName',
              key: 'supName'
            },
            {
              title: '级别',
              align: 'center',
              dataIndex: 'supGradeName',
              key: 'supGradeName'
            },
            {
              title: '类型',
              align: 'center',
              dataIndex: 'supTypeName',
              key: 'supTypeName'
            },
            {
              title: '操作',
              key: 'operation',
              ellipsis: true,
              align: 'center',
              width: 280,
              scopedSlots: { customRender: 'operation' }
            }
          ],
          query: {
            data: {
              id: 0,
              parentId: null,//上级供应商ID
              supCode: '',//供应商编码
              supName: '',//供应商名称
              supGrade: 1,//供应商级别
              supType: null,//供应商类别
            }
          }
        })

      const handleSearch = function (filters) {
        if(filters) {
          state.query.data = {
            ...filters
          }
        }
        let uri = getRequestURI('supplier_querySupplier')
        request(uri, state.query).then(res => {
          state.dataSourceList = res.result
        })
      }
      //编辑
      const upUser = function (record, status) {
        state.status = status
        console.log('状态',record)
        const uri = getRequestURI('query_supplier')
        request(uri, { data: {
          supId: record.id
          } }).then(res => {
          editDrawerRef.value.showDrawer(res.result, status, record.id)
        })
      }
      //查看
      const selUser = function (record, status) {
        state.status = status
        const uri = getRequestURI('query_supplier')
        request(uri, { data: {
            supId: record.id
          } }).then(res => {
          editDrawerRef.value.showDrawer(res.result, status,  record.id)
        })
      }
      //页面挂载时调用
      onMounted(() => {
        handleSearch({})
      })
      return {
        ...toRefs(state),
        handleSearch,
        upUser,
        selUser,
        editDrawerRef,
      }

    },
  })
</script>

<style scoped>

</style>
