<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @resetAll="reSetValue"
    @callback="handleSearch"
    ref="searchRef"
  ></search-form>
  <div class="btn-bar">
    <a-button danger @click="deleteUser1" v-if="!isDisable">
      <template #icon>
        <DeleteOutlined/>
      </template>
      批量删除
    </a-button>
    <a-button type="primary" @click="adduser('insert')" v-if="!isDisable">
      <template #icon>
        <FileAddOutlined/>
      </template>
      新增
    </a-button>
  </div>
  <div v-if="type == 2">
    <div v-if="status == 'select'">
      <a-table
        :columns="tableList"
        :data-source="dataSourceList"
        :scroll="{ x: 1500 }"
        :pagination="pagination"
        style="margin-bottom: 5px"
        :key="time"
        :row-selection="{
        selectedRowKeys: selected,
        onChange: onSelect,
        type: 'radio'
      }"
        :row-key="(record) => record.id"
        @change="handleTableChange"
      >
        <template #bodyCell="{ column, index, record }">
          <template v-if="column.key === 'index'">
            {{ index + 1 }}
          </template>
          <template v-if="column.key === 'operation'">
            <div>
              <a style="color: orange" @click="modifyUser(record, 'bianji')">编辑</a>&nbsp;&nbsp;
            </div>
          </template>
        </template>
      </a-table>
    </div>
    <div v-if="status !== 'select'">
      <a-table
        :columns="tableList"
        :data-source="dataSourceList"
        :scroll="{ x: 1500 }"
        :pagination="pagination"
        :key="time"
        style="margin-bottom: 5px"
        :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
        :row-key="(record) => record.id"
        @change="handleTableChange"
      >
        <template #bodyCell="{ column, index, record }">
          <template v-if="column.key === 'index'">
            {{ index + 1 }}
          </template>
          <template v-if="column.key === 'operation'">
            <div>
              <a style="color: orange" @click="modifyUser(record, 'bianji')">编辑</a>&nbsp;&nbsp;
            </div>
          </template>
        </template>
      </a-table>
    </div>
  </div>
  <div v-if="type == 1">
    <div v-if="status == 'select'">
      <a-table
        :columns="colmuns"
        :data-source="dataSourceList"
        :scroll="{ x: 700 }"
        :pagination="pagination"
        style="margin-bottom: 5px"
        :key="time"
        :row-selection="{
        selectedRowKeys: selected,
        onChange: onSelect,
        type: 'radio'
      }"
        :row-key="(record) => record.id"
        @change="handleTableChange"
      >
        <template #bodyCell="{ column, index, record }">
          <template v-if="column.key === 'index'">
            {{ index + 1 }}
          </template>
          <template v-if="column.key === 'operation'">
            <div>
              <a style="color: orange" @click="modifyUser(record, 'bianji')">编辑</a>&nbsp;&nbsp;
            </div>
          </template>
        </template>
      </a-table>
    </div>
    <div v-if="status !== 'select'">
      <a-table
        :columns="colmuns"
        :data-source="dataSourceList"
        :key="time"
        :scroll="{ x: 700 }"
        :pagination="pagination"
        style="margin-bottom: 5px"
        :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
        :row-key="(record) => record.id"
        @change="handleTableChange"
      >
        <template #bodyCell="{ column, index, record }">
          <template v-if="column.key === 'index'">
            {{ index + 1 }}
          </template>
          <template v-if="column.key === 'operation'">
            <div>
              <a style="color: orange" @click="modifyUser(record, 'bianji')">编辑</a>&nbsp;&nbsp;
            </div>
          </template>
        </template>
      </a-table>
    </div>
  </div>
  <a-input v-model="aaaa" v-show="false"></a-input>
  <editAgreementInsurance @select="select()" ref="userInsurance" :status="status" :code="code" :statuss="statuss"></editAgreementInsurance>
</template>

<script>
import editAgreementInsurance from './editAgreementInsurance'
import SearchForm from '../../../components/extended/search_form.vue'
import {computed, defineComponent, reactive, toRefs, ref} from 'vue'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request'
import {message, Modal} from 'ant-design-vue'
import {getDictionaryValue} from '../../../utils/request_extend'
export default defineComponent({
  components: {
    SearchForm,
    editAgreementInsurance
  },
  props: {
    status: {
      type: String,
      required: true,
      default() {
        return '123'
      }
    },
    idd: {
      type: String,
      required: true,
      default() {
        return '1'
      }
    },
    code: {
      type: String,
      required: true,
      default() {
        return '1'
      }
    }
  },
  setup(props, {expose}) {
    const userInsurance = ref()
    const state = reactive({
      time: '',
      dataSourceList: [],
      condition: {
        riskId: '险种/产品编码',
        riskName: '险种/产品名称',
        settlementTypeCode: {
          label: '结算类型',
          type: 'select1',
          urlKey:'queryDictionaryType',
          queryParam:{type:'settlementType'}

        }
      },
      type: 2,
      selected: [],
      query: {
        data: {
          createTime: '',
          createUser: '',
          delNumber: 0,
          effectDay: 0,
          giftRisk: '',
          giftRiskType: 0,
          id: 0,
          insuranceFineCode: '',
          insuranceTypeCode: '',
          premiumCalculation: 0,
          riskCode: '',
          riskCsSign: 0,
          riskGroupSign: 0,
          riskLengthSign: 0,
          riskName: undefined,
          riskNumber: 0,
          riskSign: 0,
          riskTypeFineCode: '',
          riskTypeLargeCode: '',
          supId: 0,
          supName: '',
          supRiskCode: '',
          updateTime: '',
          updateUser: '',
          waitDate: 0,
          wideDate: 0
        },
        pagination: {
          current: 1,
          pageSize: 10,
          total: 0
        },
        sorters: [
          {
            field: '',
            order: ''
          }
        ]
      },
      colmuns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left'
        },
        {
          title: '险种/产品名称',
          width: 100,
          fixed: 'left',
          key: 'riskName',
          dataIndex: 'riskName'
        },
        {
          title: '结算类型',
          width: 100,
          fixed: 'left',
          key: 'settlementTypeCode',
          dataIndex: 'settlementTypeCode',
          customRender: (text)=>getDictionaryValue('settlementType', text.value)

        },
        {
          title: '手续费（元)',
          width: 100,
          fixed: 'left',
          key: 'proceduresMoney',
          dataIndex: 'proceduresMoney'
        },
        {
          title: '生效日期',
          width: 100,
          fixed: 'left',
          key: 'effectiveDate',
          dataIndex: 'effectiveDate'
        },
        {
          title: '失效日期',
          width: 100,
          fixed: 'left',
          key: 'invalidDate',
          dataIndex: 'invalidDate'
        },
        {
          title: '操作',
          key: 'operation',
          fixed: 'right',
          width: 50
        }
      ],
      delQuery: [],
      tableList: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left'
        },
        {
          title: '险种/产品名称',
          width: 140,
          fixed: 'left',
          key: 'riskName',
          dataIndex: 'riskName'
        },
        {
          title: '结算类型',
          width: 140,
          key: 'settlementTypeCode',
          dataIndex: 'settlementTypeCode',
          customRender: (text)=>getDictionaryValue('settlementType', text.value)
        },
        {
          title: '交费方式',
          width: 140,
          key: 'payType',
          dataIndex: 'payType',
          customRender: (text)=>getDictionaryValue('apptype', text.value)
        },
        {
          title: '保险期间',
          width: 160,
          key: 'insurancePeriod',
          dataIndex: 'insurancePeriod',
          customRender: (text)=>getDictionaryValue('insurance_period', text.value)
        },
        {
          title: '缴费期间',
          width: 140,
          key: 'renewPeriod',
          dataIndex: 'renewPeriod',
          customRender: (text)=>getDictionaryValue('renew_period', text.value)
        },
        // {
        //   title: '保单年度',
        //   width: 130,
        //   key: 'policyYear',
        //   dataIndex: 'policyYear'
        // },
        // {
        //   title: '手续费比例',
        //   width: 130,
        //   key: 'renewRatio',
        //   dataIndex: 'renewRatio'
        // },
        {
          title: '生效日期',
          width: 120,
          key: 'effectiveDate',
          dataIndex: 'effectiveDate'
        },
        {
          title: '失效日期',
          width: 120,
          key: 'invalidDate',
          dataIndex: 'invalidDate'
        },
        {
          title: '操作',
          key: 'operation',
          fixed: 'right',
          width: 100
        }
      ],
      result: [],
      selectedRowKeys: [],
      jiaofeiqijian: [],
      statuss: ''
    })
    // 协议关联险种
    const formDataAccount = reactive({
      id: 0,
      agreementId: 0,
      payType: '',
      relationType: undefined,
      riskId: undefined,
      riskCode: '',
      riskName: '',
      settlementTypeCode: 2,
      proceduresMoney: 0,
      effectiveDate: '',
      invalidDate: '',
      insurancePeriod: '',
      renewPeriod: '',
      policyYear: 0,
      renewRatio: ''
    })
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total
    } = requestUsePagination(getRequestURI('productRelation_selectListForPageOne'))
    const isDisable = computed(() => {
      if (props.status == 'select') {
        return true
      } else {
        return false
      }
    })
    const del = function() {
      if (state.colmuns.length === 7) {
        state.colmuns.splice(6,1)
      } else {
        return
      }
      if (state.tableList.length === 11) {
        state.tableList.splice(10,1)
      } else {
        return
      }
    }
    const adduser = function(status) {
      state.statuss = status
      userInsurance.value.showDrawer()
    }
    const add = function() {
      if (state.colmuns.length === 6) {
        state.colmuns.push({
          title: '操作',
          key: 'operation',
          fixed: 'right',
          width: 50
        })
      } else {
        return
      }
      if (state.tableList.length  === 10) {
        state.tableList.push({
          title: '操作',
          key: 'operation',
          fixed: 'right',
          width: 100
        })
      } else {
        return
      }
    }
    const gunlianleibie = function(value) {
      if (value === 1) {
        listAccount.xianzhongmingcheng = []
        request(getRequestURI('selectRisk'), {
          data: {
            riskName:''
          }
        }, true, 'post').then(
          (result) => {
            const arr = []
            const add = []
            const ass = []
            const res = result.result
            console.debug(res)
            for (let i = 0; i < res.length; i++) {
              if(ass.includes(res[i].riskName) == false) {
                add.push({value: res[i].riskName ,label: res[i].riskName})
                ass.push(res[i].riskName)
              }
              arr.push({label: res[i].riskName, value: res[i].riskName})
            }
            console.debug(add)
            // state.condition.riskName.data = arr
            listAccount.xianzhongmingcheng = add
          }
        )
      } else {
        listAccount.xianzhongmingcheng = []
        request(getRequestURI('select'),{data: {}}).then(res => {
          console.debug(res.result)
          res.result.forEach(item => {
            let obj = {}
            obj.label = item.productName
            obj.value = item.id
            listAccount.xianzhongmingcheng.push(obj)
          })
          console.debug(listAccount.xianzhongmingcheng)
        })
      }
    }
    const changeAll = function () {
      formDataAccount.riskId = ''
      formDataAccount.riskName = ''
      formDataAccount.insurancePeriod = ''
      formDataAccount.renewPeriod = ''
      formDataAccount.policyYear = ''
      formDataAccount.renewRatio = ''
      formDataAccount.effectiveDate = ''
      formDataAccount.invalidDate = ''
      formDataAccount.payType = ''
      formDataAccount.proceduresMoney = ''
    }
    const deleteUser1 = function() {
      if (state.delQuery.length == 0) {
        message['warning']('请选择数据')
      } else {
        Modal.confirm({
          title: '确认删除吗?',
          async onOk() {
            // let keys = state.selectedRowKeys
            // if (keys.length === 0) {
            //   message.warning('请选择数据')
            //   return
            // }
            // visible.value = true
            const uri = getRequestURI('productRelation_delete')
            console.debug(state.delQuery)
            request(uri,{data: state.delQuery}).then(res => {
              console.debug(res)
              if(res.code == 1) {
                console.debug(res)
                message['success'](res.message)
              } else {
                message['error'](res.message)
              }
              state.delQuery.splice(0)
              select()
            })
          },
          onCancel() {
            state.delQuery.splice(0)
          }
        })
      }
    }
    const listAccount = reactive({
      guanlianleibie: [
        {
          label: '险种',
          value: 1
        },
        {
          label: '产品',
          value: 2
        }
      ],
      jiesuanleixing: [
        {
          label: '按保单件数',
          value: 1
        },
        {
          label: '按比例结算',
          value: 2
        }
      ],
      jiaofeifangshi: [],
      xianzhongmingcheng: [],
      baoxianqijian: [],
      jiaofeiqijian: []
    })
    const modifyUser = (record, statuss) => {
      state.statuss = statuss
      formDataAccount.riskCode = record.riskCode
      formDataAccount.riskName = record.riskName
      formDataAccount.settlementTypeCode = record.settlementTypeCode
      formDataAccount.insurancePeriod = record.insurancePeriod
      formDataAccount.renewPeriod = record.renewPeriod
      formDataAccount.policyYear = record.policyYear
      formDataAccount.renewRatio = record.renewRatio
      formDataAccount.effectiveDate = record.effectiveDate
      formDataAccount.invalidDate = record.invalidDate
      formDataAccount.proceduresMoney = record.proceduresMoney
      record.relationType =record.relationType + ''
      record.settlementTypeCode = record.settlementTypeCode + ''
      userInsurance.value.showDrawer(record)
    }
    const onSelect = function (changeId, changecontext) {
      state.statuss = 'select'
      state.selected = changeId
      changecontext.forEach(item => {
        formDataAccount.riskName = item.riskName
        formDataAccount.settlementTypeCode = item.settlementTypeCode
        formDataAccount.insurancePeriod = item.insurancePeriod
        formDataAccount.renewPeriod = item.renewPeriod
        formDataAccount.policyYear = item.policyYear
        formDataAccount.renewRatio = item.renewRatio
        formDataAccount.effectiveDate = item.effectiveDate
        formDataAccount.invalidDate = item.invalidDate
        formDataAccount.proceduresMoney = item.proceduresMoney
        formDataAccount.payType = item.payType
        formDataAccount.relationType = item.relationType
        formDataAccount.riskId = item.riskId
      })
      userInsurance.value.showDrawer(formDataAccount)
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value
      // pageSizeOptions: [10, 20, 50],
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.debug('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        // sorter,
        data: state.lastFilter
      }).then(res => {
        state.dataSourceList = res.result
      })

    }
    const handleSearch = function (filters) {
      state.lastFilter = filters
      // if (filters.settlementTypeCode !== 1 && filters.settlementTypeCode !== 2) {
      //   message['warning']('请选择结算类型')
      // } else {
        state.type = filters.settlementTypeCode
        state.lastFilter.agreementId = props.code
        select()
      // }
      console.debug('handleSearch', filters)
    }
    const select = function() {
      run({
        data: state.lastFilter,
        pagination: {
          current: current.value,
          pageSize: pageSize.value
        }
      }).then(res => {
        state.dataSourceList = res.result
      })
    }
    const  searchRef = ref()
    const onClose = () => {
      searchRef.value.deleteAll()
    }
    const reSetValue = () =>{
      state.dataSourceList = []
      pagination.value.total = 0
      formDataAccount.riskName = ''
      formDataAccount.insurancePeriod = ''
      formDataAccount.renewPeriod = ''
      formDataAccount.policyYear = ''
      formDataAccount.renewRatio = ''
      formDataAccount.effectiveDate = ''
      formDataAccount.invalidDate = ''
      formDataAccount.proceduresMoney = ''
      state.time = new Date().getTime()
    }
    const onSave = () => {
      formDataAccount.agreementId = props.code
      // formDataAccount.agreementCode = props.idd
      let obj = {
        data: formDataAccount
      }
      if (state.statuss == 'bianji') {
        formDataAccount.id = props.code
        const uri = getRequestURI('productRelation_update')
        request(uri, obj).then(res => {
          if (res.code == 1) {
            state.statuss = ''
            message['success'](res.message)
          } else {
            message['success'](res.message)
          }
          console.debug(res)
        })
      } else {
        const uri = getRequestURI('productRelation_insert')
        request(uri, obj).then(res => {
          if (res.code == 1) {
            message['success'](res.message)
          } else {
            message['success'](res.message)
          }
          console.debug(res)
        })
      }
    }
    const onSelectChange = (selectedRowKeys, selectedRow) => {
      state.delQuery.splice(0)
      console.debug('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
      state.delQuery = selectedRow
    }
    // eslint-disable-next-line vue/return-in-computed-property
    const aaaa = computed(() => {
      if (props.status === 'select') {
        del()
      } else {
        add()
      }
    })
    // 2

    expose({
      changeAll,
      onClose,
      onSave
    })
    return {
      ...toRefs(state),
      handleSearch,
      isDisable,
      aaaa,
      run,
      searchRef,
      dataSource,
      onSelectChange,
      deleteUser1,
      onClose,
      changeAll,
      onSave,
      handleTableChange,
      modifyUser,
      onSelect,
      formDataAccount,
      pagination,
      loading,
      current,
      pageSize,
      total,
      listAccount,
      gunlianleibie,
      userInsurance,
      adduser,
      select,
      reSetValue
    }
  }
})
</script>

<style scoped>

</style>
