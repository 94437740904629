<template>
  <search-form
    ref="searchRef"
    :Condition="condition"
    :ColSpan="12"
    @resetAll="resetAll"
    @callback="handleSearch"
  ></search-form>
  <div class="btn-bar">
    <a-button type="primary" @click="addModule()">
      <template #icon>
        <FileAddOutlined />
      </template>
      新增
    </a-button>
    <a-button danger @click="delModule('sel')">
      <template #icon>
        <DeleteOutlined />
      </template>
      批量删除
    </a-button>
  </div>
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :pagination="pagination"
    :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
    @change="handleTableChange"
    :row-key="(record) => record.id"
    :loading="loading"
  >

    <template #bodyCell="{ column, record, index }">
      <template v-if="column.key === 'index'">

        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'supProductCode'">
        <a
          href="javascript:void(0)"
          style="color: orange"
          @click="downModule(record)"
        >下载模板</a>
      </template>
      <template v-if="column.key === 'operation'">
        <div>
          <a
            href="javascript:void(0)"
            style="color: orange"
            @click="checkProduct(record, 'select')"
          >查看</a
          >&nbsp;&nbsp;
          <a
            href="javascript:void(0)"
            style="color: orange"
            @click="modifyProduct(record, 'update')"
          >编辑</a
          >
        </div>
      </template>
    </template>
  </a-table>
  <sign-drawer ref="signDrawerRef" @select="handleSearch()" :status=status></sign-drawer>
</template>

<script>
  import {defineComponent, reactive, toRefs, onMounted, ref, computed} from 'vue'
  import SearchForm from '../../../components/extended/search_form'
  import signDrawer from './signDrawer'
  import {getRequestURI, requestUsePagination, request} from '../../../utils/request'
  import {getDictionaryData, getDictionaryValue} from '../../../utils/request_extend'
  import {message, Modal} from 'ant-design-vue'
  export default defineComponent({

    components: {
      SearchForm,
      signDrawer

    },
    setup () {
      const signDrawerRef = ref()
      const tempTypeList = ref()
      const state = reactive({
        status: '',
        selectedRowKeys: [],

        condition: {
          supName: {
            label: '供应商名称',
            type: 'tree-select',
            // urlKey: 'supplierQuerySupLevelSt',
            urlKey: 'querySupplier',
            value: 'supName',
            default: '',
            data: []
          },
          riskName: '险种名称',
          supCode: '供应商编码',
          riskCode: '险种编码',
        },
        columns: [
          {
            title: '序号',
            key: 'index',
            fixed: 'left',
            width: 60,
            align: 'left',
          },
          {
            title: '险种编码',
            width: 100,
            align: 'center',
            key: 'riskCode',
            dataIndex: 'riskCode',
          },
          {
            title: '险种名称',
            width: 100,
            align: 'center',
            key: 'riskName',
            dataIndex: 'riskName',
          },
          {
            title: '供应商编码',
            width: 100,
            align: 'center',
            key: 'supRCode',
            dataIndex: 'supCode',
          },
          {
            title: '供应商名称',
            width: 100,
            align: 'center',
            key: 'supName',
            dataIndex: 'supName',
          },
          {
            title: '模板文件名',
            width: 100,
            align: 'center',
            key: 'productSupSignTemplateEntity',
            dataIndex: 'productSupSignTemplateEntity',
            customRender: (text)=> customRenderName(text.value)
          },
         {
            title: '模板类型',
            width: 100,
            align: 'center',
            key: 'productSupSignTemplateEntity',
            dataIndex: 'productSupSignTemplateEntity',
           customRender: (text)=> customRenderValue('signTemplateType', text.value),
          },
          {
            title: '下载',
            width: 100,
            align: 'center',
            key: 'supProductCode',
            dataIndex: 'supProductCode',
          },
          {
            title: '签名类型',
            width: 120,
            align: 'center',
            key: 'signUserType',
            dataIndex: 'signUserType',
            customRender: (text)=> getDictionaryValue('signUserType', text.value),
          },
          {
            title: '是否允许重签',
            width: 120,
            align: 'center',
            key: 'signCover',
            dataIndex: 'signCover',
            customRender: (text)=>getSignCover(text.value) ,
          },
          // // 0-合成 1-易高
          // {
          //   title: '是否允许合成',
          //   width: 120,
          //   align: 'center',
          //   key: 'signComposeSource',
          //   dataIndex: 'signComposeSource',
          //   customRender: (text)=>getSignCover(text.value) ,
          // },
          {
            title: '备注',
            width: 100,
            align: 'center',
            key: 'productType',
            dataIndex: 'productType',

          },
        /* {
            title: '更新时间',
            width: 100,
            align: 'center',
            key: 'updateTime',
            dataIndex: 'updateTime',
          },*/
          {
            title: '操作',
            key: 'operation',
            align: 'center',
            width: 120,
          },
        ],
      })

      const {
        data: dataSource,
        run,
        loading,
        current,
        pageSize,
        total,
      } = requestUsePagination(getRequestURI('sign_select'))



      const pagination = computed(() => ({
        current: current.value,
        pageSize: pageSize.value,
        total: total.value,
      }))
      const resetAll = () => {
      //  state.condition.productId.data = []
       // init()
      }
      const customRenderName = (type) => {
        if(!type) return
        if(type.templateName) {
          return type.templateName
        }
      }
      const customRenderValue = (dictionary,type) => {
        if(!type) return

       const typeValue = tempTypeList.value.find(item => item.value === type.templateType)
        return typeValue.label
      }
    // 是否重签
    const getSignCover = (params) => {
     const isVaue = params==='Y'? '是': '否'
     return isVaue
    }

      const addModule = () => {
        state.status = ''
         signDrawerRef.value.showDrawer()
      }
      // 删除 批量删除
      const delModule = (record) => {
        console.log('批量删除', record)
        let ids = []
        if (record !== 'sel') {
          ids.push(record.id)
        } else {
          console.log(ids)
          if (!state.selectedRowKeys.length) return message.warning('请选择数据')
          ids = [...state.selectedRowKeys]
        }

        Modal.confirm({
          title: '删除',
          content: record === 'sel' ? '确认批量删除吗?' : '是否删除已选择的？',
          onOk: () => {
            request(getRequestURI('sign_del'), { data:{ids} }).then(res => {
              if (res.code === 1) {
                handleSearch()
                message.success('删除成功')
                state.selectedRowKeys = []
              } else {
                message['error'](res.message)
              }
            })
          },
          onCancel: () => {
            Modal.destroyAll()
          }
        })

      }

      const handleSearch = function (filters) {
        state.searchNow = filters
        run({
          pagination: {
            current: current.value,
            pageSize: pageSize.value
          },
          data: filters
        })
      }

      // 下载模板
      const downModule =  (oldData) => {
        const productTemp = oldData.productSupSignTemplateEntity
        if(productTemp) {
          fetch(productTemp.templateFileUrl).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
            const a = document.createElement('a')
            a.href = URL.createObjectURL(blob)
            //测试链接console.log(a.href)
            a.download = productTemp.templateName  // 下载文件的名字
            document.body.appendChild(a)
            a.click()
          })
        }
      }
 // 查看
      const checkProduct =  (oldData, params) => {
        state.status = params
        if(oldData.productSupSignTemplateEntity) {
          oldData = {
            ...oldData,
            templateCode: oldData.productSupSignTemplateEntity.templateCode
          }
        }
        signDrawerRef.value.showDrawer(oldData, params)
      }

      // 编辑
      const modifyProduct =  (oldData, params) => {
        state.status = params

        if(oldData.productSupSignTemplateEntity) {
          oldData = {
            ...oldData,
            templateCode: oldData.productSupSignTemplateEntity.templateCode
          }
        }
        signDrawerRef.value.showDrawer(oldData, params)
      }
      const handleTableChange = (pag, filters, sorter) => {
        run({
          pagination: {
            current: pag?.current,
            pageSize: pag?.pageSize
          },
          sorter,
          data: state.searchNow,
        })
      }

      const onSelectChange = (selectedRowKeys) => {
        state.selectedRowKeys = selectedRowKeys
      }

      onMounted(() => {
        getDictionaryData(tempTypeList, 'signTemplateType')
        handleSearch()

      })
      return {
        ...toRefs(state),
        resetAll,
        addModule,
        handleSearch,
        delModule,
        dataSource,
        run,
        loading,
        current,
        pageSize,
        total,
        signDrawerRef,
        checkProduct,
        downModule,
        modifyProduct,
        pagination,
        onSelectChange,
        handleTableChange,
        tempTypeList
      }
    }

  })
</script>

<style scoped>

</style>
